import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import SelectField from "../../../../../../components/Form/SelectField/SelectField";
import TextFields from "../../../../../../components/Form/TextField/TextField";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

//import sidedrawer
import SideDrawer from "../../../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import Externaluse from "../../../../../../components/MediaLibrary/Externaluse";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const CashPayment = (props) => {

  const {paymentData, setPaymentData, options } = props;


  // date picker

  const [value, setValue] = React.useState(dayjs('2022-04-17'));

  // === handleChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    const fieldData = event.target.value;
    console.log(fieldName, fieldData);
    setPaymentData({ ...paymentData, [fieldName]: fieldData });
  };

  // === handleChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setPaymentData({ ...paymentData, [fieldName]: fieldData });
  };
 
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectField
            label="Status"
            validation
            options={options}
            selectedOption={paymentData.status}
            handleChange={handleChanges("status")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFields
            label="Amount Paid"
            validation={true}
            placeholder="$"
            number={true}
            // count={80}
            value={paymentData.amountPaid}
            handleChange={handleChanges("amountPaid")}
          />
        </Grid>
        <Grid item xs={12}>
        <div className="Datefield">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              label="Date And Time"
              value={paymentData.cashReceivedAt}
              onChange={(newValue) => handleChangeWithData("cashReceivedAt", newValue)}
            //   disablePast
              renderInput={(params) => <TextField {...params} />}
              className="DateTime"
            />
          </LocalizationProvider>
        </div>
        </Grid>
        <Grid item xs={12}>
          <TextFields
            label="Collected/Verified By"
            validation={false}
            // count={80}
            value={paymentData.collectedBy}
            handleChange={handleChanges("collectedBy")}
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextFields
            label="Additional Information"
            validation={false}
            count={100}
            value={paymentData.additionalInfo}
            handleChange={handleChanges("additionalInfo")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CashPayment;

const options = [
  {
    label: "Pending",
    value: "Pending",
  },

  {
    label: "Paid",
    value: "Paid",
  },
  {
    label: "Failed",
    value: "Failed",
  },
  {
    label: "Canceled",
    value: "Canceled",
  },
  {
    label: "Refund",
    value: "Refund",
  },
];
