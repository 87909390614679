import React, { useState } from "react";
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Sessions from "./Sessions";
import Resources from "./Resources";
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

function Modules(props) {

    const { index, id, title, setCurrentModule, setEditModule, deleteModule } = props;
    const { courseSessions, handleOpenSessionModal, handleDeleteSession } = props;
    const { courseResourses, handleOpenResourseModal, handleDeleteResourse } = props;

    // Modal Resource //

    const showModuleEditPopUp = () => {
        setEditModule(true);
        setCurrentModule({ index: index, id: id, title: title });
    };


    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="AccrodionThreeStep">

            {/* <Accordion expanded={expanded === `module-${index}`} onChange={handleChangeModule(`module-${index}`)}> */}

            <Accordion expanded={props.expanded === props.panel} onChange={props.handleChange}>
                <AccordionSummary
                    expandIcon={<ArrowForwardRoundedIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">

                    <div className="accTitleSection">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {title}
                            <p className="ItemCount">
                                {typeof courseSessions.length !== 'undefined' && <span> {courseSessions.length} {courseSessions.length > 1 ? "sessions" : "session"} <FiberManualRecordRoundedIcon className="SmallDot" /></span>}
                                {typeof courseResourses.length !== 'undefined' && <span> {courseResourses.length} {courseResourses.length > 1 ? "resources" : "resource"} </span>}
                            </p>
                        </Typography>
                        <div>
                            {index > 0 &&
                                <Button className="SessionButtonEdit" onClick={() => deleteModule(index)}>Delete</Button>
                            }
                            <Button className="SessionButtonEdit" onClick={() => showModuleEditPopUp()}>Edit</Button>
                        </div>
                    </div>
                </AccordionSummary>

                <AccordionDetails>
                    <div className="AccrodionInner">
                        <Sessions
                            courseSessions={courseSessions}
                            handleOpenSessionModal={handleOpenSessionModal}
                            handleDeleteSession={handleDeleteSession} moduleIndex={index}
                            
                            panel={(`session-${index}`)}
                            handleChange={handleChange(`session-${index}`)}
                            expanded={expanded}
                        />
                        <Resources
                            courseResourses={courseResourses}
                            courseSessions={courseSessions}
                            handleOpenResourseModal={handleOpenResourseModal}
                            handleDeleteResourse={handleDeleteResourse}
                            moduleIndex={index}

                            panel={(`resorce-${index}`)}
                            handleChange={handleChange(`resorce-${index}`)}
                            expanded={expanded}
                        />
                    </div>

                </AccordionDetails>

            </Accordion>

        </div>
    );
}

export default Modules;