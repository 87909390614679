
import React from "react";
import SideBarMenu from './SidebarMenu';

function Sidebar(props) {

  return (
    <div>
      <SideBarMenu 
       collapsed={props.collapsed}
      />
    </div>
  );
}

export default Sidebar;








// import React from "react";
// import MenuList from '@mui/material/MenuList';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
// import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
// import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
// import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
// import EventIcon from '@mui/icons-material/Event';
// import SettingsIcon from '@mui/icons-material/Settings';
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

// import Tooltip from '@mui/material/Tooltip';
// import Box from '@mui/material/Box';

// import PortalSwitcher from '../../../components/PortalSwitcher/PortalSwitcher';

// function Sidebar(props) {



//   return (
//     <div className="SidebarMenu">

//       <MenuList>

//         <PortalSwitcher />


//         {/* <MenuItem>
//           <ListItemIcon>
//             <DashboardRoundedIcon />
//           </ListItemIcon>
//           <a href="#"> Home</a>
//         </MenuItem> */}

//         <MenuItem>
//           <a href="/my-courses">
//             <ListItemIcon>
//               <MenuBookRoundedIcon />
//             </ListItemIcon>
//             <div>Courses</div>
//           </a>
//         </MenuItem>

//         {/* <span className="divider"></span> */}

//         {/* <Tooltip title="COMING SOON" placement="right">
//           <MenuItem>
//             <ListItemIcon>
//               < NotificationsRoundedIcon />
//             </ListItemIcon>
//             <a href="#">Alerts</a>
//           </MenuItem>
//         </Tooltip>

//         <MenuItem>
//           <ListItemIcon>
//             <ForumRoundedIcon />
//           </ListItemIcon>
//           <a href="#">Messages</a>
//         </MenuItem> */}

//         <MenuItem>
//           <a href="/calendar">
//             <ListItemIcon>
//               <EventIcon />
//             </ListItemIcon>
//             <div>Calendar</div>
//           </a>
//         </MenuItem>




//         {/* <span className="divider"></span> */}

//         <MenuItem>
//           <a href="/certificates">
//             <ListItemIcon>
//               <EmojiEventsIcon />
//             </ListItemIcon>
//             <div>Certificates</div>
//           </a>
//         </MenuItem>

//         <MenuItem>
//           <a href="/settings">
//             <ListItemIcon>
//               <SettingsIcon />
//             </ListItemIcon>
//             <div>Settings</div>
//           </a>
//         </MenuItem>

//       </MenuList>
//     </div>
//   );
// }


// export default Sidebar;

