import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import AlrtMessage from '../../../components/Form/AlrtMessage/AlertMessage';
import DraggableDialog from '../../../hooks/Dialog';
import Table from '../../../components/Table/Table';
import CustomizedSnackbar from "../../../hooks/Snackbar";


import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import ModalsResponsiveFullScreen from '../../../components/Form/Modals/ModalsResponsiveFullScreen';
import RedioBox from '../../../components/Form/RedioBox/RedioBox';
import SimpleBackdrop from '../../../components/Form/Backdrop/SimpleBackdrop';


function Courses(props) {

  const orgId = localStorage.getItem('orgId');

  const [courseList, setCourseList] = useState([]);
  const [activeCourseId, setActiveCourseId] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");

  const [openCourseStatusModal, setOpenCourseStatusModal] = useState(false);
  const [initCourseStatus, setInitCourseStatus] = React.useState("");
  const [courseStatus, setCourseStatus] = React.useState("");
  const statusRadioOptions = ['draft', 'public', 'active_for_track', 'private'];
  const [tabsData, setTabsData] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleOpenStatusModal = () => {
    console.log('open')
    setOpenCourseStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    console.log('close')
    setOpenCourseStatusModal(false);
  };

  const handleCourseStatusRadioBtn = (event, activeSwitchIndex) => {
    const courseStatus = statusRadioOptions[activeSwitchIndex];
    console.log(activeSwitchIndex, courseStatus);
    setCourseStatus(courseStatus);    
  }

  const handleConfirmCloseStatusModal = () => {
    // save new status into DB
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    const postData = { courseId: activeCourseId, status: courseStatus };
    console.log(postData);
    setOpenBackdrop(true);
    axios.post(`${baseUrl}/orgPortal/updateCourseStatus`, postData)
      .then((response) => {
        console.log(response.data);
        setOpenBackdrop(false);
        const result = response.data;
        if(result.error){
          setAlertSeverity("error");
          setAlertMessage(result.message);
          setAlertOpen(true);
        } else{
          setAlertSeverity("success");
          setAlertMessage("Status updated");
          setAlertOpen(true);
          getCourseListingData();
          setOpenCourseStatusModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setOpenBackdrop(false);
        if (err.response.data.message) {
          setAlertSeverity("error");
          setAlertMessage(err.response.data.message);
          setAlertOpen(true);
        }
      });
  }

  // fetching course data

  const getCourseListingData = () => {

    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/orgPortal/getCourseListingData?orgId=${orgId}&orderBy=courseId&orderByDir=desc`).then((result) => {
      console.log(result.data.body);
      setCourseList(result.data.body);
      setOpenBackdrop(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    getCourseListingData();
  }, []);

  const processDeleteOperation = (result) => {

    console.log(result);
    const response = result.data;
    setOpenBackdrop(false);
    if (response.deleteStatus) {
      setOpenDialog(false);
      setAlertSeverity("success");
      setAlertMessage(response.message);
      setAlertOpen(true);
      getCourseListingData();
    } else {
      setOpenDialog(false);
      setAlertSeverity("error");
      setAlertMessage(response.message);
      setAlertOpen(true);
    }
  }

  // const alertCloseHandler = () => {
  //   setAlertOpen(false);
  // }

  const alertCloseHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const handleOpenDialog = (id, additionalData) => {
    console.log(id, additionalData);
    setActiveCourseId(id);
    if(additionalData){
      setCourseStatus(additionalData.status);
      setInitCourseStatus(additionalData.status);
      setOpenCourseStatusModal(true);
    } else{
      setDialogTitle("Delete course");
      setDialogBody("Are you sure you want to delete this course and it's related data?");
      setOpenDialog(true);
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirmCloseDialog = () => {

    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    const postData = { courseId: activeCourseId };
    console.log(postData);
    setOpenBackdrop(true);
    axios.post(`${baseUrl}/orgPortal/deleteCourse`, postData)
      .then(response => processDeleteOperation(response))
      .catch((err) => {
        console.log(err);
        setOpenBackdrop(false);
        if (err.response.data.message) {
          setAlertSeverity("error");
          setAlertMessage(err.response.data.message);
          setAlertOpen(true);
        }
      });
  }

  useEffect(() => {

    let tabsDataLoc = [
      {
        title: "Draft",
        description: "Course will be saved as draft.",
        disabled:"disabled"
      },
  
      {
        title: "Public",
        description: "Course will be published and visible to everyone and also active to be added to track.",
      },
  
      {
        title: "Active for track",
        description: "Course is active to be added to track. It will not be published to webpage individually",
      },
      
      {
        title: "Private",
        description: "Only admins and instructors can see this course",
        disabled:"disabled"
      }
    ];


    if( (initCourseStatus == "" || initCourseStatus == "draft") ){
      tabsDataLoc[0].disabled = "";
    }

    // if(courseData.formValidationError){
    //   tabsDataLoc[1].disabled = "disabled";
    //   tabsDataLoc[2].disabled = "disabled";
    // } 

    if( initCourseStatus == "private" || initCourseStatus == "public" || initCourseStatus == "active_for_track"){
      tabsDataLoc[3].disabled = "";
    }

    setTabsData(tabsDataLoc);

  }, [courseStatus]);

  const headCells = [

    { id: 'name', numeric: true, disablePadding: false, label: 'Name', width: "30%" },
    { id: 'id', numeric: true, disablePadding: false, label: 'ID', width: "18%" },
    // { id: 'track', numeric: true, disablePadding: false, label: 'Track', width: "20%" },
    { id: 'price', numeric: true, disablePadding: false, label: 'Price', width: "18%" },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status', width: "25%" },
    { id: '', numeric: true, disablePadding: false, label: '', width: "15%" },

  ];

  return (

    <>

      <SimpleBackdrop open={openBackdrop} />
      <DraggableDialog openDialog={openDialog} title={dialogTitle} body={dialogBody} actionButton="Confirm" handleCloseDialog={handleCloseDialog} handleConfirmCloseDialog={handleConfirmCloseDialog} ModalFooter={true}/>
      <CustomizedSnackbar open={alertOpen} message={alertMessage} severity={alertSeverity} handleClose={alertCloseHandler} />

      <div className="TableSection">

        <Table
          title="Courses"
          tableHeader={true}
          addLabel="New Course"
          link="add-courses/0"
          headCells={headCells}
          rows={courseList}
          AddButton={true}
          Checkbox={false}
          footer={true}
          handleOpenDialog={handleOpenDialog}
        />
      </div>

      <ModalsResponsiveFullScreen
        ModalTitle="Change course status"
        open={openCourseStatusModal}
        handleClose={handleCloseStatusModal}
        handleConfirm={handleConfirmCloseStatusModal}
        ModalFooter={true}
        ButtonClose="Cancel"
        ButtonConfirm="Save"
        width="552px"
        textAlign="center"

        ModalBody={
          <div className="CrLst">
          <RedioBox
            tabsData={tabsData}
            value= {statusRadioOptions.indexOf(courseStatus)}
            FullWidth="FullWidth"
            onChange={handleCourseStatusRadioBtn}

          />
          </div>
        }
      />




    </>
  );
}


export default Courses;

