import React, { useState } from "react";
import dateFormat, { masks } from "dateformat";
import {dateFormatter} from '../../../../../hooks/HelperFunctions'
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded'


function Sessions(props) {

    const { courseSessions, handleOpenSessionModal, handleDeleteSession, moduleIndex } = props;

    const [timezone, setTimezone] = useState(() => {
        const courseData = JSON.parse(localStorage.getItem('courseData'));
        console.log(courseData.timezone);
        return courseData.timezone;
    });


    // console.log(courseSessions);

    return (
        <Accordion expanded={props.expanded === props.panel} onChange={props.handleChange}>
            <AccordionSummary
                expandIcon={<ArrowForwardRoundedIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
            >
                <div className="accTitleSection">
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Sessions
                    </Typography>
                    <Button className="SessionButton" onClick={() => handleOpenSessionModal(moduleIndex, -1)}><AddRoundedIcon />Session</Button>
                </div>
            </AccordionSummary>

            <AccordionDetails>

                <div className="SessionListing">

                    {courseSessions.map((session, sesIndex) => {
                        return (
                            <li>
                                <div className="Sessionleftcol">

                                    <div className="SessionrIcon">
                                        <span>
                                            <VideocamRoundedIcon />
                                        </span>
                                    </div>

                                    <div className="SessionListingDetail">
                                        <h4>{session.title}</h4>
                                        <p>{dateFormatter(session.startDate, timezone, "ddd, MMM Do, YYYY z")} <FiberManualRecordRoundedIcon /> {dateFormatter(session.startTime, timezone, "hh:mm a")} to {dateFormatter(session.endTime, timezone, "hh:mm a")}</p>
                                        {/* <p>{typeof session.startTime == "string"? session.startTime : JSON.parse(session.startTime)}</p> */}
                                    </div>

                                </div>

                                <div className="SessionListingAction">
                                    <Button onClick={() => handleOpenSessionModal(moduleIndex, sesIndex)}><EditRoundedIcon /></Button>
                                    <Button onClick={() => handleDeleteSession(moduleIndex, sesIndex)}><DeleteForeverRoundedIcon /></Button>
                                </div>
                            </li>
                        )
                    })}

                    <p className="emptySettionMessage">Add sessions to this module</p>
                </div>

            </AccordionDetails>

        </Accordion>
    );
}

export default Sessions;