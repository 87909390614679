import React, { useState, useRef, useEffect } from 'react';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import img from './meida-img.jpg';
import Button from '@mui/material/Button';
function ImageDetials(props) {


  return (

    <div className='ImageDetials'>

      <div className='mdLibSideBar'>

        <div className='FeatureImgContainer'>
          <img src={img} />
        </div>
        <div className='FetureImgDetails'>

          <li>
            <h3>File name:</h3>
            <p>1650819727648fcde9aae-0588-4896-bf2d-0f2d8b7785ff.jpeg</p>
          </li>

          <li>
            <h3>File type:</h3>
            <p>image/jpeg</p>
          </li>

          <li>
            <h3>Uploaded on:</h3>
            <p>2022/12/14 23:32 PM</p>
          </li>
          <li>
            <h3>File size:</h3>
            <p>132.56</p> kb
          </li>
        </div>

        <div className="DeleteImgButton">
          <Button color="secondary"><DeleteForeverRoundedIcon /> Delete permanently</Button>

        </div>

        <div className="BootmInfodetailsFooter">

          <div className="BootmInfodetails">
            <li><p>Storage</p><p>1.39% used</p></li><h4>1.39 MB of 100MB</h4>
          </div>
          <div className="BootmInfodetails"><li><p>Total Media Item</p></li><h4>14</h4></div>
        </div>

      </div>

    </div>

  );
}


export default ImageDetials;

