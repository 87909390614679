import React, { useEffect, useState } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { Button } from "@mui/material";


function Overview(props) {

  const {teamMemberData} = props;
  const [showFullBio, setShowFullBio] = useState(false);

  function createData(label, data) {
    return { label, data };
  }

  const rows = [
    createData('Full Name', teamMemberData.firstName ? <span>{teamMemberData.firstName} {teamMemberData.lastName}</span> : ""),
    createData('Role', teamMemberData.role ? <span>{teamMemberData.role}</span> : ""),
    createData('Organization', teamMemberData.organization ? <span>{teamMemberData.organization}</span> : ""),
    createData('Designation', teamMemberData.designation ? <span>{teamMemberData.designation}</span> : ""),
    createData('Short bio', teamMemberData.shortBio ? 
    <div style={{ maxHeight: showFullBio ? "none" : "3em", overflow: "hidden", textOverflow: "ellipsis" }}>
      {teamMemberData.shortBio}
    </div>
     : ""),
  ];

  const toggleBioVisibility = () => {
    setShowFullBio(!showFullBio);
  };


  return (
    <div className="TableContainer">
        <div className="TableInnerHeader">
          <h3>Overview</h3>
        </div>
        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="personal info">
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.label}
                  hover={true}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& td:nth-of-type(even)': {
                      backgroundColor: index % 2 === 0 ? '#F3F3F3 !important' : 'inherit',
                    },
                  }}
                  className={index % 2 === 0 ? 'even-row' : ''}
                >
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    {row.data}
                    {row.label === "Short bio" && teamMemberData.shortBio && (
                    <div>
                      {!showFullBio && (
                        <Button
                           variant="text" 
                           onClick={toggleBioVisibility}
                           sx={{p: '0 !important',textTransform: 'capitalize'}}
                        >Read&nbsp; <span style={{textTransform: 'lowercase'}}>more</span></Button>
                      )}
                      {showFullBio && (
                        <Button
                         variant="text"
                         onClick={toggleBioVisibility}
                         sx={{p: '0 !important',textTransform: 'capitalize'}}
                        >Read&nbsp; <span style={{textTransform: 'lowercase'}}>less</span></Button>
                      )}
                    </div>
                  )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>        
    </div>

  );
}


export default Overview;