import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';

import {getUserData, getUserTimezone} from '../../../../hooks/HelperFunctions';

import ComingSoon from '../../../../components/ComingSoon/ComingSoon';
import VerticalTab from '../../../../components/VerticalTab/VerticalTab';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import Overview from './overview/Overview';
import SecurityTab from './security/Security';
import Courses from './courses/Courses';
import { Person, Receipt, Security } from "@mui/icons-material";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Certificate from '@mui/icons-material/EmojiEvents';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple, } from '@mui/material/colors';

import Modals from '../../../../components/Form/Modals/Modals';
import EditUser from '../forms/EditUser';
import Payments from "./payments/Payments";
import SimpleBackdrop from '../../../../components/Form/Backdrop/SimpleBackdrop'
import CustomizedSnackbar from '../../../../hooks/Snackbar';


function UsersDetails(props) {

  const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
  const timezone = getUserTimezone();

  let { userId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  let reqActiveTab = searchParams.get("activeTab");

  const [studentData, setStudentData] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [userBillingData, setUserBillingData] = useState(0);
  const [totalRunningCourses, setTotalRunningCourses] = useState(0);
  const [totalCompletedCourses, setTotalCompletedCourses] = useState(0);
  const [totalCirtificates, setTotalCirtificates] = useState(0);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [paymentDataLoaded, setPaymentDataLoaded] = useState(false);
  const [startTab, setStartTab] = useState(0);

  useEffect(() => {

    if (paymentDataLoaded === true && reqActiveTab) {      
      // active the tab
      if(reqActiveTab == "payment"){
        setStartTab(1);
      }

    }

  }, [paymentDataLoaded]);

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  // fetching student data

  const getStudentData = () => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    const orgId = localStorage.getItem('orgId');
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/studentPortal/getStudentData?orgId=${orgId}&userId=${userId}&timezone=${timezone}`).then((result) => {
      console.log(result.data.body);
      setStudentData(result.data.body.studentDetails);
      setTotalRunningCourses(result.data.body.totalRunningCourses);
      setOpenBackdrop(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  // fetching course data

  const getStudentCourseListingData = () => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    const orgId = localStorage.getItem('orgId');
    // const userId = localStorage.getItem('userId');
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/studentPortal/getStudentCourses?orgId=${orgId}&userId=${userId}&portalName=orgPortal`).then((result) => {
      console.log(result.data.body);
      setCourseList(result.data.body);
      setOpenBackdrop(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  const getStudentBillingData = () => {    
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/studentPortal/getStudentBillingData?userId=${userId}&actionCol=true&timezone=${timezone}`).then((result) => {
      console.log(result.data.body);
      setUserBillingData(result.data.body);
      setOpenBackdrop(false);
      setPaymentDataLoaded(true);
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    getStudentData();
    getStudentBillingData();
    getStudentCourseListingData();
  }, []);



  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabData = [
    {
      icon: <Person />,
      label: "Overview",
    },

    {
      icon: <Receipt />,
      label: "Payment",
    },

    {
      icon: <MenuBookRoundedIcon />,
      label: "Courses",
    },

    {
      icon: < Certificate />,
      label: "Certificates",
    },



    {
      icon: <Security />,
      label: "Security",
    },



  ]

  const tabBody = [

    {
      id: 0,
      panel: <Overview studentData={studentData} />,
    },
    {
      id: 1,
      panel: <Payments userBillingData={userBillingData} getStudentBillingData={getStudentBillingData} />,
    },
    {
      id: 2,
      panel: <Courses courseList={courseList} />,
    },

    {
      id: 3,
      panel: <ComingSoon title="Certificates" />,
    },

    {
      id: 4,
      panel: <SecurityTab studentData={studentData} />,
    },

  ]

  const [currentTab, setCurrentTab] = useState(0);
  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  }


   {/*==== Edit new Form Modal */}
   const [OpenEditNewUser, setOpenEditUser] = useState(false);
   const handleOpenEditUser = () => {
     setOpenEditUser(true);
   };
   const CloseEditNewUser = () => {
     console.log('close')
     setOpenEditUser(false)
   };
   {/*==== Edit new Form Modal */}


  return (
    <div>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="UserDetails">
        <li>

          <div className="UserDetailsleftcol">
            <div className="UserDetailsrIcon">
              {studentData.image ?
                <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} src={studentData.image ? studentData.image : ""} ></Avatar>
                :
                <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} > {studentData.firstName ? Array.from(studentData.firstName)[0] : ""} </Avatar>
              }
            </div>
            <div className="UserDetailsDetail">
              <span style={{ display: "inline-block" }}><h4>{ studentData.firstName? `${studentData?.firstName} ${studentData?.lastName}` : ''}</h4></span>  <span style={{ display: "inline-block" }} className="status rounded success">Active</span>
              <p>{studentData.email}</p>
            </div>

          </div>

          {/* <div className="EditUserProfile actionDoropdown">
            <Button className="ActionButton" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              Actions <KeyboardArrowDownRoundedIcon />
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >

              <MenuItem onClick={() => handleOpenEditUser(true)}>Edit</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
            </Menu>
          </div> */}

        </li>

        {/* <div className="UserStatus">
          <div className="UserStatusCol">{totalRunningCourses} running courses</div>
          <div className="UserStatusCol">{totalCompletedCourses} Completed Courses</div>
          <div className="UserStatusCol">{totalCirtificates} Certificates</div>
        </div> */}

      </div>

      <VerticalTab
        //title="Introduction to Islamic Economics and Finance"
        //name="MUNA Academy"
        link="#"
        tabData={tabData}
        tabBody={tabBody}
        onTabSwitch={handleTabSwitch}
        activeTab={startTab}
      //onClick={onClick()}
      //ButtonLabel="Start Class"
      />


      {/*==== Edit new Form Modal */}
      <Modals
        ModalTitle="Edit User Information"
        handleClose={CloseEditNewUser}
        open={OpenEditNewUser}
        ButtonClose="Cancel"
        ButtonConfirm="Save"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalFooter={true}
        ModalBody={
          <div><EditUser /></div>
        }
      />
      {/*==== Edit new Form Modal End */}

    </div>
  );
}


export default UsersDetails;

