import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@mui/icons-material/Clear';
import './SearchBox.css';

export default function SearchBox(props) {
  return (
    <div className='SearchBox'>
    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
        <OutlinedInput
            id="outlined-adornment-password"
            placeholder={props.placeholder}
            value={props.searchText}
            type="text"
            onChange={props.handleSearchText}
            endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={props.handleSearch}
                    // onMouseDown={props.handleSearch}
                    edge="end"
                >
                { props.searchText? <ClearIcon /> : <SearchIcon /> }
                </IconButton>
                </InputAdornment>
            }
        />
    </FormControl>
    </div>
  );
}