/**
 * Author: Ashrafuzzaman Sujan
 */

import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '../Tooltip/Tooltip';
import './ComboBox.css';

export default function ComboBox(props) {

  const { label, validation, required, placeholder, options, selectedOption, handleChange, error, helperText } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");

  const [value, setValue] = useState(0);


  useEffect(() => {

    if (required || validation) {
      // console.log(selectedOption);
      if (!selectedOption) {
        setValidationError(true);
        setValidationText("Required");        
      } else {
        setValidationError(false);
        setValidationText("");
      }

    }
  }, [selectedOption]);

  const onChangeHandler = (event, option) => {
    console.log(option);
    if(option){
      handleChange(option.value);
    }    
  }

  const getObjFromId = (options, selectedOption) => {
    const currentListItem = options.findIndex( (element, sesIndex, array) => {
        return (element.value == selectedOption);
    });
    return options[currentListItem];
  }

  return (
    <div className="ComboBox">
        <div className="FieldLabel">{label} {(required || validation) && <span>*</span>}
        {props.TooltipStatus &&
          <Tooltip
            TooltipText={props.TooltipText}
            Tooltip={<InfoRoundedIcon />}
          />
        }
      </div>
        <Autocomplete         
            disablePortal
            id="combo-box-demo"
            value={getObjFromId(options, selectedOption)}
            options={options}
            onChange={onChangeHandler}
            getOptionLabel={(option) => option.label}
            // sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} placeholder={placeholder? placeholder : `Search ${label}`} error={error || validationError}
            helperText={helperText || validationText} />}
        />
    </div>
  );
}