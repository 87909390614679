import React, { useEffect, useState } from "react";
import axios from 'axios';
import Table from '../../../../../components/Table/Table';
import TextField from '../../../../../components/Form/TextField/TextField'
import TextArea from '../../../../../components/Form/TextArea/TextArea'
import Grid from '@mui/material/Grid';
import Modals from '../../../../../components/Form/Modals/Modals';
import SimpleBackdrop from '../../../../../../src/components/Form/Backdrop/SimpleBackdrop';


function Announcements(props) {

  const {courseId, announcements, getAnnouncements, handleOpenSnackbar} = props;

  const [annouModalTitle, setAnnouModalTitle] = useState("");
  const [annouModalActionBtn, setAnnouModalActionBtn] = useState("");

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const headCells = [
    { id: 'Title', numeric: false, disablePadding: true, label: 'Title', width: "50%" },
    { id: 'Published', numeric: true, disablePadding: false, label: 'Published', width: "40%" },
    { id: 'Action', numeric: true, disablePadding: false, label: '', width: "10%" },
  ];

  // const rows = [
  //   {
  //     cell: [
  //       { textBold: "Session 3 will be held at December 20", },
  //       { date: "Dec 13, 2022, 10:00 AM", },
  //       { icon: <ModeEditOutlineOutlinedIcon />, iocnLink: "#", operationType:"edit", iconStatus: true, },
  //       { icon: <DeleteOutlineOutlinedIcon />, iocnLink: "#", operationType:"delete", iconStatus: true, }

  //     ]
  //   },

  // ];
  

  const iconButtonHandler = (operationType, id) => {
    console.log(operationType, id);
    if(operationType == "edit"){
      handleOpenEditAnnoucement(id);
    } else if(operationType == "delete"){
      handleOpenDeleteAnnoucement(id)
    }
  }

  const [values, setValues] = React.useState({});

  const handleChanges = (field) => (event) => {
    console.log(field);
    setValues({
      ...values,
      [field]: event.target.value,
    });
  };

  // Modal Edit announcement  start//
  const [openEditAnnouncement, setOpenEditAnnoucement] = useState(false);
  
  const handleOpenAddAnnoucement = () => {
    setValues({...values, ["title"]: "", ["details"]: "", ["annouId"]: 0});
    setAnnouModalTitle("Add new Announcement");
    setAnnouModalActionBtn("Add New");    
    setOpenEditAnnoucement(true);
  };

  const handleOpenEditAnnoucement = (annouId) => {

    const activeItem = announcements.findIndex( (element) => {
      return (annouId == element.row.annouId);
    });

    setValues({...values, ["title"]: announcements[activeItem]?.row?.title, ["details"]: announcements[activeItem]?.row?.details, ["annouId"]: annouId});

    setAnnouModalTitle("Edit Announcement");
    setAnnouModalActionBtn("Save"); 
    setOpenEditAnnoucement(true);
  };

  const handleCloseModalEditAnnoucement = () => {
    console.log('close')
    setOpenEditAnnoucement(false)
  };

  const handleConfirmEditAnnoucement = () => {
    console.log('close')    
    handleSaveStudentData(values);
  };
  // Modal Edit announcement  End//


  // Modal Delete announcement  start//
  const [openDeleteAnnouncement, setOpenDeleteAnnoucement] = useState(false);
  const handleOpenDeleteAnnoucement = (annouId) => {
    setOpenDeleteAnnoucement(true);
    setValues({...values, ["annouId"]: annouId});
  };

  const handleCloseModalDeleteAnnoucement = () => {
    console.log('close')
    setOpenDeleteAnnoucement(false)
  };

  const handleConfirmDeleteAnnoucement = () => {  
         
    const apiBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${apiBaseUrl}/orgPortal/deleteAnnouncements?annouId=${values?.annouId}`).then((result) => {
      handleOpenSnackbar("success", "Announcement deleted"); 
      setOpenDeleteAnnoucement(false);  
      getAnnouncements();
    }).catch((err) => {
      console.log(err);
    });
    
  };

  // Modal Delete announcement  End//

  useEffect(() => {
    setValues({...values, ["courseId"]: courseId}); 
  }, []);

  // add/update announcement

  const showDataPostMessage = (response) => {

    setOpenBackdrop(false);

    if(response.data.error){
      handleOpenSnackbar("error", response.data.message);
    } else{
      setOpenEditAnnoucement(false);
      if(values.annouId){
        handleOpenSnackbar("success", "Announcement updated");
      } else{        
        handleOpenSnackbar("success", "Announcement added");
      }
      
      getAnnouncements();
    }
    
  }


  const handleSaveStudentData = (dataArray) => {
    const apiBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    console.log(dataArray);
    if(dataArray.title.length == 0){
      handleOpenSnackbar("error", "Please fill up all required fields.");
    } else{
      setOpenBackdrop(true);
      axios.post(`${apiBaseUrl}/orgPortal/updateAnnouncements`, dataArray)
      .then(response => showDataPostMessage(response));
    }
    
  }

  return (
    <>
      
      <div className="TableSection">
        <Table
          AddButtonInner={true}
          addLabelInner="+ Announcement"
          innerTitle="Announcements"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          onClickInnerButton={() => handleOpenAddAnnoucement(true)}
          innerHeader={true}
          headCells={headCells}
          rows={announcements}
          perPageItems={15}
          Checkbox={false}
          footer={true}
          iconButtonHandler={iconButtonHandler}
        />
      </div>

      {/* Edit announcement Modal */}
      <Modals
        ModalTitle={annouModalTitle}
        handleClose={handleCloseModalEditAnnoucement}
        handleConfirm={handleConfirmEditAnnoucement}
        open={openEditAnnouncement}
        ButtonClose="Cancel"
        ButtonConfirm={annouModalActionBtn}
        width="552px"
        
        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"
        ModalFooter={true}
        ModalBody={
          <div>
            <SimpleBackdrop open={openBackdrop} />
            <Grid container spacing={3}>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Title"
                  required={true}
                  count={50}
                  value={values.title}
                  handleChange={handleChanges("title")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextArea
                  label="Details"
                  count={200}
                  value={values.details}
                  handleChange={handleChanges("details")}
                />
              </Grid>

            </Grid>

          </div>
        }

      />
      {/*  Edit announcement Modal  End*/}

      {/* Delete announcement Modal */}
      <Modals
        ModalTitle="Delete this announcement?"
        handleClose={handleCloseModalDeleteAnnoucement}
        handleConfirm={handleConfirmDeleteAnnoucement}
        open={openDeleteAnnouncement}
        ButtonClose="Cancel"
        ButtonConfirm="Delete"
        width="552px"

        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"
        ModalFooter={true}

        ModalBody={
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{textAlign:"center",}}>Are you sure you want to delete this? Once you delete it, you can't get it back.</div>

              </Grid>
            </Grid>

          </div>
        }

      />
      {/*  Edit announcement Modal  End*/}

    </>

  );
}

export default Announcements;

