import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {getUserData, getUserTimezone} from '../../../hooks/HelperFunctions';

import AlrtMessage from '../../../components/Form/AlrtMessage/AlertMessage';
import Table from '../../../components/Table/Table';
import CustomizedSnackbar from '../../../hooks/Snackbar';
import EmptyCard from '../../../../src/components/EmptyCard/EmptyCard';
import SimpleBackdrop from '../../../../src/components/Form/Backdrop/SimpleBackdrop';

import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import StatusChip from '../../../components/Form/StatusChip/StatusChip';
import Modals from '../../../components/Form/Modals/Modals';
import FileUplaoder from '../../../components/Form/FileUplaoder/FileUplaoder';
import OrderDetails from './PaymentMetod/OrderDetails';
import PaymentMethodZelle from './PaymentMetod/PaymentMethodZelle';
import PaymentMethodCash from './PaymentMetod/PaymentMethodCash';
import PaymentMethodCheck from './PaymentMetod/PaymentMethodCheck';
import OrderItems from './PaymentMetod/OrderItems';

function InProgress(props) {

  const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
  const timezone = getUserTimezone();

  const [courseList, setCourseList] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [paymentData, setPaymentData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [paymentReceipt, setPaymentReceipt] = useState(null); 

  // main website url
  let projectEnv = process.env.REACT_APP_PROJECT_ENV;
  let mainWebsiteUrl = process.env.REACT_APP_TAKBIR_SUBDOMAIN;

  if(projectEnv == "prod"){
    mainWebsiteUrl = mainWebsiteUrl.replace('sub-domain.', "");
  } else{
    mainWebsiteUrl = mainWebsiteUrl.replace('sub-domain', "dev");
  }

    // retrive get data from url
    let [searchParams, setSearchParams] = useSearchParams();
    let enrollment = searchParams.get("enrollment");
    console.log(enrollment);
  
    useEffect(() => {
        
        if(enrollment == "success"){
          triggerSnackbar("success", "Enrolled successfully!");
        }
  
    }, []);

    // update course data per/1 minute
    useEffect(() => {
      const interval = setInterval(() => {            
        getCourseListingData();
      }, 60000);
      
      return () => clearInterval(interval);

    }, []);
  
    // trigger snackbar
    /**
     * When click on snackbar close btn
     */
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setShowSnackbar(false);
    }
  
    const triggerSnackbar = (severity = "", message = "") => {
      setShowSnackbar(true);
      setSnackbarSeverity(severity);
      setSnackbarMessage(message);
    }

  const headCells = [
    { id: 'course', numeric: true, disablePadding: false, label: 'Course', width: "40%" },
    // { id: 'track', numeric: true, disablePadding: false, label: 'Track', width: "22%" },
    { id: 'nextSession', numeric: true, disablePadding: false, label: 'Next Session', width: "47%" },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status', width: "20%"},
    { id: 'action', numeric: true, disablePadding: false, label: '', width: "15%" },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action', width: "15%" },
  ];

  // fetching course data

  const getCourseListingData = () => {        
    // const orgId = localStorage.getItem('orgId');
    const userData = getUserData();
    const userId = userData.id;
    axios.get(`${baseUrl}/studentPortal/getStudentCourses?userId=${userId}&payNowBtn=true`).then((result) => {
      console.log(result.data.body);
      setCourseList(result.data.body);
      setOpenBackdrop(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {

    setOpenBackdrop(true);
    getCourseListingData();

  }, []);

  // Modal Add Category  start//

  const [openNewCategory, setOpenNewCategory] = useState(false);

  const handleOpenNewCategory = () => {
    setOpenNewCategory(true);
  };

  const handleCloseModalNewCategory = () => {
    console.log('close')
    setOpenNewCategory(false)
  };

  const handleConfirmNewCategory = () => {
    console.log('close')
    setOpenNewCategory(false)
  };

  // Modal Add Category  End//


  // == Drawer Start == //
  const [openDrawer, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // == Drawer End == //


  // == Modal Start == //
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    console.log('close')
    setOpenModal(false)
  };
  // == Modal End == //

  const handleConfirmModal = () => {
    console.log('confirm');

    setOpenBackdrop(true);

    let postData = {
      "paymentId" : paymentData.paymentId,
      "attachmentFile" : paymentReceipt,
      "markAsPaid": 1,
      "portal" : "learner",
    };

    axios.post(`${baseUrl}/studentPortal/updatePaymentsData`, postData)
      .then((response) => {
        // Handle the response from the server
        console.log(response.data);
        setOpenBackdrop(false);
        setOpenModal(false);
        handleDrawerClose();
        setPaymentReceipt(null);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error(error);
      });
    
  };
  // == Drawer End == //

  const btnHandleClick = (id, operation) => {
    console.log(id, operation);
    // retrive payment data
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/studentPortal/getPaymentDetails?paymentId=${id}&timezone=${timezone}`).then((result) => {
      console.log(result.data.body);
      setPaymentData(result.data.body);
      setCourseData(result.data.courseData);
      setOpenBackdrop(false);
      handleDrawerOpen();
    }).catch((err) => {
      console.log(err);
    });    
  }


  return (

    <>

      <SimpleBackdrop open={openBackdrop} />
      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />

      {courseList && 

      <div className="TableSection">
        
        {courseList.length > 0 ? 
        <Table
          tableHeader={true}
          innerTitle="In Progress"
          innerHeader={true}
          headCells={headCells}
          rows={courseList}
          btnHandleClick={btnHandleClick}
          AddButton={false}
          Checkbox={false}
          footer={true}
        />
        :
        <EmptyCard 
         TableTitle="My courses"
         TableTitleStatus={false}
          title="You will find your in-progress courses here." 
          description="Keep exploring to find a course!" 
          showButton={true} 
          btnLabel="Explore courses" 
          btnLink={mainWebsiteUrl} 
        />
        }        
      </div> }      

      {/*== SideDrawer ==*/}
      <SideDrawer
        title={<div className="TitlewituChip">${paymentData.amount? paymentData.amount/100 : 0} USD <StatusChip Status={paymentData.status? paymentData.status.charAt(0).toUpperCase() + paymentData.status.slice(1) : ""} /></div>} // Status="Paid, Failed, Canceled, Refund" //
        ButtonLabel="Mark As Paid"
        clickSaveButton={handleOpenModal}
        cancelButtonLabel="Close"
        DeleteButton={true}
        sideOpen="right"
        open={openDrawer}
        handleDrawerClose={handleDrawerClose}
        clickButtonLeft={handleDrawerOpen}
        FooterWithoutTab={true}
        body={
          <>
            <OrderDetails paymentData={paymentData} />

            {paymentData.method == "Zelle" && 
            <PaymentMethodZelle paymentData={paymentData} /> }
            {paymentData.method == "Cash" && 
            <PaymentMethodCash paymentData={paymentData} /> }
            {paymentData.method == "Check" && 
            <PaymentMethodCheck paymentData={paymentData} /> }

            <OrderItems courseData={courseData} />
          </>
        }

      />

      <Modals
        ModalTitle="Mark as paid"
        open={openModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
        ModalFooter={true}
        ButtonClose="Cancel"
        ButtonConfirm="Submit"
        width="540px"
        textAlign="center"
        ModalBody={<div>
          <div>MUNA Academy will be notified about your payment completion. Please add an attachment below (optional)</div>
          <div>
            {/* <FileUplaoder /> */}
            <FileUplaoder
                filePath={`students/${paymentData.userId}/payment-receipt/`}
                uploadButton="Upload Photo"
                file={paymentReceipt}
                fileType="image"                
                onUpload={(filePath) => setPaymentReceipt(filePath)}
                setSelectedFile={ () => {}}
                helpertext="Maximum size of 10 MB. JPG, JPEG, or PNG."
                showUploadButton={false}
                showThumb={true}
              />
          </div>

          </div>
          }
      />
      {/*== SideDrawer End ==*/}

    </>

  );
}


export default InProgress;





































