import React, { useState, useRef, useEffect } from 'react';
import Table from '../Table/Table';
import SideDrawer from '../SideDrawer/SideDrawer';
import UploadFromDevice from './UploadFromDevice';
import UploadFromYoutube from './UploadFromYoutube';
import EditMedia from './EditMedia';
import axios from 'axios'

import DraggableDialog from '../../hooks/Dialog';
import CustomizedSnackbar from '../../hooks/Snackbar';
//import internal functions
import { convertDate, convertFileSize, generaImageUrl, onFileUpload } from './Functions';
import SimpleBackdrop from '../../components/Form/Backdrop/SimpleBackdrop';
import YouTubeIcon from '@mui/icons-material/YouTube';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import MediaLibrarySelectMedia from './MediaLibrarySelectMedia';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import './MediaLibrary.css';


function MediaLibraryTableView(props) {

  //base url for api taken from .env file 
  const baseUrl = process.env.REACT_APP_PROJECT_ENV == "dev" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

  const [orgId, setOrgId] = useState(localStorage.getItem('orgId'));
  const [openNewMedia, setOpenNewMedia] = useState(false);

  const [items, setItems] = useState([]);

  const [totalItems, setTotalItems] = useState([]);

  const userToken = localStorage.getItem('userToken');
  axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;

  // const modalRef = useRef();
  const childRef = useRef(null);

  const [file_name, setFileName] = useState("");
  const [file_type, setFileType] = useState("");
  const [file_size, setFileSize] = useState("");
  const [file_url, setFileUrl] = useState("");
  const [file_id, setFileId] = useState("");
  const [created_at, setCreatedAt] = useState("");
  const [medianame, setMediaName] = useState("");
  const [fileMeta, setFileMeta] = useState({});

  const [openBackdrop, setOpenBackdrop] = useState(true);

  const [videoName, setVideoName] = useState("");

  const [imageName, setImageName] = useState("");

  const [videoUrl, setVideoUrl] = useState("");

  const [searchedText, setSearchedText] = useState("");

  const [addNewItem, setAddNewItem] = useState(false);


  const [showSnackbar, setShowSnackbar] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success

  const [deleteMedia, setDeleteMedia] = useState(false);

  const [imageNameError, setImageNameError] = useState(false);

  const [fileUploadError, setFileUploadError] = useState(false);

  const [isfileSelected, setFileSelected] = useState(false);

  const [imaneNameErrorMessage, setImageNameErrorMessage] = useState("");
  

  const handleDrawerOpenNewMedia = () => {
    setOpenNewMedia(true);
  };
  const handleDrawerCloseNewMedia = () => {
    setOpenNewMedia(false);
  };

  const [openEditMedia, setOpenEditMedia] = useState(false);
  const handleDrawerOpenEditMedia = (id) => (event) => {
    console.log("id", id);
    //find the data from the totalItems array
    let data = null;
    // console.log(totalItems)
    for (let i = 0; i < totalItems.length; i++) {
      if (totalItems[i].id === id) {
        data = totalItems[i];
        setFileName(data.file_name);
        setFileType(data.file_type);
        setFileSize(convertFileSize(data.file_size));
        setFileUrl(data.file_url);
        setFileId(data.id);
        setCreatedAt(convertDate(data.created_at));
        setMediaName(data.name);
        //json parse the file_meta and set the values
        setFileMeta(data?.file_meta ? JSON.parse(data?.file_meta) : {});
        break;
      }
    }

    console.log("data", data);
    setOpenEditMedia(true);
  };
  const handleDrawerCloseEditMedia = () => {
    setOpenEditMedia(false);
  };


  useEffect(() => {

    //call api to get media library data
    console.log("baseUrl", baseUrl)
    axios.get(`${baseUrl}/getAllMediaByOrgId?orgId=${orgId}`, {})
      .then(res => {
        console.log("res", res.data);
        //traverse through the data and set the values in the table
        let data = res.data;
        setTotalItems(data);
        let temp = [];
        data.map((item, index) => {
          // console.log("item", item);
          let file_type = item.file_type.charAt(0).toUpperCase() + item.file_type.slice(1);
          temp.push(
            {
              cell: [
                { Check:true, img: generaImageUrl( item ), imgStatus: true, linkText: item.name, linkTextStatus: true,  onClickLinkText: handleDrawerOpenEditMedia(item.id)},
                { text: file_type, },
                { text: convertFileSize(item.file_size), textcolor: item.file_type.startsWith("image") ? "#1B1B1F" : "#A4A7B0" },
                { text: convertDate(item.created_at) },
                // { text: "N/A",textcolor:"#A4A7B0" },
                { Button: true, buttonLabel: "Edit", buttonTextcolor: "#004FE0",buttonBorder: "#E4E8EC 1px solid", onClick: handleDrawerOpenEditMedia(item.id), },
              ],
            }
          )
        })
        setItems(temp);
        setOpenBackdrop(false);

      }
      )
      .catch(err => {
        console.log("err", err)
      }
      )

  }, [totalItems.length]);


  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log('close')
    setOpen(false)
    setDeleteMedia(false);
  };


  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Media', width: "50%" },
    { id: 'name', numeric: false, disablePadding: true, label: 'Type', width: "15%" },
    { id: 'name', numeric: false, disablePadding: true, label: 'Size', width: "15%" },
    { id: 'name', numeric: false, disablePadding: true, label: 'Date', width: "15%" },
    { id: 'name', numeric: false, disablePadding: true, label: '', width: "15%" },

  ];

  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");

  const deleteConfirm = () => {
    console.log("delete confirm clicked");
    setDeleteMedia(true);
  }

  const deleteMediaItem = () => {
    console.log("delete clicked");
    //open loading icon 
    setOpenBackdrop(true);
    //call api to delete the media
    axios.delete(`${baseUrl}/deleteMedia?mediaId=${file_id}`)
      .then(res => {
        console.log("res", res.data);
        //traverse through the data and set the values in the table
        let data = totalItems;
        let temp = [];
        let tempItem = [];
        data.map((item, index) => {
          console.log("item", item)
          if (item.id !== file_id) {
            let file_type = item.file_type.charAt(0).toUpperCase() + item.file_type.slice(1);
            temp.push(item);
            tempItem.push(
              {
                cell: [                  
                  { Check:true, img: generaImageUrl( item ), imgStatus: true, linkText: item.name, linkTextStatus: true, onClickLinkText: handleDrawerOpenEditMedia(item.id) },
                  { text: file_type, },
                  { text: convertFileSize(item.file_size), textcolor: item.file_type.startsWith("image") ? "#1B1B1F" : "#A4A7B0"  },
                  { text: convertDate(item.created_at) },
                  { Button: true, buttonLabel: "Edit", buttonTextcolor: "#004FE0",buttonBorder: "#E4E8EC 1px solid", onClick: handleDrawerOpenEditMedia(item.id), },
                ],
              }
            )

          }
        })
        setTotalItems(temp);
        setItems(tempItem);
        setOpenEditMedia(false);
        setOpen(false);
        setDeleteMedia(false);
        handleSnackbarOpen("Media deleted successfully", "success");
        setOpenBackdrop(false);
      }
      )
  }

  const [videoUpload, setVideoUpload] = useState(false);

  const handleTabChange = (event) => {
    if (event == 1) {
      setUploadButtonText("Import from YouTube");
      setVideoUpload(true);
    } else {
      setUploadButtonText("Upload Image");
      setVideoUpload(false);
    }
  };

  const handleSnackbarOpen = (message, type) => {
    setShowSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(type);
    // setOpenSnackbar(true);
    setOpen(false);
    //set opennewmedia to false
    setOpenNewMedia(false);
    // alert(message);
    setTotalItems([]);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const validateForm = () => {
    let validationError = false;
    if (videoName === "") {
      validationError = true;
      // handleValidation(validationError);
      alert("Please enter video name");
      return false;
    } else if (videoUrl === "") {
      validationError = true;
      // handleValidation(validationError);
      alert("Please enter video url");
      return false;
    }
    return true;
  };

  const clickSaveButton = async () => {
    console.log("save button clicked");

    setOpenBackdrop(true);

    if (videoUpload) {

      console.log("saveVideo");
      const validation = validateForm();
      if (validation) {
        // save data
        console.log("save data");
        console.log(videoName);

        //call to api via axios 
        const response = await axios.post(baseUrl + '/videoUpload', {
          name: videoName,
          orgId: orgId,
          url: videoUrl
        });

        console.log(response)

        setOpenBackdrop(false)
        //if response status is 200
        if (response.data.message == 'success') {
          console.log("response", response);
          setVideoName("");
          setVideoUrl("");

          //if addNewitem is true then clear the form
          if (addNewItem) {
            
            setShowSnackbar(true);
            setSnackbarMessage("Video uploaded successfully.");
            setSnackbarSeverity("success");
          } else {
            handleSnackbarOpen("Video uploaded successfully.", "success");
          }
          //set total items to empty 
          setTotalItems([]);
        } else {
          handleSnackbarOpen("Something went wrong.", "error");
        }
      }

    }else{
      // console.log("hi")
      if(imageName === ""){
        setImageNameError(true);
        setImageNameErrorMessage("Please enter media name")
        setOpenBackdrop(false);
        return false;
      }else{
        setImageNameError(false);
        setImageNameErrorMessage("")
      }
      if( isfileSelected === false){
        setFileUploadError(true);
        setOpenBackdrop(false);
        return false ; 
      }
      if(childRef.current){
        childRef.current.uploadFile();
      }
      
    }
    setOpenBackdrop(false);
  };

  const updateMedia = async () => {
    // console.log("update media");
    setOpenBackdrop(true)
    //call api to update the media 
    const response = await axios.post(baseUrl + '/updateMedia', {
      name: medianame,
      orgId: orgId,
      mediaId: file_id
    });
    setOpenBackdrop(false)
    //if response status is 200
    if (response.data.message == 'success') {
      // console.log("response", response);
      handleSnackbarOpen("Media updated successfully.", "success");
      //set open edit media to false to close the edit drawer
      setOpenEditMedia(false);
    } else {
      handleSnackbarOpen("Something went wrong.", "error");

    }

  }

  //this is for search text saving in the state 

  const handleSearchText = (event) => {
    
    setSearchedText(event.target.value.toLowerCase());
    //if searchtext length is 0 then trigger handlesearch
    // if (event.target.value.length == 0) {
      handleSearch();
    // }    
    
  }

  const handleSearch = () => {
    // console.log("search clicked");
    setOpenBackdrop(true);
    // console.log("search text", searchedText);
    //get over all the items and filter the data
    let data = totalItems;
    let temp = [];
    let tempItem = [];
    data.map((item, index) => {
      // console.log("item", item)
      if (item.name.toLowerCase().includes(searchedText)) {
        let file_type = item.file_type.charAt(0).toUpperCase() + item.file_type.slice(1);

        temp.push(item);
        tempItem.push(
          {
            cell: [
              { Check:true, img: generaImageUrl( item ), imgStatus: true, linkText: item.name, linkTextStatus: true, onClickLinkText: handleDrawerOpenEditMedia(item.id) },
              { text: file_type, },
              { text: convertFileSize(item.file_size), textcolor: item.file_type.startsWith("image") ? "#1B1B1F" : "#A4A7B0" },
              { text: convertDate(item.created_at) },
              { Button: true, buttonLabel: "Edit", buttonTextcolor: "#004FE0",buttonBorder: "#E4E8EC 1px solid", onClick: handleDrawerOpenEditMedia(item.id), },
              
            ],
          }
        )

      }
    }
    )
    setItems(tempItem);
        
    setOpenBackdrop(false);

  }

  //function to process fileupload 
  const processFileUpload = async (filePath, fileName, fileSize, fileType, width, height) => {
    setOpenBackdrop(true);
    const response = await onFileUpload(imageName, filePath, fileName, fileSize, fileType, width, height);
    
    if(response){
      //show snackbar success message 
      let message = "File uploaded successfully.";
      let type = "success" ;

      setShowSnackbar(true);
      setSnackbarMessage(message);
      setSnackbarSeverity(type);

      //set total items to empty
      setTotalItems([]);
      //set opennewmedia to false

      setImageName("");

      if(addNewItem){
        
        if (childRef.current) {
          childRef.current.clickButton();
        }
      }else{
        
        setOpen(false);
        setOpenNewMedia(false);
      }
      
    }else{
      handleSnackbarOpen("Something went wrong.", "error");
    }
    setOpenBackdrop(false);
  }
  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }


  const fileSelected = (status) => {    
    setFileSelected(status);
  }
  const handleCheckboxClicked = (event) => {
    console.log(event.target.checked);
    setAddNewItem(event.target.checked);
  };


  const tabData = [
    {
      lebel: "Upload from device",
      icon: <UploadFileRoundedIcon />,
    },

    {
      lebel: "YouTube video",
      icon: <YouTubeIcon />,
    },
  ]

  const panelTab = [
    {
      id: 0,
      panleBody: <UploadFromDevice
        handleTabChange={handleTabChange}
        handleSnackbarOpen={handleSnackbarOpen}        
        setImageName={setImageName}
        videoName={videoName}
        videoUrl={videoUrl}
        imageName={imageName}     
        showImageNameError={imageNameError}
        onFileUpload={processFileUpload}
        ref={childRef}
        fileSelected={fileSelected}
        showFileUploadError={fileUploadError}
        imageErrorMessage={imaneNameErrorMessage}
      />,

      SaveButtonLabel: "Upload File",
      clickSaveButton:clickSaveButton,
      CancelButtonLabel: "Cancel",

      Checkbox: true,
      CheckboxLabel: "Add another after saving",
      //onClickCheckbox:onClickCheckbox,

      DeleteButton: false,
      DeleteLebel: "Delete Permanently",
      onClickCheckbox: handleCheckboxClicked,
      handleTabChange : handleTabChange,    
      //onClickDelete:onClickDelete,
    },

    {
      id: 1,
      panleBody: <UploadFromYoutube 
        setVideoName= {setVideoName} setVideoUrl= {setVideoUrl} 
        handleSnackbarOpen={handleSnackbarOpen} 
        onCheckboxClicked={handleCheckboxClicked}
        videoName={videoName} videoUrl={videoUrl}
         />,
      SaveButtonLabel: "Import From YouTube",
      clickSaveButton: clickSaveButton, 
      cancelButtonLabel: "Cancel",
      videoUrl: videoUrl,
      videoName: videoName,
      CancelButtonLabel: "Cancel", 
      handleTabChange : handleTabChange,    
      Checkbox: true,
      CheckboxLabel: "Add another after saving",
      onClickCheckbox: handleCheckboxClicked,
    },

  ]


  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };

  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="TableSection">
        <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
        <Table
          title="Media Library"
          tableHeader={true}
          addLabel="Add New"
          headCells={headCells}
          rows={items}
          AddButton={true}
          onClick={handleDrawerOpenNewMedia}
          Checkbox={false}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          innerHeader={true}
          showSearchBox={true}
          FilterButton={false}
          searchBoxPlaceholder="Search"
        />
      </div>

      {/* <div className="TableSection">
        <button onClick={() => handleDrawerOpenSelectMedia()}>Select Media</button>
      </div> */}


      <SideDrawer
        title="Media Library"
        ButtonLabel="Add"
        //clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openSelectMedia}
        handleDrawerClose={handleDrawerCloseSelectMedia}
        FooterWithoutTab={true}
        LeftButton={true}
        ButtonLeft="Upload New"
        ButtonLeftIcon={<FileUploadOutlinedIcon/>}
        clickButtonLeft={handleDrawerOpenNewMedia}
        
        body={
          <MediaLibrarySelectMedia />
        }

      />

      <SideDrawer
        title="Upload New Media"
        ButtonLabel={uploadButtonText}
        clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openNewMedia}
        handleDrawerClose={handleDrawerCloseNewMedia}
        CheckboxLabel="Add another after saving"
        Checkbox={true}
        onCheckboxClicked={handleCheckboxClicked}
        FooterWithoutTab={false}
        handleTabChange={handleTabChange}
        //=== Tabs Codes ===//
        TabsStatus={true}
        TabsPannelStatus={true}
        panelTab={panelTab}
        tabData={tabData}
      />

      <SideDrawer
        title="Edit Media"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        onClickDelete={deleteConfirm}
        clickSaveButton={updateMedia}
        DeleteButton={true}
        sideOpen="right"
        open={openEditMedia}
        handleDrawerClose={handleDrawerCloseEditMedia}
        CheckboxLabel="Add another after saving"
        FooterWithoutTab={true}
        body={
          <EditMedia file_name={file_name}
            file_type={file_type}
            file_size={file_size}
            file_url={file_url}
            file_id={file_id}
            created_at={created_at}
            medianame={medianame}
            setMediaName={setMediaName}
            fileMeta={fileMeta}

          />
        }
      />

      <DraggableDialog
        title="Delete this media?"
        body={<div>Are you sure you want to delete this?<br></br> Once you delete it, you can't get it back.</div>}
        openDialog={deleteMedia}
        handleCloseDialog={handleClose}
        ModalFooter={true}
        ButtonClose="Cancel"
        ButtonConfirm="Delete"
        width="460px"
        textAlign="center"
        handleConfirmCloseDialog={deleteMediaItem}
      />

    </>


  );
}


export default MediaLibraryTableView;