import React, { useState } from "react";
import axios from 'axios';

import { Grid } from '@material-ui/core/';
import Buttons from '../../components/Form/Button/Button';
import TextField from '../../components/Form/TextField/TextField';
import Header from './header/Header';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import './Form.css';
import CustomizedSnackbar from '../../hooks/Snackbar';
import DeenLogo from '../../assets/imgs/deen360-logo.png';

function ForgotPassword(props) {

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [email, setEmail] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [resendBtn, setResendBtn] = React.useState(false);
  const [formValidationError, setFormValidationError] = useState(false);

  const handleChanges = (fieldName) => (event) => {
    const userEmail = event.target.value;
    console.log(userEmail);
    setEmail(userEmail);
  };

  const processResponse = (response) => {

    setResendBtn(true);
    setBtnDisabled(false);

    if (response.data.body.status == true) {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage("Email sent");
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.body.message);
    }

  }

  const sendForgotPasswordRequest = () => {

    console.log(email);

    if (formValidationError == false) {

      const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
      const postData = { email: email, passwordResetURL: `${window.location.origin}/reset-password` };

      setBtnDisabled(true);

      axios.post(`${baseUrl}/forgotPassword`, postData)
        .then(response => processResponse(response))
        .catch((err) => {
          console.log(err);
          if (err.response.data.message) {
            setBtnDisabled(false);
            setShowSnackbar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage(err.response.data.message);
          }
        });

    }

  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const handleValidation = (validationError) => {
    // set form validation error
    if (validationError) {
      setFormValidationError(true);
    } else {
      setFormValidationError(false);
    }
  }

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
          {!resendBtn ?
            <div className="SignForm">
              <h1>Forgot password?</h1>
              <div className="formContainer">
                <div className="SignFormGrid">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <p style={{ textAlign: "center", }}>Enter the email address below that you use with your TAKBIR account.</p>
                    </Grid>

                    <Grid item xs={12} md={12} className="HideLabelClass">
                      <TextField
                        required={true}
                        email={true}
                        value={email}
                        handleValidation={handleValidation}
                        handleChange={handleChanges("email")}
                        placeholder="Email address"
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Buttons
                        label="Send reset email"
                        buttonColor="#004FE0"
                        border="#ccc 1px solid"
                        color="#fff"
                        width="100%"
                        height="48px"
                        fontSize="14px"
                        link=""
                        onClick={() => sendForgotPasswordRequest()}
                        disabled={btnDisabled}
                      />

                      <div className="BottomButton">
                        <Buttons
                          label="Cancel"
                          color="#004FE0"
                          width=""
                          height="48px"
                          fontSize="14px"
                          link="/signin"
                        />
                      </div>
                    </Grid>

                  </Grid>
                </div>
              </div>
            </div>
            :
            <div className="SignForm">
              <h1 className="FontMedium">Thanks, email has been sent <br></br>with reset password instructions</h1>
              <Grid container spacing={3}>

                <Grid item xs={12} md={12}>
                  <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
                </Grid>

                <Grid item xs={12} md={12}>

                  <p style={{ textAlign: "center", margin: "0px", fontSize: "14px" }}>If you haven't received an email in 5 minutes, check your spam or</p>

                  <div style={{ paddingTop: "4px", marginBottom: "5px" }}>
                    <Buttons
                      label="resend"
                      color="#004FE0"
                      width="100%"
                      height="48px"
                      fontSize="14px"
                      link=""
                      onClick={() => sendForgotPasswordRequest()}
                    />
                  </div>

                  <Buttons
                    label="Back To Sign In"
                    buttonColor="#004FE0"
                    border="#ccc 1px solid"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    link="/signin"
                  />

                </Grid>

              </Grid>
            </div>
          }

          <div className="SignFormFooter">
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>

      </div>
    </>
  );
}


export default ForgotPassword;

