import React, { useEffect, useState } from "react";

import Header from '../../../components/Header/Header';
import Sidebar from '../../org-portal/sidebar/Sidebar';

import AlrtMessage from '../../../components/Form/AlrtMessage/AlertMessage';
import Table from '../../../components/Table/Table';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Buttons from '../../../components/Form/Button/Button';

function createData(name, Status, ofcourses, Action) {
  return { name, Status, ofcourses, Action };
}
function CategoriesListing(props) {
  const onClick = () => {
    alert("onclick working fine!");
  }


  const headCells = [

    { id: 'name', numeric: true, disablePadding: false, label: 'Name', width: "30%" },
    { id: '#of courses', numeric: true, disablePadding: false, label: '#of courses', width: "20%" },
    { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: "10%" },
    { id: '', numeric: true, disablePadding: false, label: '', width: "20%" },
    { id: '', numeric: true, disablePadding: false, label: '', width: "10%" },
    { id: '', numeric: true, disablePadding: false, label: '', width: "10%" },

  ];

  const rows = [
    {
      cell: [
        { textBold: "Quran", },
        { text: "10", },
        { linkText: "", label: "Active", Status: true, background: "#E1F2E8", color: "#0B9444", },
        { text: "", },
        { Status: false, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
        { ActionButton: true, ActionButtonLabel: "Action" },

      ],


      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //

    }];

  // Modal Add Category  start//

  const [openNewCategory, setOpenNewCategory] = useState(false);

  const handleOpenNewCategory = () => {
    setOpenNewCategory(true);
  };

  const handleCloseModalNewCategory = () => {
    console.log('close')
    setOpenNewCategory(false)
  };

  const handleConfirmNewCategory = () => {
    console.log('close')
    setOpenNewCategory(false)
  };

  // Modal Add Category  End//


  return (

    <>

      <AlrtMessage
        message="Course added"
        background="#0B9444"
      />

      <div className="TableSection">
        <Table
          title="Categories"
          addLabel="New Categories"
          link="#"
          headCells={headCells}
          rows={rows}
          AddButton={true}
          tableHeader={true}
          Checkbox={false}
          footer={true}
        />
      </div>

    </>

  );
}


export default CategoriesListing;





































