import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from 'axios';

import {getUserData, getUserTimezone} from '../../../hooks/HelperFunctions';

import CustomizedSnackbar from '../../../hooks/Snackbar';
import SimpleBackdrop from '../../../components/Form/Backdrop/SimpleBackdrop'
import VerticalTab from '../../../components/VerticalTab/VerticalTab';
import { Person, Receipt, Security } from "@mui/icons-material";
import PersonalInfo from './personal-info/PersonalInfo';
import Billing from './billing/Billing';
import Account from './account/Account';
import Avatar from '@mui/material/Avatar';
import { deepPurple, } from '@mui/material/colors';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import Buttons from '../../../components/Form/Button/Button';



function StudentsSettings(props) {

  const [fetchLatestData, setFetchLatestData] = useState(true);
  const [profileEditingReady, setProfileEditingReady] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [totalRunningCourses, setTotalRunningCourses] = useState(0);
  const [studentBillingData, setStudentBillingData] = useState(0);
  const [totalCompletedCourses, setTotalCompletedCourses] = useState(0);
  const [totalCirtificates, setTotalCirtificates] = useState(0);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [personalInfo, setPersonalInfo] = React.useState([]);
  const [gradeLevels, setGradeLevels] = React.useState([]);
  const [ageGroups, setAgeGroups] = React.useState([]);
  const [timeZones, setTimeZones] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [highestEducation, setHighestEducation] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [editProfile, setEditProfile] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);

  const [startTab, setStartTab] = useState(0);

  const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
  const timezone = getUserTimezone();
  const userData = getUserData();
  const userId = userData.id;

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  }

  const triggerSnackbar = (status = false, severity = "", message = "") => {
    setShowSnackbar(status);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  }


  // fetching course data

  const getStudentData = () => {
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/studentPortal/getStudentData?userId=${userId}&timezone=${timezone}`).then((result) => {
      console.log(result.data.body);
      setStudentData(result.data.body.studentDetails);
      setTotalRunningCourses(result.data.body.totalRunningCourses);
      setOpenBackdrop(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  const getStudentBillingData = () => {    
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/studentPortal/getStudentBillingData?userId=${userId}&actionCol=true&payNow=true&timezone=${timezone}`).then((result) => {
      console.log(result.data.body);
      setStudentBillingData(result.data.body);
      setOpenBackdrop(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  const getPersonalInfoModalData = () => {
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/studentPortal/getPersonalInfoModalData?userId=${userId}`).then((result) => {
      console.log(result.data.body);
      setPersonalInfo(result.data.body.studentDetails);
      setAgeGroups(result.data.body.ageGroups);
      setGradeLevels(result.data.body.gradeLevels);
      setTimeZones(result.data.body.timezones);
      setCountries(result.data.body.countries);
      setHighestEducation(result.data.body.highestEducation);
      setOpenBackdrop(false);
      setProfileEditingReady(true);
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {

    if (fetchLatestData) {      
      setFetchLatestData(false);
      getStudentData();
      getStudentBillingData();
      getPersonalInfoModalData();      
    }
  }, [fetchLatestData]);

  useEffect( () => {
    //get the url params and set the tab
    if( startTab) {
      let studentTab = searchParams.get("activeTab");
      console.log("activeTab", studentTab);
      setStartTab(studentTab);
    }
  }, [startTab]);

  const handleEditProfile = () => {
    setEditProfile(true);
  }

  
  let studentEditProfile = searchParams.get("editProfile");

  useEffect(() => {

    if (profileEditingReady === true && studentEditProfile === "true") {      
      handleEditProfile();
    }

  }, [profileEditingReady]);

  const tabData = [
    {
      icon: <Person />,
      label: "Profile",
    },

    {
      icon: <AdminPanelSettingsIcon />,
      label: "Security",
    },

    {
      icon: <Receipt />,
      label: "Billing",
    },

  ]

  const tabBody = [

    {
      id: 0,
      panel:
        <PersonalInfo
          studentData={studentData}
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          gradeLevels={gradeLevels}
          ageGroups={ageGroups}
          timeZones={timeZones}
          countries={countries}
          highestEducation={highestEducation}
          setEditProfile={setEditProfile}
          editProfile={editProfile}
          triggerSnackbar={triggerSnackbar}
          setFetchLatestData={setFetchLatestData}
        />,
    },
    {
      id: 1,
      panel: <Account studentData={studentData} />,
    },
    {
      id: 2,
      panel: <Billing studentBillingData={studentBillingData} />,
    },

  ]

  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  }


  return (
    <div className="">
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="UserDetails">
        <li>

          <div className="UserDetailsleftcol">
            <div className="UserDetailsrIcon">
              {studentData.image ?
                <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} src={studentData.image ? studentData.image : ""} ></Avatar>
                :
                <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} > {studentData.firstName ? Array.from(studentData.firstName)[0] : ""} </Avatar>
              }
            </div>

            <div className="UserDetailsDetail">
              {studentData?.firstName && <h1>{`${studentData.firstName} ${studentData.lastName}`}</h1> }
              {studentData?.email && <p>{studentData.email}</p>}
            </div>
          </div>

          {currentTab == 0 && 
          <div className="EditUserProfile">
            <Buttons
              label="Edit Profile"
              buttonColor="#fff"
              border="#E4E8EC 1px solid"
              color="#004FE0"
              width=""
              height="48px"
              fontSize="14px"
              onClick={() => handleEditProfile()}
            />
          </div>}

        </li>

        {/* <div className="UserStatus">
          <div className="UserStatusCol">{totalRunningCourses} Running Courses</div>
          <div className="UserStatusCol">{totalCompletedCourses} Completed Courses</div>
          <div className="UserStatusCol">{totalCirtificates} Certificates</div>
        </div> */}

      </div>

      <VerticalTab
        //title="Introduction to Islamic Economics and Finance"
        //name="MUNA Academy"
        link="#"
        tabData={tabData}
        tabBody={tabBody}
        onTabSwitch={handleTabSwitch}
        activeTab={startTab}
      //onClick={onClick()}
      //ButtonLabel="Start Class"
      />
      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
    </div>
  );
}


export default StudentsSettings;

