import React from "react";
import VerticalTab from '../../../../components/VerticalTab/VerticalTab';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import Overview from './overview/Overview';
import Securityy from './security/Security';
import Courses from './courses/Courses';
import { Person, Receipt, Security } from "@mui/icons-material";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';






import TableCell from '@mui/material/TableCell';
import { ArrowForwardIos, VerifiedUser } from "@mui/icons-material";


function InstructorDetails(props) {



  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabData = [
    {
      icon: <Person />,
      label: "Overview",
    },

    {
      icon: <EventRoundedIcon />,
      label: "Calendar",
    },

    {
      icon: <MenuBookRoundedIcon />,
      label: "Courses",
    },
    {
      icon: <Receipt />,
      label: "Payments",
    },
    {
      icon: <Security />,
      label: "Security",
    },



  ]

  const tabBody = [

    {
      id: 0,
      panel: <Overview />,
    },
    {
      id: 1,
      panel: "Calender",
    },
    {
      id: 2,
      panel: <Courses />,
    },

    {
      id: 3,
      panel: "Payments",
    },

    {
      id: 4,
      panel: <Securityy />,
    },







  ]




  return (
    <div className="">

      <div className="SessionListing">
        <li>
          <div className="Sessionleftcol">

            <div className="SessionrIcon">
              <span>
                <Person />
              </span>
            </div>

            <div className="SessionListingDetail">

            <span style={{display:"inline-block"}}><h4>Dr. Omar Suleiman</h4></span>  <span  style={{display:"inline-block"}} className="status rounded success">Active</span>
              <p>notrealomarsuleiman@gmail.com</p>
            </div>

          </div>

          <div className="SessionListingAction">
            <div align="left">


              <Button className="ActionButton" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Action <KeyboardArrowDownRoundedIcon />
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>

            </div>

          </div>

        </li>



      </div>

      <VerticalTab
        //title="Introduction to Islamic Economics and Finance"
        //name="MUNA Academy"
        link="#"
        tabData={tabData}
        tabBody={tabBody}
      //onClick={onClick()}
      //ButtonLabel="Start Class"
      />

    </div>
  );
}


export default InstructorDetails;

