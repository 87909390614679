import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import tableImg from '../../../../../assets/imgs/tableImg.png';

import '../../../../../assets/css/PaymentMethod.css';


const PaymentMethodZelle = (props) => {

  const {paymentData} = props;

  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "#F8F8F9",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========/


  return (
    <>

      <div className="PaymentMethod">
        <div className="PaymentMethodTitle">Payment Details</div>
        <div className="TableContainer oddEvenTable">

          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableBody>

                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Method</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">Zelle</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Recipient account</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">munaacademy@gmail.com</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Sender account</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">{paymentData.zelleAcc}</StyledTableCell>
                </StyledTableRow>

                {paymentData.attachmentFile && 
                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Picture/Receipt</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <div className="Pmt-ImgBox">
                      <div className="PmtImg">
                        <img className="item-icon" src={paymentData.attachmentFile} />
                      </div>
                      <div className="PmtInfo">
                        <p>{paymentData.attachmentFile.slice(-15)}</p>
                        <a href={paymentData.attachmentFile} target="_blank">View Photo</a>
                      </div>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>}

              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodZelle;


