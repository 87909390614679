import React, { useState, useRef, useEffect } from 'react';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import SelectIndividual from './SelectIndividual';


function EnrollLearners(props) {

  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };


  return (
    // * when we need the commented section then remove inline style property from the parent div
    <div style={{marginTop: '-40px'}}> 
    <SelectIndividual />
      {/* <div className="MediaLibTabsContainer">
        <Tabs value={value} onChange={handleChangeTabs}>
          <Tab label={<div className='tabsContent'><PersonRoundedIcon /> Select Individual</div>} />
          <Tab label={<div className='tabsContent'><GroupsRoundedIcon /> Select Group</div>} />
        </Tabs>
      </div>

      <div className="MediaLibPanelContainer">
        <TabPanel value={value} index={0}>
          <SelectIndividual />
        </TabPanel>
      </div> */}
    </div>


  );
}




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default EnrollLearners;

