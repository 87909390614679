import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './CheckBox.css';



// const [state, setState] = React.useState({
//   checkedA: true,
// });

// const handleChangeCheck = (event) => {
//   setState({ ...state, [event.target.name]: event.target.checked });
// };

{/* <CheckBox
  label="Set this as most popular"
  checked={state.checkedB}
  onChange={handleChangeCheck}
/> */}




function CheckBox(props) {
  const { checked, onChange, label, disabled } = props;

  return (

    <div className="CheckBoxContianer">

      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name="checkedB"
            color="primary"
            disabled={disabled}
          />
        }
        label={label}
      />

    </div>
  );
}

export default CheckBox;

