import React, { useState, useEffect } from "react";
import axios from 'axios';

import AlrtMessage from '../../../../../components/Form/AlrtMessage/AlertMessage';
import SideDrawer from '../../../../../components/SideDrawer/SideDrawer';
import CustomizedSnackbar from '../../../../../hooks/Snackbar';
import DraggableDialog from '../../../../../hooks/Dialog';
import EmptyCard from '../../../../../components/EmptyCard/EmptyCard';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Button from '../../../../../components/Form/Button/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import PricingOption from './PricingOption';
import Grid from '@material-ui/core/Grid';
import InfoAlert from '../InfoAlert';
import SimpleBackdrop from '../../../../../components/Form/Backdrop/SimpleBackdrop';


function Pricing(props) {

  const {trackId, trackDetails, trackPricing, getTrackDetailsInfo} = props;

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [fullPrice, setFullPrice] = useState({'pricingOption': "fullPrice", 'price': 0, "compareAtPrice": 0, "paymentFrequency": 0, "totalNumOfPayments": 1, "mostPopular": 0, "enabled": 0, 'amountPerPayment' : 0 });
  const [yearlyPrice, setYearlyPrice] = useState({'pricingOption': "yearlyPrice", 'price': 0, "compareAtPrice": 0, "paymentFrequency": 0, "totalNumOfPayments": 1, "mostPopular": 0, "enabled": 0, 'amountPerPayment' : 0 });
  const [monthlyPrice, setMonthlyPrice] = useState({'pricingOption': "monthlyPrice", 'price': 0, "compareAtPrice": 0, "paymentFrequency": 1, "totalNumOfPayments": 1, "mostPopular": 0, "enabled": 0, 'amountPerPayment' : 0 });
  const [totalTrackPrice, setTotalTrackPrice] = useState(0);


  useEffect(() => { 
    if(trackPricing.fullPrice){
      setFullPrice(trackPricing.fullPrice);
    }

    if(trackPricing.yearlyPrice){
      setYearlyPrice(trackPricing.yearlyPrice);
    }

    if(trackPricing.monthlyPrice){
      setMonthlyPrice(trackPricing.monthlyPrice);
    }
  }, [trackPricing]);

  useEffect( () => {
    setTotalTrackPrice(trackDetails.totalTrackPrice);
  }, [trackDetails]);

  
  //base url for api taken from .env file 
  const baseUrl = process.env.REACT_APP_PROJECT_ENV == "dev" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

  //======= Drawer ===== //
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //======= Drawer ===== //


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  //======= Delete dialog ===== //


  const handleOpenDialog = (id) => {
    console.log(id);
    // setActiveCourseId(id);
    // setDialogTitle("Delete this track?");
    // setDialogBody("Are you sure, you want to delete a course record and it's related data?");
    setOpenDialog(true);
  }

  const onClick = () => {
    alert("onclick working fine!");
  }

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const showSnackbarMessage = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  }

  const updateTracksPricing = () => {

    const trackPricingData = [fullPrice, yearlyPrice, monthlyPrice];

    console.log("Track pricing data", trackPricingData);

    if(false){
      showSnackbarMessage("error", "One or more fields are required.");
    } else{
      // show loader
      setOpenBackdrop(true);
      axios.post(`${baseUrl}/orgPortal/updateTracksPricing`, {'trackId': trackId, 'totalTrackPrice': totalTrackPrice, 'prices' : trackPricingData})
      .then(response => showDataPostMessage(response));
    }
  }

  const showDataPostMessage = (response) => {

    console.log(response.data);

    // hide loader
    setOpenBackdrop(false);

    const error = typeof response.data.error == 'undefined' ? true : response.data.error;

    if (error) {
      showSnackbarMessage("error", response.data.message);
    } else{
      showSnackbarMessage("success", "Track pricing updated successfully");
      handleDrawerClose();
      getTrackDetailsInfo();
    }
    
  }

  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "#FCFCFC",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//


  return (

    <>

      <SimpleBackdrop open={openBackdrop} />

      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />

      <Grid container spacing={3}>

        {trackPricing?.length == 0? 
        <Grid item xs={12} sm={12} md={12}>
          <EmptyCard
            title="Pricing options"
            description="Enable pricing options"
            showButton={true}
            btnLabel="Add Pricing"
            onClick={handleDrawerOpen}
            icon={<AddRoundedIcon />}
          />
        </Grid>
        :
        <Grid item xs={12} sm={12} md={12}>
          <div className="TableContainer oddEvenTable">
            <div className="TableInnerHeader">
              <h3>Pricing options</h3>
              <Button
                label="Edit Pricing"
                icon={<ModeEditRoundedIcon />}
                buttonColor="#004FE0"
                border="#E4E8EC 1px solid"
                color="#fff"
                width=""
                height="40px"
                fontSize="14px"
                link=""
               onClick={handleDrawerOpen}

              />
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>

                {trackDetails?.totalTrackPrice && 
                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel" width="23%">Full price</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">${Math.round(trackDetails.totalTrackPrice)}</StyledTableCell>
                  </StyledTableRow>}

                  {fullPrice.enabled && 
                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel" width="23%">Pay in full</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">${Math.round(fullPrice.price)}</StyledTableCell>
                  </StyledTableRow>}

                  {yearlyPrice.enabled && 
                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel" width="23%">Yearly</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">${Math.round(yearlyPrice.price)}</StyledTableCell>
                  </StyledTableRow>}

                  {monthlyPrice.enabled && 
                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel" width="23%">{monthlyPrice.paymentFrequency == 1? "Monthly" : `Every ${monthlyPrice.paymentFrequency} Month`}</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">${Math.round(monthlyPrice.price)}</StyledTableCell>
                  </StyledTableRow>}

                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>}

      </Grid>

      <SideDrawer
        title="Pricing options"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        clickSaveButton={updateTracksPricing}
        sideOpen="right"
        open={open}
        FooterWithoutTab={true}
        handleDrawerClose={handleDrawerClose}
        body={
          <PricingOption 
            fullPrice={fullPrice}
            setFullPrice={setFullPrice}
            yearlyPrice={yearlyPrice}
            setYearlyPrice={setYearlyPrice}
            monthlyPrice={monthlyPrice}
            setMonthlyPrice={setMonthlyPrice}
            totalTrackPrice={totalTrackPrice}
            setTotalTrackPrice={setTotalTrackPrice}
          />
        }
      />

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        title="Remove course from track?"
        body={<div>Are you sure you want to remove this course?  <br></br>You can add the course again to this track.</div>}
        ModalFooter={true}
        actionButton="Delete"
      />


    </>
  );
}


export default Pricing;

