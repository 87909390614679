import React, { useEffect, useState } from "react";

import Table from '../../../../../components/Table/Table';


function createData(Invoice,Status, Date, Completed,  Action,) {
  return { Invoice, Date, Status, Completed,  Action, };
}

function Courses(props) {


  const onClick = () => {
    alert("onclick working fine!");
  }

  const headCells = [
    { id: 'Invoice', numeric: false, disablePadding: true, label: 'Course', width: "30%" },
    { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: "20%" },
    { id: 'Date', numeric: true, disablePadding: false, label: 'Start date', width: "20%" },
    { id: 'Completed', numeric: true, disablePadding: false, label: 'Completed', width: "20%" },
    { id: 'Action', numeric: true, disablePadding: false, label: '', width: "20%" },
  ];

  const rows = [
    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
        { date: "Jan 14, 2022", },
        { date: "Sep 10, 2022", },
        { Button: true, buttonLabel: "Details", buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget:"_blank",},

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#FFF5E6", color: "#FFAE35", label: "Upcoming", },
        { date: "Jan 14, 2022", },
        { date: "N/A", },
        { Button: true, buttonLabel: "Details", buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget:"_blank",},

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#EEECFF", color: "#7266FB", label: "In Progress", },
        { date: "Jan 14, 2022", },
        { date: "N/A", },
        { Button: true, buttonLabel: "Details", buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget:"_blank",},

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#EEECFF", color: "#7266FB", label: "In Progress", },
        { date: "Jan 14, 2022", },
        { date: "N/A", },
        { Button: true, buttonLabel: "Details", buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget:"_blank",},

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#EEECFF", color: "#7266FB", label: "In Progress", },
        { date: "Jan 14, 2022", },
        { date: "N/A", },
        { Button: true, buttonLabel: "Details", buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget:"_blank",},

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },


  ];


  return (
    <div className="TableSection">
      <Table
        title=""
        tableHeader={false}
        innerTitle="Courses"
        innerHeader={true}
        addLabel="New User"
        link="add-courses"
        headCells={headCells}
        rows={rows}
        AddButton={true}
        Checkbox={false}
        footer={true}
      />
    </div>
  );
}






export default Courses;

