import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import axios from "axios";

import OrgAuth from "./routing/OrgAuth";
import StudentAuth from "./routing/StudentAuth";
import OwnerAuth from "./routing/OwnerAuth";

import "./App.css";

/** public pages start */
import SignInOrgID from "../src/pages/authentication/SignInOrgID";
import SignInOrgEmail from "../src/pages/authentication/SignInOrgEmail";
import SignIn from "../src/pages/authentication/SignIn";
import ForgotPassword from "../src/pages/authentication/ForgotPassword";
import Otp from "../src/pages/authentication/Otp";
import ResetPassword from "../src/pages/authentication/ResetPassword";
import TokenBasedSignIn from "../src/pages/authentication/TokenBasedSignIn";
import SignInAddPhoneNumber from "../src/pages/authentication/SignInAddPhoneNumber";
/** public pages end */

/** org pages start */
import Courses from "../src/pages/org-portal/courses/CourseListing";
import AddCourse from "../src/pages/org-portal/courses/add/add";
import CourseDetails from "../src/pages/org-portal/courses/details/DetailsPage";

import UserListing from "../src/pages/org-portal/users/UserListing";
import AddUser from "../src/pages/org-portal/users/addUser/addUser";
import UsersDetails from "../src/pages/org-portal/users/details/DetailsPage";

import TeamListing from "./pages/org-portal/team/TeamListing";
import TeamMemberDetails from "./pages/org-portal/team/details/TeamMemberDetails";
import TracksListing from "../src/pages/org-portal/tracks/TracksListing";
import TracksDetails from "../src/pages/org-portal/tracks/details/DetailsPage";

import CategoriesListing from "../src/pages/org-portal/categories/CategoriesListing";
import FeaturedListing from "../src/pages/org-portal/featured/FeaturedListing";

import InstructorsListing from "../src/pages/org-portal/instructors/InstructorsListing";
import InstructorDetails from "../src/pages/org-portal/instructors/details/DetailsPage";

import Coupons from "../src/pages/org-portal/coupons/Coupons";

/** org pages end */

import TableData from "../src/components/Table/TableData";

/** student pages start */
import MyLearning from "./pages/student-portal/courses/MyLearning";
import StudentCourseDetails from "../src/pages/student-portal/courses/details/DetailsPage";
import StudentsSettings from "../src/pages/student-portal/settings/SettingsPage";
import Calendar from "../src/pages/student-portal/calendar/Calendar";
import Certificates from "../src/pages/student-portal/certificates/Certificates";
import MediaLibrary from "../src/components/MediaLibrary/MediaLibrary";
import MediaLibraryTableView from "../src/components/MediaLibrary/MediaLibraryTableView";
import TrackDetails from "./pages/student-portal/courses/details/TrackDetails";
/** student pages end */

/** owner pages start */
import OwnerCustomerListing from "../src/pages/owner-portal/OwnerCustomerListing";
import OwnerCustomerAddNew from "../src/pages/owner-portal/customers/add/Add";
import OwnerDetailsPage from "../src/pages/owner-portal/customers/details/DetailsPage";
/** owner pages end */

function App() {
  const userToken = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

  return (
    <Routes>
      {/* public routes start */}
      <Route path="/" element={<SignIn />} />
      <Route path="/singin-org-id" element={<SignInOrgID />} />
      <Route path="/singin-org-email" element={<SignInOrgEmail />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify-otp" element={<Otp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/token-based-signin" element={<TokenBasedSignIn />} />
      <Route path="/add-phone-number" element={<SignInAddPhoneNumber />} />
      {/* public routes end */}

      {/* org routes start */}
      <Route
        path="/courses-listing"
        element={
          <OrgAuth>
            <Courses />
          </OrgAuth>
        }
      />

      <Route
        path="/add-courses/:courseId"
        element={
          <OrgAuth collapsed={true}>
            <AddCourse />
          </OrgAuth>
        }
      />
      <Route
        path="/media-library-table-view"
        element={
          <OrgAuth>
            <MediaLibraryTableView />
          </OrgAuth>
        }
      />
      <Route
        path="/course-details/:courseId"
        element={
          <OrgAuth collapsed={true}>
            <CourseDetails />
          </OrgAuth>
        }
      />
      <Route
        path="/users"
        element={
          <OrgAuth>
            <UserListing />
          </OrgAuth>
        }
      />
      <Route
        path="/add-student"
        element={
          <OrgAuth collapsed={true}>
            <AddUser />
          </OrgAuth>
        }
      />
      <Route
        path="/student-details/:userId"
        element={
          <OrgAuth collapsed={true}>
            <UsersDetails />
          </OrgAuth>
        }
      />

      <Route
        path="/tracks"
        element={
          <OrgAuth>
            <TracksListing />
          </OrgAuth>
        }
      />
      <Route
        path="/track-details/:trackId"
        element={
          <OrgAuth collapsed={true}>
            <TracksDetails />
          </OrgAuth>
        }
      />
      <Route
        path="/categories-listing"
        element={
          <OrgAuth>
            <CategoriesListing />
          </OrgAuth>
        }
      />
      <Route
        path="/featured-listing"
        element={
          <OrgAuth>
            <FeaturedListing />
          </OrgAuth>
        }
      />

      <Route
        path="/team"
        element={
          <OrgAuth>
            <TeamListing />
          </OrgAuth>
        }
      />
      <Route
        path="/team-details"
        element={
          <OrgAuth>
            <TeamMemberDetails />
          </OrgAuth>
        }
      />

      <Route
        path="/instructors-listing"
        element={
          <OrgAuth>
            <InstructorsListing />
          </OrgAuth>
        }
      />
      <Route
        path="/instructors-details"
        element={
          <OrgAuth collapsed={true}>
            <InstructorDetails />
          </OrgAuth>
        }
      />

      <Route
        path="/coupons"
        element={
          <OrgAuth>
            <Coupons />
          </OrgAuth>
        }
      />

      <Route path="/table" element={<TableData />} />

      {/* org routes end */}

      {/* student routes start */}
      <Route
        path="/my-courses"
        element={
          <StudentAuth>
            <MyLearning />
          </StudentAuth>
        }
      />
      <Route
        path="/my-course-details/:courseId"
        element={
          <StudentAuth collapsed={true}>
            <StudentCourseDetails />
          </StudentAuth>
        }
      />
      <Route
        path="/settings"
        element={
          <StudentAuth collapsed={true}>
            <StudentsSettings />
          </StudentAuth>
        }
      />
      <Route
        path="/calendar"
        element={
          <StudentAuth>
            <Calendar />
          </StudentAuth>
        }
      />
      <Route
        path="/certificates"
        element={
          <StudentAuth>
            <Certificates />
          </StudentAuth>
        }
      />
      <Route
        path="/media-library"
        element={
          <StudentAuth>
            <MediaLibrary />
          </StudentAuth>
        }
      />

      <Route
        path="/my-track-details/:trackId"
        element={
          <StudentAuth>
            <TrackDetails />
          </StudentAuth>
        }
      />

      {/* student routes end */}

      {/* owner routes start */}
      <Route
        path="/customer-listing"
        element={
          <OwnerAuth>
            <OwnerCustomerListing />
          </OwnerAuth>
        }
      />
      <Route
        path="/add-new-customer"
        element={
          <OwnerAuth>
            <OwnerCustomerAddNew />
          </OwnerAuth>
        }
      />
      {/* <Route path="/customer-details/:customerId" element={<OwnerAuth><OwnerDetailsPage /></OwnerAuth>} /> */}
      <Route
        path="/customer-details"
        element={
          <OwnerAuth>
            <OwnerDetailsPage />
          </OwnerAuth>
        }
      />
      {/* owner routes end */}
    </Routes>
  );
}

export default App;
