import React, {useState} from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import PortalSwitcher from '../../../components/PortalSwitcher/PortalSwitcher';
import { useLocation } from "react-router-dom";


import '../../../assets/css/SideBarMenu.css';


function SideBarMenu(props) {


    const pathname = useLocation().pathname;


    return (
        <div className="SideBarContainer">
            <div className="SideBarInnerContainer">

                <div className="SideBarMenu">
                    <Sidebar
                        collapsed={props.collapsed}
                    >
                        <PortalSwitcher />
                        <Menu >
                            <MenuItem className={`${pathname === '/courses-listing' ? 'LinkActive' : 'link'}`} href="/courses-listing"> <MenuBookRoundedIcon />  <span className="MenuPer">Courses</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/courses-listing">Courses</a></div></MenuItem>
                            <MenuItem className={`${pathname === '/users' ? 'LinkActive' : 'link'}`} href="/users"><PersonRoundedIcon /> <span className="MenuPer">Users</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/users">Users</a></div></MenuItem>
                            <MenuItem className={`${pathname === '/tracks' ? 'LinkActive' : 'link'}`} href="/tracks"><InsightsRoundedIcon /> <span className="MenuPer">Tracks</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/tracks">Tracks</a></div></MenuItem>
                            <MenuItem className={`${pathname === '/media-library-table-view' ? 'LinkActive' : 'link'}`} href="/media-library-table-view"><CollectionsRoundedIcon /> <span className="MenuPer">Media</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/media-library-table-view">Media</a></div></MenuItem>
                            
                            {/* <SubMenu className="MenuButtonPr" label={<div className="Submenue"><ForumRoundedIcon /> <span className="MenuPer">Message</span></div>}>
                                <div className="submenutitle">MESSAGES</div>
                                <MenuItem><FiberManualRecordRoundedIcon className="DotIcon" /> Sent</MenuItem>
                                <MenuItem><FiberManualRecordRoundedIcon className="DotIcon" /> Templates </MenuItem>
                            </SubMenu>  */}

                            <span className="divider"></span>
                            <MenuItem className={`${pathname === '/coupons' ? 'LinkActive' : 'link'}`} href="/coupons"><InsightsRoundedIcon /> <span className="MenuPer">Coupons</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/coupons">Coupons</a></div></MenuItem>
                        </Menu>
                    </Sidebar>
                </div>

            </div>
        </div>
    );
}


export default SideBarMenu;

