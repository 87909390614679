import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SwitchButtonIOS from '../../components/Form/SwitchButtonIOS/SwitchButtonIOS';
import './SwitchAccordion.css';


  // const [expanded, setExpanded] = React.useState(false);
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

{/* <SwitchAccordion
  title="Full price"
  expanded={expanded === 'panel'}
  onChange={handleChange('panel')}
  //handleChangeSwitch={handleChange}
  body={
    <></>
  }
/> */}



function SwitchAccordion(props) {

  const { expanded, onChange, title, body, handleChangeSwitch } = props;

  return (


    <div className="SwitchAccordion">
      <Accordion expanded={expanded} onChange={onChange}>
        <AccordionSummary>
          <div className="AccordionTitle">
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {title}
            </Typography>
            <SwitchButtonIOS
              handleChange={handleChangeSwitch}
              checked={expanded}
            />
          </div>
        </AccordionSummary>

        <AccordionDetails>
          {body}
        </AccordionDetails>
      </Accordion>
    </div>



  );
}


export default SwitchAccordion;

