import React from "react";
import ComingSoon from '../../../components/ComingSoon/ComingSoon';


function Certificates(props) {



  return (

    <>
      <ComingSoon title="Certificates" />

    </>

  );
}


export default Certificates;





































