import React from "react";
import { Grid } from '@material-ui/core/';
import Buttons from '../../components/Form/Button/Button';
import TextField from '../../components/Form/TextField/TextField';
import FieldPassword from '../../components/Form/FieldPassword/FieldPassword';
import Header from './header/Header';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import './Form.css';


function SignInOrgEmail(props) {

  const [values, setValues] = React.useState({
    name: "hello",
    number: "",
  });
  const handleChanges = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">

          <div className="SignForm">
            <h1>Sign in</h1>

            <Grid container spacing={3}>

              <Grid item xs={12} md={12}>
                <TextField
                  // value={values.name}
                  // onChange={handleChanges("name")}
                  placeholder="Email"
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FieldPassword

                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Continue"
                  buttonColor="#004FE0"
                  border="#ccc 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                // onClick={}
                />
              </Grid>

              <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <Buttons
                  label="Forgot Password?"
                  color="#004FE0"
                  width=""
                  height="48px"
                  fontSize="14px"
                  link=""
                />
              </Grid>

            </Grid>
          </div>
          <div className="SignFormFooter">
            <LockOutlinedIcon />
            <span>SECURE SIGN IN</span>
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>

      </div>
    </>

  );
}


export default SignInOrgEmail;

