import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import RedioBox from '../../../../../components/Form/RedioBox/RedioBox';

import Individual from './Individual';
import Organization from './Organization';


function BasicInfo(props) {

  const [value, setValue] = React.useState(0);
  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsData = [
    {
      title: "Individual",
      description: "Customer is an individual",
    },

    {
      title: "Organization",
      description: "Customer is an organization",
    },
  ]

  const panelDate = [
    {
      id: 0,
      panel: <Individual />,
    },

    {
      id: 1,
      panel: <Organization />,
    },

  ]


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection Section">
            <div className="MdTitle">Basic Info</div>
            <RedioBox
              tabsData={tabsData}
              panelDate={panelDate}
              value={value}
              onChange={onChange}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}


export default BasicInfo;

