import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import AlrtMessage from '../../../../../components/Form/AlrtMessage/AlertMessage';
import Table from '../../../../../components/Table/Table';
import SideDrawer from '../../../../../components/SideDrawer/SideDrawer';
import AddCourses from './AddCourses';
import CustomizedSnackbar from '../../../../../hooks/Snackbar';
import DraggableDialog from '../../../../../hooks/Dialog';
import tableImg from '../../../../../assets/imgs/tableImg.png';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import SimpleBackdrop from '../../../../../components/Form/Backdrop/SimpleBackdrop';

import Grid from '@material-ui/core/Grid';





function Courses(props) {

  const navigate = useNavigate();

  const { trackId, trackCourses, getCoursesTableData, getStudentList } = props;

  const [orgId, setOrgId] = useState(localStorage.getItem('orgId'));
  const [orgCourses, setOrgCourses] = useState([]);
  const [courseTableData, setCourseTableData] = useState([]);

  const [selectedCourse, setSelectedCourse] = React.useState('');

  const [selectedTrackCourse, setSelectedTrackCourse] = React.useState('');

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //base url for api taken from .env file 
  const baseUrl = process.env.REACT_APP_PROJECT_ENV == "dev" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

  //======= Drawer ===== //
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //======= Drawer ===== //


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = (trackCourseId) => {
    console.log(trackCourseId);
    setSelectedTrackCourse(trackCourseId);
    setOpenDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmCloseDialog = () => {
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/orgPortal/deleteTrackCourse?trackCourseId=${selectedTrackCourse}`, {})
      .then(res => {
        console.log("res", res.data);
        let data = res.data;
        setOpenDialog(false);

        if(data.error){
          showSnackbarMessage("error", data.message);
        } else{
          showSnackbarMessage("success", data.message);
        }        
        getCoursesTableData();
        getStudentList();
        setOpenBackdrop(false);

      }
      )
      .catch(err => {
        console.log("err", err)
      }
      )
  }
  //======= Delete dialog ===== //


  const handleOpenDialog = (id) => {
    console.log(id);
    // setActiveCourseId(id);
    // setDialogTitle("Delete this track?");
    // setDialogBody("Are you sure, you want to delete a course record and it's related data?");
    setOpenDialog(true);
  }

  const showSnackbarMessage = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  }

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  useEffect(() => {
    processCourseData(trackCourses);
    getCourseListForTrack();
  }, [trackCourses]);

  const gotoDetailsPage = (courseId) => {
    navigate(`/course-details/${courseId}`, { replace: true });
  }


  const getCourseListForTrack = () => {
    //call api to get media library data
    console.log("baseUrl", baseUrl);
    // setOpenBackdrop(true);
    axios.get(`${baseUrl}/orgPortal/getCourseListForTrack?orgId=${orgId}&trackId=${trackId}`, {})
      .then(res => {
        console.log("res", res.data);
        //traverse through the data and set the values in the table
        let data = res.data.body;
        // const data = JSON.parse(res.data);
        // setTotalItems(data);
        let temp = [];


        data.map((item, index) => {
          // console.log("item", item);
          temp.push({ value: `${item.courseId}`, label: `${item.courseTitle}` })
        })
        setOrgCourses(temp);
        // setOpenBackdrop(false);

      }
      )
      .catch(err => {
        console.log("err", err)
      }
      )
  }

  const processCourseData = (data) => {

    let temp = [];

        data.map((item, index) => {
          // console.log("item", item);
          temp.push({
            cell: item.cell,
            action: [
              { label: "View Details", onClick: () => gotoDetailsPage(item.row.courseId) },
              { label: "Remove", onClick: () => handleOpenDelete(item.row.trackCourseId) },
            ]
          });
        })

        setCourseTableData(temp);
  }

  const handleAddNewCourse = () => {
    console.log(selectedCourse); //courseId
    console.log(trackId);
    console.log(orgId);

    console.log("selected course data", selectedCourse);

    if (!selectedCourse) {
      showSnackbarMessage("error", "Please select a Course");
    } else {
      // show loader
      setOpenBackdrop(true);
      const postData = { orgId: orgId, trackId: trackId, courseId: selectedCourse };
      axios.post(`${baseUrl}/orgPortal/updateTrackCourse`, postData)
        .then(response => showDataPostMessage(response));
    }

  }

  const showDataPostMessage = (response) => {

    console.log(response.data);

    // hide loader
    setOpenBackdrop(false);

    const error = typeof response.data.error == 'undefined' ? true : response.data.error;

    if (error) {
      showSnackbarMessage("error", response.data.message);
    } else {
      showSnackbarMessage("success", "Course added successfully to track.");
      handleDrawerClose();
      getCoursesTableData();
      getStudentList();
    }

  }




  const onClick = () => {
    alert("onclick working fine!");
  }


  const headCells = [

    { id: 'Seq', numeric: true, disablePadding: false, label: 'Seq', width: "5%" },
    { id: 'Type', numeric: true, disablePadding: false, label: 'Course', width: "40%" },
    { id: 'Track', numeric: true, disablePadding: false, label: 'ID', width: "18%" },
    { id: 'price', numeric: true, disablePadding: false, label: 'Price', width: "18%" },
    {
      id: 'Status', numeric: true, disablePadding: false, label:"Status", Tooltip:true, TooltipText:"Only “Published” courses will be visible to the learners.", width: "30%"},
    { id: '', numeric: true, disablePadding: false, label: '', width: "18%" },
  ];

  return (

    <>

      <SimpleBackdrop open={openBackdrop} />

      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />

      <AlrtMessage
        message="Course added"
        background="#0B9444"
      />


      <Grid container spacing={3}>


        <Grid item xs={12} sm={12} md={12}>
          <div className="TableSection">
            <Table
              headCells={headCells}
              rows={courseTableData}
              footer={true}
              innerHeader={true}
              innerTitle="Courses"
              AddButtonInner={true}
              addLabelInner="Add Course"
              InnerButtonColor="#004FE0"
              InnerButtonTextColor="#fff"
              InnderButtonIcon={<AddRoundedIcon />}
              onClickInnerButton={handleDrawerOpen}
              handleOpenDialog={handleOpenDialog}
            />
          </div>
        </Grid>

      </Grid>


      <SideDrawer
        title="Add courses to track"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={open}
        FooterWithoutTab={true}
        clickSaveButton={handleAddNewCourse}
        handleDrawerClose={handleDrawerClose}
        body={
          <AddCourses orgCourses={orgCourses} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} />
        }
      />

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={handleConfirmCloseDialog}
        title="Remove course from track?"
        body={<div>Are you sure you want to remove this course?  <br></br>You can add the course again to this track.</div>}
        ModalFooter={true}
        actionButton="Delete"
      />


    </>
  );
}


export default Courses;

