import React, { useEffect, useState } from "react";

import '../../../../../../assets/css/summary.css';


const Summary = (props) => {

  const {paymentData} = props;

  return (
    <>
     <div className="SummarySection">
     
      <div className="SummaryCar">
        <h1>Summary</h1>
        <div className="SumamryTitle">Order Details</div>
        <div className="SummaryTable">
          <div className="SummaryTableRwo">
            <div className="SummaryTableCell LightText">Invoice #</div>
            <div className="SummaryTableCell BoldText">{paymentData?.invoiceNumber}</div>
          </div>

          <div className="SummaryTableRwo DarkRwo">
            <div className="SummaryTableCell LightText">Date</div>
            <div className="SummaryTableCell BoldText">{paymentData?.date}</div>
            {/* <div className="SummaryTableCell BoldText">{paymentData.date}</div> */}
          </div>

          <div className="SummaryTableRwo">
            <div className="SummaryTableCell LightText">Amount</div>
            <div className="SummaryTableCell BoldText">${paymentData.amount/100}</div>
            {/* <pre>{JSON.stringify(paymentData, null, 2)}</pre> */}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Summary;


