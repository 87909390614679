import React, { useEffect, useState } from "react";
import axios from 'axios';
import Table from '../../components/Table/Table';
import PasswordStrength from '../../components/Form/FieldPassword/PasswordStrength';


function createData(name, Type, Email, Status, CreatedDate, Action) {
  return { name, Type, Email, Status, CreatedDate, Action };
}


function OwnerCustomerListing(props) {

  const [customersList, setCustomersList] = useState([]);

  // fetching customers data

  const getCustomersListingData = () => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${baseUrl}/ownerPortal/getCustomers`).then((result) => {
      console.log(result.data.body);
      setCustomersList(result.data.body);
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    getCustomersListingData();
  }, []);


  const headCells = [
    { id: 'name', numeric: true, disablePadding: false, label: 'Name', width: "30%" },
    { id: 'type', numeric: true, disablePadding: false, label: 'Type', width: "20%" },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email', width: "22%" },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status', width: "12%" },
    { id: 'createdDate', numeric: true, disablePadding: false, label: 'Created Date', width: "30%" },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action', width: "15%" },
  ];



  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(true);

  return (
    <div className="TableSection">
      <Table
        title="Customers"
        AddButton={true}
        addLabel="New Customer"
        link="/add-new-customer"
        headCells={headCells}
        rows={customersList}
        tableHeader={true}
        Checkbox={false}
      />


      <PasswordStrength
        placeholder="New Password"
        handlePasswordText={setPassword}
        handlePasswordValidation={setPasswordValidation}
      />


    </div>
  );
}


export default OwnerCustomerListing;

