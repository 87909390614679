import React, { useState, useEffect } from "react";
import axios from 'axios';

import Grid from "@mui/material/Grid";
import TextField from "../../../../../components/Form/TextField/TextField";
import TextArea from "../../../../../components/Form/TextArea/TextArea";

import SelectField from "../../../../../components/Form/SelectField/SelectField";
import MultipleSeletChip from "../../../../../components/Form/MultipleSeletChip/MultipleSeletChip";
import Datefield from "../../../../../components/Form/Datefield/Datefield";


import { getDataMap } from "../../../../../hooks/HelperFunctions";


//import sidedrawer
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Buttons from "../../../../../components/Form/Button/Button";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import Avatar from "@mui/material/Avatar";
//import deletehoverrounded from mui component
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import SlateEditor from "../../../../../components/SlateEditor/SlateEditor";

function CourseOverview(props) {

  const { courseData, setCourseData } = props;

  const { courseLanguages, instructors, courseLevels, categories } =
    props.courseRelationalData;

  const [hasExistingCourseTitle, setHasExistingCourseTitle] = useState(false);

  const [additionalInstructors, setAdditionalInstructors] = useState([]);

  const [openSelectMedia, setOpenSelectMedia] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState(false);

  const [mediaItem, setMediaItem] = useState({});

  const [openNewMedia, setOpenNewMedia] = useState(false);

  //handleMediaUploadClose
  const handleMediaUploadClose = () => {
    setOpenNewMedia(false);
  };

  //deleteCourseImageSelection
  const deleteCourseImageSelection = () => {
    setCourseData({ ...courseData, ["courseImage"]: "" });
  };

  const setImageSelection = (fileUrl) => {
    // console.log(fileUrl);
    let temp = courseData;
    temp.courseImage = fileUrl;
    setCourseData(temp);
    // console.log(courseData);
  };

  useEffect(() => {    
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${baseUrl}/orgPortal/checkDuplicateCourseTitle?courseTitle=${courseData.courseTitle}&courseId=${courseData.courseId}`).then((result) => {
      console.log(result.data.duplicateCourseTitle);
      if(result.data.duplicateCourseTitle){
        setHasExistingCourseTitle(true);        
      } else{
        setHasExistingCourseTitle(false);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, [courseData.courseTitle]);

  useEffect(() => {
    const fieldName = "courseTitleError";
    if(hasExistingCourseTitle){
      setCourseData({ ...courseData, [fieldName]: true });
    } else{
      setCourseData({ ...courseData, [fieldName]: false });
    }
  }, [hasExistingCourseTitle]);

  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    const fieldData = event.target.value;
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };
  //handleDrawerOpenNewMedia
  const handleDrawerOpenNewMedia = () => {
    console.log("handleDrawerOpenNewMedia");
    setOpenNewMedia(true);
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  useEffect(() => {
    const skip = courseData.leadInstructor ? true : false;
    console.log(courseData.leadInstructor, skip);
    const additionalInst = getDataMap(instructors, "instId", "firstName", "lastName", " ", "instId", courseData.leadInstructor, skip);
    setAdditionalInstructors(additionalInst);
  }, [courseData.leadInstructor]);

  //handleDrawerOpenSelectMedia
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };

  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      // console.log(mediaItem);
      //set the media item
      setImageSelection(mediaItem.file_url);
      handleChangeWithData("courseImageName", mediaItem.name);

      // console.log( courseData)
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
  };

  //getMediaItem
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };

  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };

  return (
    <div className="formSection Section">
      <div className="MdTitle">Basic Info</div>
      <div className="FormBody">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Course Title"
              required={true}
              error={hasExistingCourseTitle}
              helperText={hasExistingCourseTitle? "Course title already exists" : ""}
              count={60}
              value={courseData.courseTitle}
              handleChange={handleChanges("courseTitle")}
            />
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12}>
            <Editor
              apiKey="kz96ollor2xbn81xk7qg37z2kb1ldxgcsep4xe9srh87dgaw"
              initialValue={courseData.description}
              init={{
                height: 300,
                menubar: false,
                branding: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code fontsize fontfamily textcolor wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | fontsizeselect | bold italic underline backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist  | removeformat ",
                forced_root_block: "",
                content_style:
                  "body { font-family:Poppins, sans-serif; font-size:14px }",
              }}
              onEditorChange={(newValue, editor) => {
                setContent(newValue);
                setCourseData({ ...courseData, ["description"]: newValue });
              }}
            />

            <SlateEditor
              label="Description"
              required={false}
              onChange={(fieldData) => handleChangeWithData("description", fieldData)}
              value={courseData.description}
              readOnly={false}
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={12}>
            <MultipleSeletChip
              label="Language"
              required={false}
              options={getDataMap(courseLanguages, "langId", "name")}
              selectedOption={courseData.courseLanguages}
              handleChange={(event, fieldData) =>
                handleChangeWithData("courseLanguages", fieldData)
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <SelectField
              label="Level"
              required={false}
              options={getDataMap(courseLevels, "levelId", "name")}
              selectedOption={courseData.level}
              handleChange={handleChanges("level")}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <SelectField
              label="Select Category"
              required={false}
              options={getDataMap(categories, "catId", "name")}
              selectedOption={courseData.category}
              handleChange={handleChanges("category")}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <SelectField
              label="Lead Instructor"
              required={true}
              options={getDataMap(instructors, "instId", "firstName", "lastName")}
              selectedOption={courseData.leadInstructor}
              handleChange={handleChanges("leadInstructor")}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <MultipleSeletChip
              label="Additional Instructor(s)"
              required={false}
              options={additionalInstructors}
              selectedOption={courseData.instructors}
              handleChange={(event, fieldData) =>
                handleChangeWithData("instructors", fieldData)
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="FieldLabel">Course Image<span>*</span></div>
            {(courseData?.courseImage ? courseData.courseImage.length : false) ? (
              <>
                <div className="TableSection">
                  <div
                    style={{
                      padding: '0.2rem',
                      // background: "#C3C3C3",
                      border: '1px solid #E4E8EC',
                      borderRadius: '10px',
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "70px",
                      padding: "0 16px",
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <span className="CellImg">
                        <Avatar variant="rounded" src={courseData.courseImage} />
                      </span>
                      <span>{courseData?.courseImageName}</span>
                    </div>
                    <span className="CellImg">
                      <DeleteForeverRoundedIcon
                        onClick={deleteCourseImageSelection}
                        sx={{ mb: -0.5, color: '#676C7B' }}F
                      />
                    </span>
                  </div>
                  <div className="HelperText">Minimum dimension of 750x422 pixels. Maximum size of 10.0MB. .jpg, .jpeg, or .png.</div>
                </div>
              </>
            ) : (
              <div>
                <SideDrawer
                  title="Media Library"
                  ButtonLabel="Add"
                  //clickSaveButton={clickSaveButton}
                  cancelButtonLabel="Cancel"
                  sideOpen="right"
                  open={openSelectMedia}
                  handleDrawerClose={handleDrawerCloseSelectMedia}
                  FooterWithoutTab={true}
                  LeftButton={true}
                  ButtonLeft="Upload New"
                  ButtonLeftIcon={<FileUploadOutlinedIcon />}
                  clickButtonLeft={handleDrawerOpenNewMedia}
                  clickSaveButton={clickSelectMediaItem}
                  body={
                    <MediaLibrarySelectMedia
                      onClickRadioIcon={setSelectedValue}
                      onItemSelected={getMediaItem}
                    />
                  }
                />

                <Externaluse
                  open={openNewMedia}
                  handleCloseButton={handleMediaUploadClose}
                  handleOpenNewMedia={handleOpenNewMedia}
                />

                <div className="TableSection">
                  <Buttons
                    label="Add From Media Library"
                    buttonColor="transparent"
                    border="#E4E8EC 1px solid"
                    icon={<AddRoundedIcon />}
                    color="#004FE0"
                    width="100%"
                    height="70px"
                    fontSize="14px"
                    link=""
                    onClick={() => handleDrawerOpenSelectMedia()}
                  />
                </div>
                <div className="HelperText">Minimum dimension of 750x422 pixels. Maximum size of 10.0MB. .jpg, .jpeg, or .png.</div>
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Promotional Video"
              placeholder="Enter YouTube video URL"
              TooltipText="Enter YouTube video URL"
              TooltipStatus={true}
              validation={false}
              // count={60}
              value={courseData.promotionalVideo}
              handleChange={handleChanges("promotionalVideo")}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CourseOverview;
