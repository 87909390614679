import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const GooglePlaceField = (props) => {

  return (
    <div>
        <div className="FieldLabel">{props.label} {props.validation && <span>*</span>}</div>
        <GooglePlacesAutocomplete
            apiKey='AIzaSyCpinHOnBAzeTGbCBuFSU_-HrC3WUgIUTc' 
            selectProps={{
            value: props.value,
            onChange: props.handleChange,
            }}
        />
    </div>
  );
}

export default GooglePlaceField;