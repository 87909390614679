import React, { useState, useEffect } from "react";
import axios from 'axios';

import {getUserData} from '../../../hooks/HelperFunctions';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import './RunningCourseAlertMessage.css';

export default function RunningCourseAlertMessage(props) {

  const [runningCourses, setRunningCourses] = useState([]);
  const [activeAlertCourse, setActiveAlertCourse] = useState([]);
  const [hideAlert, setHideAlert] = useState(false);
  const [courseDetailPath, setCourseDetailPath] = useState("");

  useEffect(() => {

    if (props.portal == "org") {
      const orgId = localStorage.getItem('orgId');
      getOrgRunningCourseList(orgId);
      // setCourseDetailPath(`/course-details`);
    } else if (props.portal == "student") {
      getStudentRunningCourseList();
      setCourseDetailPath(`/my-course-details`);
    }

  }, []);

  // update running course data per/1 minute
  useEffect(() => {

    const interval = setInterval(() => {

      if (props.portal == "org") {
        const orgId = localStorage.getItem('orgId');
        getOrgRunningCourseList(orgId);
      } else if (props.portal == "student") {
        getStudentRunningCourseList();
      }

    }, 60000);

    return () => clearInterval(interval);

  }, []);


  useEffect(() => {
    runningCourses.forEach((item, i) => setTimeout(
      () => setActiveAlertCourse(item),
      (i + 1) * 15000, // 5000, 10000, 15000
    ));
  }, [runningCourses]);


  const getOrgRunningCourseList = (orgId) => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${baseUrl}/orgPortal/getRunningCourses?orgId=${orgId}`).then((result) => {
      console.log(result.data.body);
      if (result.data.body?.length > 0) {
        setRunningCourses(result.data.body);
      } else {
        setHideAlert(true);
      }
    }).catch((err) => {
      console.log(err.response.data.message);
    });
  }

  const getStudentRunningCourseList = () => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    const userData = getUserData();
    const userId = userData.id;
    axios.get(`${baseUrl}/studentPortal/getRunningCourses?userId=${userId}`).then((result) => {
      console.log(result.data.body);
      if (result.data.body?.length > 0) {
        setRunningCourses(result.data.body);
      } else {
        setHideAlert(true);
      }
    }).catch((err) => {
      console.log(err.response.data.message);
    });
  }

  return (
    <>
      {!hideAlert && activeAlertCourse && activeAlertCourse.courseTitle &&
        <div className='BlueAlertMessage'>
          <div className='BlMessageContainer'>
            <div className='BlMessageLeftContainer'>
              <SensorsRoundedIcon />
              <div>
                <h2><span>Live</span>: {activeAlertCourse.sessionTitle}</h2>
                <p>{activeAlertCourse.courseTitle}</p>
              </div>
            </div>
            <div className="AlrtMessageContainer">
              {props.portal == "student" &&
                <Button href={`${courseDetailPath}/${activeAlertCourse.courseId}`} className="AlrtButton" >
                  Go To Course
                </Button>}
            </div>
          </div>
          <IconButton
            className='BlueAlertMessageIconButton'
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setHideAlert(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>}
    </>
  );
}