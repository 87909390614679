import React, { useEffect, useState } from "react";
import Table from '../../../../../components/Table/Table';

function Courses(props) {

  const { courseList } = props;


  const headCells = [
    { id: 'Course', numeric: false, disablePadding: true, label: 'Course', width: "40%"},
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status', width: "10%"},
    { id: 'Enrolled', numeric: false, disablePadding: true, label: 'Start date', width: "20%"},
    { id: 'Completed', numeric: false, disablePadding: true, label: 'Completed', width: "20%"},
    { id: 'Actions', numeric: true, disablePadding: false, label: '', width: "10%" },
  ];


  const rows = [
    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
        { date: "Jan 14, 2022", },
        { date: "Jan 12, 2022", },
        {
          Button: true,
          buttonLabel: "Details",
          buttonColor: "#FFFFFF",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonLink: "",
        },
      ]
    },
    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#EEECFF", color: "#7266FB", label: "In Progress", },
        { date: "Jan 14, 2022", },
        { date: "Jan 12, 2022", },
        {
          Button: true,
          buttonLabel: "Details",
          buttonColor: "#FFFFFF",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonLink: "",
        },
      ]
    },
    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#FFF5E6", color: "#FFAE35", label: "Upcoming", },
        { date: "Jan 14, 2022", },
        { date: "Jan 12, 2022", },
        {
          Button: true,
          buttonLabel: "Details",
          buttonColor: "#FFFFFF",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonLink: "",
        },
      ]
    },
    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
        { date: "Jan 14, 2022", },
        { date: "Jan 12, 2022", },
        {
          Button: true,
          buttonLabel: "Details",
          buttonColor: "#FFFFFF",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonLink: "",
        },
      ]
    },
    {
      cell: [
        { textBold: "Introduction to Islamic Economics and Finance", },
        { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
        { date: "Jan 14, 2022", },
        { date: "Jan 12, 2022", },
        {
          Button: true,
          buttonLabel: "Details",
          buttonColor: "#FFFFFF",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonLink: "",
        },
      ]
    },
  ]



  return (
    <>
      
      <div className="TableSection">
      <Table
        tableHeader={true}
        innerTitle="Courses"
        innerHeader={true}
        headCells={headCells}
        rows={rows}
        footer={true}
      />
    </div>
    </>
  );
}


export default Courses;

