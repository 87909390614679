import React, { useEffect, useState } from "react";
import axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Modals from '../../../../components/Form/Modals/Modals';
import ModalsResponsiveFullScreen from '../../../../components/Form/Modals/ModalsResponsiveFullScreen';
import SimpleBackdrop from '../../../../components/Form/Backdrop/SimpleBackdrop';
import UpdatePersonalInfo from './forms/UpdatePersonalInfo';
import { withStyles } from '@material-ui/core/styles';

import SideDrawer from '../../../../components/SideDrawer/SideDrawer';

// import AWS from "aws-sdk";

// const CONF_S3_DIRECTORY =
//   process.env.REACT_APP_PROJECT_ENV === "prod"
//     ? process.env.REACT_APP_S3_DIRECTORY_PROD
//     : process.env.REACT_APP_S3_DIRECTORY_DEV;

// const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
// const S3_DIRECTORY = CONF_S3_DIRECTORY;
// const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;
// const REGION = process.env.REACT_APP_S3_REGION;
// const S3_ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
// const S3_SECRET_ACCESS_KEY = process.env.REACT_APP_S3_SECRET_ACCESS_KEY;

// const REACT_APP_CDN_URL = process.env.REACT_APP_CDN_URL;

// AWS.config.update({
//   accessKeyId: S3_ACCESS_KEY,
//   secretAccessKey: S3_SECRET_ACCESS_KEY,
// });

// const s3Bucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });



function PersonalInfo(props) {

  const { studentData, personalInfo, setPersonalInfo, gradeLevels, ageGroups, timeZones, editProfile, setEditProfile, triggerSnackbar, setFetchLatestData } = props;
  const { countries, highestEducation } = props;

  // const [selectedFile, setSelectedFile] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  function createData(label, data) {
    return { label, data };
  }

  const birthdayArray = studentData.birthdate ? studentData.birthdate.split("-") : [];
  const birthMonth = birthdayArray[0] ? birthdayArray[0] : "";
  const birthYear = birthdayArray[1] ? birthdayArray[1] : "";

  let birthdateArr = [];
  if (birthMonth.length > 0) {
    birthdateArr.push(birthMonth);
  }
  if (birthYear.length > 0) {
    birthdateArr.push(birthYear);
  }


  const rows = [
    createData('Full Name', studentData.firstName ? <span>{studentData.firstName} {studentData.middleName} {studentData.lastName}</span> : <a className="addText" onClick={() => OpenModal()}>Add name</a>),
    createData('Gender', studentData.gender ? <span>{studentData.gender}</span> : <a className="addText" onClick={() => OpenModal()}>Add Gender</a>),
    createData('Country', studentData.countryName ? <span>{studentData.countryName}</span> : <a className="addText" onClick={() => OpenModal()}>Add Country</a>),
    createData('Address', studentData.address ? <span>{studentData.address}</span> : <a className="addText" onClick={() => OpenModal()}>Add Address</a>),
    createData('WhatsApp Number', studentData.whatsAppNumber ? <span>{studentData.whatsAppNumber}</span> : <a className="addText" onClick={() => OpenModal()}>Add WhatsApp Number</a>),
    createData('Birthday', studentData.birthdate ? <span>{birthdateArr.join(' ')}</span> : <a className="addText" onClick={() => OpenModal()}>Add Birthday</a>),
    createData('Highest Education', studentData.heduTitle ? <span>{studentData.heduTitle}</span> : <a className="addText" onClick={() => OpenModal()}>Add Highest Education</a>),
    createData('Current Profession', studentData.currentProfession ? <span>{studentData.currentProfession}</span> : <a className="addText" onClick={() => OpenModal()}>Add Current Profession</a>),
    createData('Timezone', studentData.timezone ? <span>{`${studentData.timezone}`}</span> : <a className="addText" onClick={() => OpenModal()}>Add Timezone</a>),
  ];

  // ==== Name Modals ===== //
  const [open, setOpen] = useState(false);
  const OpenModal = () => { setOpen(true); };
  const handleClose = () => {
    console.log('close')
    setOpen(false);
    setEditProfile(false);
  };
  // ==== Name Modals ===== //


  const showDataPostMessage = (response, dataArray) => {
    triggerSnackbar(true, "success", "Your profile has updated.");
    setOpen(false);
    setEditProfile(false);
    setFetchLatestData(true);
    // set profile pic
    console.log(dataArray.image);
    let userData = JSON.parse(localStorage.getItem('userData'));
    userData.image = dataArray.image;
    userData.firstName = dataArray.firstName;
    userData.lastName = dataArray.lastName;
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('timezone', dataArray?.timezone);
  }

  // const uploadFile = async (file) => {      
    
  //   setOpenBackdrop(true);

  //   let objFilePath = S3_DIRECTORY;

  //   const filePath = "student/" + studentData.id + "/profile/" ; 
  //   // objFilePath += filePath + randString(5) + "-" + file.name;
  //   objFilePath += filePath + file.name;

  //   console.log(objFilePath);

  //   // return '';

  //   const params = {
  //     ACL: "public-read",
  //     Body: file,
  //     Bucket: S3_BUCKET,
  //     Key: objFilePath,
  //   };

  //   await s3Bucket.upload(params).promise();      
  //   setOpenBackdrop(false);
  //   return {
  //       url: REACT_APP_CDN_URL + objFilePath,
  //       fileName: file.name,
  //       size: Number(file.size) / 1024,
  //       type: file.type
  //   }      
  // };


  const handleSaveStudentData = async (dataArray) => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    console.log(dataArray);

    // dataArray.address = typeof dataArray.address == null? "": dataArray.address;

    if (!dataArray.firstName || !dataArray.lastName || dataArray.countryId == 0 || !dataArray.addressObj) {
      triggerSnackbar(true, "error", "Please fill up all required fields.");
    } else {
      // console.log(selectedFile);
      // if (selectedFile) {
      //   const fileData = await uploadFile(selectedFile);
      //   dataArray.image = fileData.url;
      // }

      axios.post(`${baseUrl}/studentPortal/editProfile`, dataArray)
        .then(response => showDataPostMessage(response, dataArray));
    }

  }

  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "#FCFCFC",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  return (
    <div className="TableContainer oddEvenTable">
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="TableInnerHeader">
        <h3>Profile</h3>
      </div>
      <Divider />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="personal info">
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
              >
                <StyledTableCell component="th" scope="row" className="TableLabel"> {row.label}</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">{row.data}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>      


      <SideDrawer
        title="Edit Profile"
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        sideOpen="right"
        open={open || editProfile}
        handleDrawerClose={handleClose}
        ButtonLeft="Upload New"
        FooterWithoutTab={true}
        ButtonLabel="Save"
        clickSaveButton={() => handleSaveStudentData(personalInfo)}
        body={
          <div>
            <UpdatePersonalInfo
              personalInfo={personalInfo}
              setPersonalInfo={setPersonalInfo}
              gradeLevels={gradeLevels}
              ageGroups={ageGroups}
              timeZones={timeZones}
              countries={countries}
              highestEducation={highestEducation}
              // selectedFile={setSelectedFile}
            />
          </div>
        }
      />
    </div>
  );
}
export default PersonalInfo;
