import React, { useState } from "react";
import AlrtMessage from '../../../../../components/Form/AlrtMessage/AlertMessage';
import Table from '../../../../../components/Table/Table';
import SideDrawer from '../../../../../components/SideDrawer/SideDrawer';
import CustomizedSnackbar from '../../../../../hooks/Snackbar';
import DraggableDialog from '../../../../../hooks/Dialog';
import tableImg from '../../../../../assets/imgs/tableImg.png';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

import EmptyCard from '../../../../../components/EmptyCard/EmptyCard';

function Certificates(props) {

  // //======= Drawer ===== //
  // const [open, setOpen] = useState(false);
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };
  // //======= Drawer ===== //


  // //======= Delete dialog ===== //
  // const [openDialog, setOpenDialog] = useState(false);
  // const handleOpenDelete = () => {
  //   setOpenDialog(true);
  // };
  // const handleCloseDeleteDialog = () => {
  //   setOpenDialog(false)
  // };
  // //======= Delete dialog ===== //


  // const handleOpenDialog = (id) => {
  //   console.log(id);
  //   // setActiveCourseId(id);
  //   // setDialogTitle("Delete this track?");
  //   // setDialogBody("Are you sure, you want to delete a course record and it's related data?");
  //   setOpenDialog(true);
  // }




  // const onClick = () => {
  //   alert("onclick working fine!");
  // }


  // const headCells = [

  //   { id: 'Seq', numeric: true, disablePadding: false, label: 'Name', width: "50%" },
  //   { id: 'Type', numeric: true, disablePadding: false, label: 'Status', width: "18%" },
  //   { id: 'Track', numeric: true, disablePadding: false, label: 'Progress', width: "18%" },
  //   { id: '', numeric: true, disablePadding: false, label: 'Action', width: "18%" },
  // ];

  // const rows = [

  //   {
  //     cell: [
  //       { textBold: "Emma Smith", },
  //       { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Active", },
  //       { linkText: "1/5 >", linkTextStatus: true, onClickLinkText: handleDrawerOpen, },
  //       {
  //         Button: true, buttonLabel: "Details",
  //         buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget: "_blank",
  //       },
  //     ],

  //   },

  //   {
  //     cell: [
  //       { textBold: "Emma Smith", },
  //       { Status: true, background: "#FCE7E9", color: "#E63946", label: "Blocked", },
  //       { linkText: "1/5 >", linkTextStatus: true, onClickLinkText: handleDrawerOpen, },
  //       {
  //         Button: true, buttonLabel: "Details",
  //         buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget: "_blank",
  //       },
  //     ],

  //   },

  //   {
  //     cell: [
  //       { textBold: "Emma Smith", },
  //       { Status: true, background: "#FFF5E6", color: "#FFAE35", label: "Dropped", },
  //       { linkText: "1/5 >", linkTextStatus: true, onClickLinkText: handleDrawerOpen, },
  //       {
  //         Button: true, buttonLabel: "Details",
  //         buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget: "_blank",
  //       },
  //     ],

  //   },

  // ];



  return (

    <>
      <EmptyCard
        title="No certificate to upload yet"
        description="Certificates will be visible here"
      />

      {/* <CustomizedSnackbar
        message="Track removed"
        severity="success"
      />


      <AlrtMessage
        message="Course added"
        background="#0B9444"
      />

      <div className="StatusMessage">
        <ErrorRoundedIcon />
        <p>Learners can't see this track yet. Please fill all the required fields and change the status to "Publish" from track <a href="">settings.</a></p>
      </div>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          AddButtonInner={true}
          addLabelInner="Export"
          InnerButtonColor="#E0EAFB"
          InnerButtonTextColor="#004FE0"
          InnderButtonIcon={<FileUploadOutlinedIcon />}
          //onClickInnerButton={handleDrawerOpen}
          handleOpenDialog={handleOpenDialog}
          showSearchBox={true}
        />
      </div>

      <SideDrawer
        title="Progress"
        ButtonLabel="View More Details"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={open}
        FooterWithoutTab={true}
        handleDrawerClose={handleDrawerClose}
        body={
          <Progress />
        }
      />

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        title="Remove course from track?"
        body={<div>Are you sure you want to remove this course?  <br></br>You can add the course again to this track.</div>}
        ModalFooter={true}
        actionButton="Delete"
      /> */}


    </>
  );
}


export default Certificates;

