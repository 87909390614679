import React, { useState, useEffect } from "react";
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import './SideDrawer.css';





function SideDrawer(props) {

  const { open, handleDrawerClose, clickSaveButton, sideOpen, title, ButtonLabel, body,
    panelTab, tabData, clickButtonLeft, SubDrawer, SubDrawerButtonSatus, SubDrawerWithOutTabBody, SubDrawerTabSatus,
    SubDrawerWithOutTab, TitleUnderHeader } = props;

  const [defaultChecked, setDefaultChecked] = useState(props.defaultChecked);



  const [value, setValue] = React.useState(0); 

  const onChange = (event, newValue) => {
    setValue(newValue);
    if( typeof props.handleTabChange === 'function'){
      props.handleTabChange(newValue);
    }
    // props.handleTabChange(newValue);
  };

  useEffect(() => {
    console.log(props.selectedCheckBoxTab);
    setValue(props.selectedCheckBoxTab);

  }, [props.selectedCheckBoxTab]);




  // const [open, setOpen] = useState(false);
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  // onClick:handleDrawerOpen

  // <SideDrawer
  //   title="Edit Media"
  //   ButtonLabel="Save"
  //   cancelButtonLabel="Cancel"
  //   deleteText="Delete Permanently"
  //   // onClickDelete={onClickDelete}
  //   DeleteButton={true}
  //   sideOpen="right"
  //   open={openEditMedia}
  //   handleDrawerClose={handleDrawerCloseEditMedia}
  //   CheckboxLabel="Add another after saving"
  //   Checkbox={true}
  // LeftButton={true}
  //       ButtonLeft="Upload New"
  //       ButtonLeftIcon={<FileUploadOutlinedIcon/>}
  //       clickButtonLeft={handleDrawerOpenNewMedia}
  // FooterWithoutTab={false}
  // panelTab={panelTab}
  //       tabData={tabData}
  //  arrowIcon={true}
  //   body={
  //     <EditMedia />
  //   }

  // TabsStatus={true}


  // CheckBoxTabsStatus={true}
  // TabsPannelStatus={true}

  // SubDrawer="SubDrawer"
  // SubDrawerButtonSatus={true}
  // SubDrawerTabSatus={true}
  // SubDrawerWithOutTab={false}
  // SubDrawerWithOutTabBody={<p>Sub Drawer WithOut Tab Body</p>}

  // TitleUnderHeaderStatus={true}
  // TitleUnderHeader="Edit Payment"
  // SubTitle="Payment Method*"


  // />


  // const tabData = [
  //   {
  //     lebel: "Upload from device",
  //     icon: <InsertDriveFileIcon />,
  //   },

  //   {
  //     lebel: "YouTube video",
  //     icon: <YouTubeIcon />,
  //   },
  // ]

  // const panelTab = [
  //   {
  //     id: 0,
  //     panleBody: <UploadNewMedia
  //       handleTabChange={handleTabChange}
  //       handleSnackbarOpen={handleSnackbarOpen}
  //       setVideoName={setVideoName}
  //       setVideoUrl={setVideoUrl}
  //       setImageName={setImageName}
  //       videoName={videoName}
  //       videoUrl={videoUrl}
  //       imageName={imageName}
  //     />,

  //     SaveButtonLabel: "Upload File",
  //     //clickSaveButton:clickSaveButton,
  //     CancelButtonLabel: "Cancel",

  //     Checkbox: true,
  //     CheckboxLabel: "Add another after saving",
  //     //onClickCheckbox:onClickCheckbox,

  //     DeleteButton: true,
  //     DeleteLebel: "Delete Permanently",
  //     //onClickDelete:onClickDelete,

  //SubDrawerBodyWithTab:"Sub Drawer Body With Tab",

  //   },

  //   {
  //     id: 1,
  //     panleBody: <p>scsdsfvjsdvlds</p>,
  //     SaveButtonLabel: "Import From YouTube",
  //     //clickSaveButton:clickSaveButton,
  //     cancelButtonLabel: "Cancel",
  //   },

  // ]




  // const tabData = [
  //   {
  //     title: <img src={zelle} alt="zelleIcon" width={30} height={30} />,
  //     description: "Zelle",
  //   },

  //   {
  //     title: <img src={check} alt="zelleIcon" width={30} height={30} />,
  //     description: "Check",
  //   },
  //   {
  //     title: <img src={cash} alt="zelleIcon" width={30} height={30} />,
  //     description: "Cash",
  //   },
  // ]

  // const panelTab = [
  //   {
  //     id: 0,
  //     panleBody: <ZellePayment />,
  //     SubDrawerBodyWithTab:<p>Sub Drawer Body With Tab </p>,
  //   },

  //   {
  //     id: 1,
  //     panleBody: <CheckPayment />,
  //     SubDrawerBodyWithTab:"Sub Drawer Body With Tab",
  //   },

  //   {
  //     id: 2,
  //     panleBody: <CashPayment />,
  //     SubDrawerBodyWithTab:"Sub Drawer Body With Tab",
  //   },

  // ]


  const [isActive, setActive] = useState("true");
  const handleToggle = () => {
    setActive(!isActive);
  };


  return (
    <div className="MainDrawer">

    

      <Drawer
        anchor={sideOpen}
        open={open}
        handleDrawerClose={handleDrawerClose}
        className="MainDrawerWrapper"
      >
        
        <div className="MuiModal-backdrop-Custom" onClick={handleDrawerClose}></div>

        <div className={isActive ? SubDrawer : "SubDrawerActive"}>
          <div className="DrawerContainer">
            <div className="DrawerHeader">
              <div className="DrawerHeaderTitle">{title}</div>
              <div>
                {SubDrawerButtonSatus &&
                  <>
                    <Button className="DrawerSummaryButton CloseSummary" onClick={handleToggle}>Close Summary</Button>
                    <Button className="DrawerSummaryButton ShowSummary" onClick={handleToggle}>Show Summary</Button>
                  </>
                }
                <Button onClick={handleDrawerClose}><CloseRoundedIcon /></Button>
                
              </div>
            </div>
            {props.TitleUnderHeaderStatus &&
              <div className="DrawerHeaderTitleUnderHeader">{TitleUnderHeader}</div>
            }

            {props.TabsStatus &&
              <div className="DrawerTabsContainer">
                <Tabs value={value} onChange={onChange}>
                  {tabData && tabData.map((item, i) => (
                    <Tab label={<div className='tabsContent'>{item.icon}{item.lebel}</div>} />
                  ))}
                </Tabs>
              </div>
            }

            {props.CheckBoxTabsStatus &&
              <div className="CheckBoxTabs">
                <div class="FieldLabel">{props.SubTitle}</div>
                <Tabs value={value} onChange={onChange}>
                  {tabData && tabData.map((item, i) => (
                    <Tab label={
                      <div className="RedioContents">
                        <div className="RedioIcon">
                          <FiberManualRecordOutlinedIcon className="UnSelectedRedio" />
                          <CheckCircleIcon className="SelectedRedio" />
                          {/* <FiberManualRecordIcon className="SelectedRedio" /> */}
                        </div>
                        <div className="RedioLebel"><h3>{item.title}</h3> <p>{item.description}</p></div>
                      </div>
                    } />
                  ))}
                </Tabs>
              </div>
            }

            {props.TabsPannelStatus &&
              <>
                {panelTab && panelTab.map((item, i) => (
                  <div className="TabPanel">
                    <TabPanel value={value} index={item.id}>

                      <div className="MDC">
                        <div className="DrawerBody">
                          {item.panleBody}
                        </div>

                        {SubDrawerTabSatus &&
                          <div className="SubDrawerBody">
                            <div className="SubDrawerContainer">
                              {item.SubDrawerBodyWithTab}
                            </div>
                          </div>
                        }
                      </div>

                      <div className="DrawerFooter TabFooter">
                        <div className="DrawerFooterInner">
                          {item.Checkbox &&
                            <FormControlLabel control={<Checkbox defaultChecked={defaultChecked} onClick={item.onClickCheckbox} />} label={item.CheckboxLabel} />
                          }
                          {item.DeleteButton &&
                            <Button onClick={() => item.onClickDelete()} variant="text" style={{ color: "#BA1A1A", textTransform: "initial", }}>{item.DeleteLebel}</Button>
                          }
                          <span></span>
                          <div className="DrawerFooterButton">
                            <Button className="DrawerCloseButton" onClick={handleDrawerClose}>{item.CancelButtonLabel}</Button>
                            <Button className="DrawerActionButton" onClick={item.clickSaveButton}>{item.SaveButtonLabel} {item.arrowIcon && <ArrowForwardRoundedIcon />}</Button>
                            <Button className="DrawerCloseButtonMobile" onClick={handleDrawerClose}>{item.CancelButtonLabel}</Button>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                ))}
              </>
            }

            <div className="MDC">
              <div className="DrawerBody">
                {body}
              </div>

              {SubDrawerWithOutTab &&
                <div className="SubDrawerBody">
                  <div className="SubDrawerContainer">
                    {SubDrawerWithOutTabBody}
                  </div>
                </div>
              }
            </div>



            {props.FooterWithoutTab &&
              <div className="DrawerFooter">

                <div className="DrawerFooterInner">
                  {props.Checkbox &&
                    <FormControlLabel control={<Checkbox defaultChecked={defaultChecked} onClick={props.onCheckboxClicked} />} label={props.CheckboxLabel} />
                  }

                  {props.DeleteButton &&
                    <Button onClick={() => props.onClickDelete()} variant="text" style={{ color: "#BA1A1A", textTransform: "initial", }}>{props.deleteText}</Button>
                  }
                  {props.LeftButton &&
                    <span onClick={handleDrawerClose}>
                      <Button className="DrawerActionButton" onClick={clickButtonLeft}><span className="DrawerActionButtonLeftIcon">{props.ButtonLeftIcon}</span>{props.ButtonLeft}</Button>
                    </span>
                  }
                  <span></span>

                  <div className="DrawerFooterButton">
                    <Button className="DrawerCloseButton" onClick={handleDrawerClose}>{props.cancelButtonLabel}</Button>
                    {props.ButtonLabel && 
                    <Button className="DrawerActionButton" onClick={clickSaveButton}>{props.ButtonLabel} {props.arrowIcon && <ArrowForwardRoundedIcon />}</Button>}
                    <Button className="DrawerCloseButtonMobile" onClick={handleDrawerClose}>{props.cancelButtonLabel}</Button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </Drawer>
    </div>



  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default SideDrawer;

