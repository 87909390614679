import React, {useState, useEffect} from "react";
import axios from 'axios';
import { getUserData } from '../../../hooks/HelperFunctions';

import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import EventIcon from '@mui/icons-material/Event';
import SettingsIcon from '@mui/icons-material/Settings';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import PortalSwitcher from '../../../components/PortalSwitcher/PortalSwitcher';
import { useLocation } from "react-router-dom";


import '../../../assets/css/SideBarMenu.css';
import { Button } from "@mui/material";


function SideBarMenu(props) {

    const [studentBecomeTeacherLink, setStudentBecomeTeacherLink] = useState("");
    const [teachingAccounts, setTeachingAccounts] = useState([]);

    let userData = getUserData();
    const userId = userData.id;


    const pathname = useLocation().pathname;


    useEffect(() => {
        const studentBecomeTeacherLinkLoc = localStorage.getItem('studentBecomeTeacherLink');
        setStudentBecomeTeacherLink(studentBecomeTeacherLinkLoc);
        getTeachingAccountsList();
    }, []);


    // fetching teaching accounts data
    const getTeachingAccountsList = () => {
        const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
        axios.get(`${baseUrl}/orgPortal/getUserOrgsData?userId=${userId}`).then((result) => {
        console.log(result.data.body);
        setTeachingAccounts(result.data.body);
        }).catch((err) => {
        console.log(err);
        });
    }

    const positionedButtonStyle = {
        width: '200px',
        color: '#004FE0', 
        borderColor: '#004FE0',
        textTransform: 'initial',
        position: 'fixed', 
        top: '93%',
        left: '130px',
        transform: 'translate(-50%, -50%)',
      };

//ps-collapsed
    return (
        <div className="SideBarContainer">
            <div className="SideBarInnerContainer">

                <div className="SideBarMenu">
                    <Sidebar
                        collapsed={props.collapsed}
                    >
                        <PortalSwitcher />
                        <Menu >
                            <MenuItem className={`${pathname === '/my-courses' ? 'LinkActive' : 'link'}`} href="/my-courses"> <MenuBookRoundedIcon />  <span className="MenuPer">My learning</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/my-courses">My learning</a></div></MenuItem>
                            <MenuItem className={`${pathname === '/calendar' ? 'LinkActive' : 'link'}`} href="/calendar"><EventIcon /> <span className="MenuPer">Calendar</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/calendar">Calendar</a></div></MenuItem>
                            <MenuItem className={`${pathname === '/certificates' ? 'LinkActive' : 'link'}`} href="/certificates"><EmojiEventsIcon /> <span className="MenuPer">Certificates</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/certificates">Certificates</a></div></MenuItem>
                            <MenuItem className={`${pathname === '/settings' ? 'LinkActive' : 'link'}`} href="/settings"><SettingsIcon /> <span className="MenuPer">Settings</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/settings">Settings</a></div></MenuItem>
                        </Menu>
                        {!props.collapsed && teachingAccounts.length == 0 && (  // Only render the button if the sidebar is not collapsed
                            <Button variant="outlined" startIcon={<LaunchRoundedIcon />} href={studentBecomeTeacherLink} target="_blank" style={positionedButtonStyle}>
                                Become a teacher
                            </Button>
                        )}
                    </Sidebar>
                </div>

            </div>

        </div>
    );
}


export default SideBarMenu;

