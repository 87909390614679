import React, { useEffect, useState } from "react";
import Buttons from '../../../../../components/Form/Button/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ArrowForwardIos, Forward, Forward30, Person, Receipt, Security } from "@mui/icons-material";

function Overview(props) {


  return (
    <>
      <div className="Table">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            {/* <TableHead>
              <h1 className="TableTitle">Instructor Information</h1>
              
            </TableHead> */}


            <TableBody>

            <TableRow>
                <TableCell align="left"> <h1>Instructor Information</h1></TableCell>
                <TableCell align="left"> </TableCell>
                <TableCell></TableCell>
               

              </TableRow>


              <TableRow>
                <TableCell align="left">Full name </TableCell>
                <TableCell align="left"> Dr. Omar Suleiman</TableCell>
                <TableCell> <span>
                  <ArrowForwardIos />
                </span></TableCell>
               

              </TableRow>

              <TableRow>
                <TableCell align="left">Organization </TableCell>
                <TableCell align="left">Southern Methodist University</TableCell>
                <TableCell> <span>
                  <ArrowForwardIos />
                </span></TableCell>

              </TableRow>

              <TableRow>
                <TableCell align="left">Designation </TableCell>
                <TableCell align="left">Adjunct Professor of Islamic Studies</TableCell>
                <TableCell> <span>
                  <ArrowForwardIos />
                </span></TableCell>

              </TableRow>

              <TableRow>
                <TableCell align="left">Phone </TableCell>
                <TableCell align="left">+1 (123) 123-1234</TableCell>
                <TableCell> <span>
                  <ArrowForwardIos />
                </span></TableCell>

              </TableRow>

              <TableRow>
                <TableCell align="left">Short bio </TableCell>
                <TableCell align="left">Imam Omar Suleiman is the Founder and President of the Yaqeen Institute for Islamic Research, and an Adjunct Professor... of Islamic Studies in the Graduate Liberal Studies Program at SMU (Southern Methodist University). He is also the Resident Scholar at Valley Ranch Islamic Center and Co-Chair Emeritus of Faith Forward Dallas at Thanks-Giving Square.</TableCell>
                <TableCell> <span>
                  <ArrowForwardIos />
                </span></TableCell>

              </TableRow>




            </TableBody>
          </Table>
        </TableContainer >
      </div >

    </>
  );
}






export default Overview;

