import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import SelectField from '../../../../../components/Form/SelectField/SelectField'
import Datefield from '../../../../../components/Form/Datefield/Datefield'
import TextArea from '../../../../../components/Form/TextArea/TextArea'
import TextField from '../../../../../components/Form/TextField/TextField'
import Modals from '../../../../../components/Form/Modals/Modals';
import Button from '@mui/material/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RedioBox from '../../../../../components/Form/RedioBox/RedioBox';





function Courses(props) {


  //==== radio button//
  const [radiovalue, radiosetValue] = React.useState('female');

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };
  //==== radio button End//

  // ==== Date Picker === //
  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChangeDate = (newValue) => {
    setValue(newValue);
  };

  // ==== Date Picker end === //

  // ==== Level Value === //
  const [age, setAge] = React.useState('');
  const handleChangelevel = (event) => {
    setAge(event.target.value);
  };

  const selectValue = [
    {
      label: "Beginner level",
      value: "Beginner level",
    },
    {
      label: "Intermediate level",
      value: "Intermediate level",
    },
    {
      label: "Expert level",
      value: "Expert level",
    },

    {
      label: "All levels",
      value: "All levels",
    },

  ];
  // ==== Level Value end === //


  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangePannel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [expanded_2, setExpanded_2] = React.useState('panel1');

  const handleChangePannel_2 = (panel) => (event, isExpanded_2) => {
    setExpanded_2(isExpanded_2 ? panel : false);
  };


  // Modal Module //

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    console.log('close')
    setOpen(false)
  };

  const handleConfirm = () => {
    console.log('close')
    setOpen(false)
  };

  // Modal Module  End//


  // Modal Session //

  const [openSession, setOpenSession] = useState(false);

  const handleOpenSession = () => {
    setOpenSession(true);
  };

  const handleCloseModalSession = () => {
    console.log('close')
    setOpenSession(false)
  };

  const handleConfirmSession = () => {
    console.log('close')
    setOpenSession(false)
  };

  // Modal Session  End//


  // Modal Resource //

  const [openResource, setOpenResource] = useState(false);

  const handleOpenResource = () => {
    setOpenResource(true);
  };

  const handleCloseModalResource = () => {
    console.log('close')
    setOpenResource(false)
  };

  const handleConfirmResource = () => {
    console.log('close')
    setOpenResource(false)
  };

  // Modal Resource End //

  const [values, setValues] = React.useState({
    name: "hello",
    number: "",
  });

  const handleChanges = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };




  return (
    <>

      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection Section">
            <div className="MdTitle">Courses</div>
            

            <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
                {/* <SelectField
                  label="Select course"
                  required={true}
                  selectValue={selectValue}
                  value={age}
                  handleChange={handleChangelevel}
                /> */}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                
              </Grid>
              <Button variant="text">+ Add More Course</Button>
            </Grid>

          </div>
        </Grid>
      </Grid>


      







   



    </>
  );
}


export default Courses;

