import React, { useEffect, useState } from "react";
import axios from 'axios';
import {getUserData, getUserTimezone} from '../../../hooks/HelperFunctions';

import Table from '../../../components/Table/Table';
import EmptyCard from '../../../../src/components/EmptyCard/EmptyCard';
import CustomizedSnackbar from '../../../hooks/Snackbar';
import DraggableDialog from '../../../hooks/Dialog';
import SimpleBackdrop from '../../../components/Form/Backdrop/SimpleBackdrop';


import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import StatusChip from '../../../components/Form/StatusChip/StatusChip';
import Modals from '../../../components/Form/Modals/Modals';
import FileUplaoder from '../../../components/Form/FileUplaoder/FileUplaoder';
import OrderDetails from './PaymentMetod/OrderDetails';
import PaymentMethodZelle from './PaymentMetod/PaymentMethodZelle';
import PaymentMethodCash from './PaymentMetod/PaymentMethodCash';
import PaymentMethodCheck from './PaymentMetod/PaymentMethodCheck';
import OrderItems from './PaymentMetod/OrderItems';


function Tracks(props) {

  //base url for api taken from .env file 
  const baseUrl = process.env.REACT_APP_PROJECT_ENV == "dev" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
  const timezone = getUserTimezone();
  // get usr data
  const userData = getUserData();
  const userId = userData.id;
  // main website url
  let projectEnv = process.env.REACT_APP_PROJECT_ENV;
  let mainWebsiteUrl = process.env.REACT_APP_TAKBIR_SUBDOMAIN;

  if(projectEnv == "prod"){
    mainWebsiteUrl = mainWebsiteUrl.replace('sub-domain.', "");
  } else{
    mainWebsiteUrl = mainWebsiteUrl.replace('sub-domain', "dev");
  }

  const [tracks, setTracks] = useState([]);

  const [paymentData, setPaymentData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [paymentReceipt, setPaymentReceipt] = useState(null); 

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }
  
  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const showSnackbarMessage = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  }
  
  useEffect(() => {

    //call api to get media library data
    console.log("baseUrl", baseUrl);
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/orgPortal/getTracks?userId=${userId}&actionTab=true`, {})
      .then(res => {
        console.log("res", res.data);
        console.log("type:", typeof res.data);
        //traverse through the data and set the values in the table
        let data = res.data.body;
        // const data = JSON.parse(res.data);
        // setTotalItems(data);
        let temp = [];


        data.map((item, index) => {
          console.log("item", item);
          temp.push(
            {
              cell: item.cell,
              //======= Action Date Start===== //
              action: [
                // { label: "View", onClick: () => gotoDetailsPage(item.row.trackId) },
                // { label: "Edit", onClick: () => handleDrawerOpen(item.row) },
                // { label: "Delete", onClick: handleOpenDelete, },
              ]
              //======= Action Date End===== //
            }
          )
        })
        setTracks(temp);
        setOpenBackdrop(false);

      }
      )
      .catch(err => {
        console.log("err", err)
      }
      )

  }, []);



  const onClick = () => {
    alert("onclick working fine!");
  }
  
  const headCells = [
    { id: 'Track', numeric: true, disablePadding: false, label: 'Track', width: "40%" },
    { id: 'TrackType', numeric: true, disablePadding: false, label: 'Track Type', width: "20%" },
    { id: '#OfCourses', numeric: true, disablePadding: false, label: '# Of Courses', width: "20%" },
    { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: "20%" },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action', width: "15%" },
  ];


  // const rows = [
  //   {
  //     cell: [
  //       { img: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80", imgStatus: true, imgText: "Islamic studies and history", align: "left",},
  //       { chip: "CERTIFICATE", chipStatus: true, },
  //       { Status: true, background: "#EDEDEF", color: "#676C7B", label: "6",  },
  //       { Status: true, background: "#EEECFF", color: "#7266FB", label: "Enrolled", },
  //       {
  //         Button: true, buttonLabel: "Pay Now", buttonColor: "#FF6200", buttonBorder: "#FF6200 1px solid",
  //         buttonTextcolor: "#fff", buttonLink: "#", buttonTarget:"_blank",
  //       },
  //     ],
  //     //======= Action Date Start===== //
  //     action: [
  //       { label: "View", onClick: onClick, },
  //       { label: "Edit", onClick: onClick },
  //       { label: "Delete", onClick: onClick },
  //     ]
  //     //======= Action Date End===== //
  //   },  

  // ];





    // == Drawer Start == //
    const [openDrawer, setOpen] = useState(false);
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const handleDrawerClose = () => {
      setOpen(false);
    };
    // == Drawer End == //
  
  
    // == Modal Start == //
    const [openModal, setOpenModal] = useState(false);
  
    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      console.log('close')
      setOpenModal(false)
    };
    // == Modal End == //

    const handleConfirmModal = () => {
      console.log('confirm');
  
      setOpenBackdrop(true);
  
      let postData = {
        "paymentId" : paymentData.paymentId,
        "attachmentFile" : paymentReceipt,
        "markAsPaid": 1,
        "portal" : "learner",
      };
  
      axios.post(`${baseUrl}/studentPortal/updatePaymentsData`, postData)
        .then((response) => {
          // Handle the response from the server
          console.log(response.data);
          setOpenBackdrop(false);
          setOpenModal(false);
          handleDrawerClose();
          setPaymentReceipt(null);
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error(error);
        });
      
    };
    // == Drawer End == //
  
    const btnHandleClick = (id, operation) => {
      console.log(id, operation);
      // retrive payment data
      setOpenBackdrop(true);
      axios.get(`${baseUrl}/studentPortal/getPaymentDetails?paymentId=${id}&timezone=${timezone}`).then((result) => {
        console.log(result.data.body);
        setPaymentData(result.data.body);
        setCourseData(result.data.courseData);
        setOpenBackdrop(false);
        handleDrawerOpen();
      }).catch((err) => {
        console.log(err);
      });    
    }





  return (

    <>
      
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />    
      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
      
      <div className="TableSection">
        {tracks.length > 0 ? 
        (
          <Table
            tableHeader={true}
            innerTitle="Tracks"
            innerHeader={true}
            headCells={headCells}
            rows={tracks}
            btnHandleClick={btnHandleClick}
            AddButton={false}
            Checkbox={false}
            footer={true}
          />
        )
        :
        (
          <EmptyCard 
            TableTitle="My tracks"
            TableTitleStatus={false}
            title="You will find your tracks here." 
            description="Keep exploring to find a tracks!" 
            showButton={true} 
            btnLabel="Explore tracks" 
            btnLink={mainWebsiteUrl} 
          />
        )                
        }        
       
      </div> 
      
      {/*== SideDrawer ==*/}
      <SideDrawer
        title={<div className="TitlewituChip">${paymentData.amount? paymentData.amount/100 : 0} USD <StatusChip Status={paymentData.status? paymentData.status.charAt(0).toUpperCase() + paymentData.status.slice(1) : ""} /></div>} // Status="Paid, Failed, Canceled, Refund" //
        ButtonLabel="Mark As Paid"
        clickSaveButton={handleOpenModal}
        cancelButtonLabel="Close"
        DeleteButton={true}
        sideOpen="right"
        open={openDrawer}
        handleDrawerClose={handleDrawerClose}
        clickButtonLeft={handleDrawerOpen}
        FooterWithoutTab={true}
        body={
          <>
            <OrderDetails paymentData={paymentData} />

            {paymentData.method == "Zelle" && 
            <PaymentMethodZelle paymentData={paymentData} /> }
            {paymentData.method == "Cash" && 
            <PaymentMethodCash paymentData={paymentData} /> }
            {paymentData.method == "Check" && 
            <PaymentMethodCheck paymentData={paymentData} /> }

            <OrderItems courseData={courseData} />
          </>
        }

      />

      <Modals
        ModalTitle="Mark as paid"
        open={openModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
        ModalFooter={true}
        ButtonClose="Cancel"
        ButtonConfirm="Submit"
        width="540px"
        textAlign="center"
        ModalBody={<div>
          <div>MUNA Academy will be notified about your payment completion. Please add an attachment below (optional)</div>
          <div>
            {/* <FileUplaoder /> */}
            <FileUplaoder
                filePath={`students/${paymentData.userId}/payment-receipt/`}
                uploadButton="Upload Photo"
                file={paymentReceipt}
                fileType="image"                
                onUpload={(filePath) => setPaymentReceipt(filePath)}
                setSelectedFile={ () => {}}
                helpertext="Maximum size of 10 MB. JPG, JPEG, or PNG."
                showUploadButton={false}
                showThumb={true}
              />
          </div>

          </div>
          }
      />
      {/*== SideDrawer End ==*/}

    </>

  );
}


export default Tracks;





































