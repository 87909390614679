import React, { useState, useRef, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

function ImageGrid(props) {
  const { imgsData } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <div className='ImageGrid'>

      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

      {imgsData.map((item, i) => (
        <Tab
          label={
            <div className='ImageGridItemContainer'>
              <img className='ImageGridItem' src={item.img} />
              <span className='ImageAlfa'></span>
              <CheckCircleRoundedIcon />
            </div>
          }
        />
        ))}
      </Tabs>


    </div>

  );
}


export default ImageGrid;

