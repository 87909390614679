import React from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { withStyles } from '@material-ui/core/styles';



import './Switch.css';



{/* <SwitchButton
  title="Graded exam certificate"
  subtitle="Students can earn certificate submitting exam"
  BorderLine={true}
  ComingSoon={true}
  handleChange={handleChange}
  disabled
  checked={state.checkedB }
/> */}


function SwitchButton(props) {

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <div className="Switch">
        <div className="SwitchLeft">
          <h1>{props.title} {props.ComingSoon && <span>Coming Soon</span>}</h1>
          <p>{props.subtitle}</p>
        </div>
        <div className="SwitchRight">
          <Switch
            checked={props.checked}
            onChange={props.handleChange}
            color="primary"
            name="checkedB"
            disabled={props.disabled} 
            
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>
      {props.BorderLine && <div className="BorderLine"></div>}
    </>
  );
}


export default SwitchButton;

