import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';




function Payment(props) {


  //==== radio button//
  const [radiovalue, radiosetValue] = React.useState('female');

  const handleChangeRadio = (event) => {
    radiosetValue(event.target.radiovalue);
  };
  //==== radio button End//




  // ==== Date Picker === //
  const [dateTime, dateSetValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChangeDate = (newValue) => {
    dateSetValue(newValue);
  };

  // ==== Date Picker end === //

  // ==== Level Value === //
  const [age, setAge] = React.useState('');
  const handleChangelevel = (event) => {
    setAge(event.target.value);
  };

  const selectValue = [
    {
      label: "Beginner level",
      value: "Beginner level",
    },
    {
      label: "Intermediate level",
      value: "Intermediate level",
    },
    {
      label: "Expert level",
      value: "Expert level",
    },

    {
      label: "All levels",
      value: "All levels",
    },

  ];
  // ==== Level Value end === //


  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangePannel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [expanded_2, setExpanded_2] = React.useState('panel1');

  const handleChangePannel_2 = (panel) => (event, isExpanded_2) => {
    setExpanded_2(isExpanded_2 ? panel : false);
  };


  // Modal Module //

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    console.log('close')
    setOpen(false)
  };

  const handleConfirm = () => {
    console.log('close')
    setOpen(false)
  };

  // Modal Module  End//


  // Modal Session //

  const [openSession, setOpenSession] = useState(false);

  const handleOpenSession = () => {
    setOpenSession(true);
  };

  const handleCloseModalSession = () => {
    console.log('close')
    setOpenSession(false)
  };

  const handleConfirmSession = () => {
    console.log('close')
    setOpenSession(false)
  };

  // Modal Session  End//


  // Modal Resource //

  const [openResource, setOpenResource] = useState(false);

  const handleOpenResource = () => {
    setOpenResource(true);
  };

  const handleCloseModalResource = () => {
    console.log('close')
    setOpenResource(false)
  };

  const handleConfirmResource = () => {
    console.log('close')
    setOpenResource(false)
  };

  // Modal Resource End //

  const [values, setValues] = React.useState({
    name: "hello",
    number: "",
  });

  const handleChanges = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };




  return (
    <>

      <div className="formSection Section">
        <div className="MdTitle">Payment</div>
        <div className="FormBody">

          <Grid container spacing={3}>


            <Grid item xs={12} sm={12} md={12}>
              <div className="PaymentDetails">
                <div className="column" >
                  <div  style={{fontSize:16, color:"grey" }} >Order total</div>
                  <div style={{fontSize:48, fontWeight:1000}}>$40.25</div>
                </div>
              </div>
            </Grid>




            <Grid item xs={12} sm={12} md={12}>
              <span style={{ display: 'flex', alignItems: 'start', color: "grey" }}>  <InfoIcon /> Order total will be added as balance against this customer. Balance can be managed in Customer Details screen.</span>
            </Grid>

          </Grid>
        </div>
      </div>
    </>
  );
}


export default Payment;

