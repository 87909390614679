import React, { useState, useRef, useEffect } from "react";
import Table from "../../../../../components/Table/Table";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

function SelectIndividual(props) {
  const [checked, setChecked] = React.useState([true, false]);

  const handleChange = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Full Name",
      width: "35%",
    },
    {
      id: "email",
      numeric: true,
      disablePadding: false,
      label: "Email",
      width: "35%",
    },
    {
      id: "location",
      numeric: true,
      disablePadding: false,
      label: "# of courses",
      width: "35%",
    },
  ];

  const rows = [
    {
      cell: [
        { Check: true, onChange: handleChange, textBold: "Emma Smith" },
        {
          date: "emmasmith@gmail.com",
          chip: (
            <DoneRoundedIcon fontSize="small" style={{ borderRadius: "50%" }} />
          ),
          chipStatus: true,
        },
        { date: <div className="ofCoursesContainer">3</div> },
      ],
    },

    {
      cell: [
        { Check: true, onChange: handleChange, textBold: "Emma Smith" },
        {
          date: "emmasmith@gmail.com",
          chip: (
            <DoneRoundedIcon fontSize="small" style={{ borderRadius: "50%" }} />
          ),
          chipStatus: true,
        },
        { date: <div className="ofCoursesContainer">3</div> },
      ],
    },

    {
      cell: [
        { Check: true, onChange: handleChange, textBold: "Emma Smith" },
        {
          chip: (
            <div className="chipContainer">
              <DoneRoundedIcon fontSize="small" />
              Verified
            </div>
          ),
          chipStatus: true,
        },
        { date: <div className="ofCoursesContainer">2</div> },
      ],
    },

    {
      cell: [
        { Check: true, onChange: handleChange, textBold: "Emma Smith" },
        {
          date: "emmasmith@gmail.com",
          chip: (
            <DoneRoundedIcon fontSize="small" style={{ borderRadius: "50%" }} />
          ),
          chipStatus: true,
        },
        { date: <div className="ofCoursesContainer">3</div> },
      ],
    },

    {
      cell: [
        { Check: true, onChange: handleChange, textBold: "Emma Smith" },
        {
          chip: (
            <div className="chipContainer">
              <DoneRoundedIcon fontSize="small" />
              Verified
            </div>
          ),
          chipStatus: true,
        },
        { date: <div className="ofCoursesContainer">2</div> },
      ],
    },
    {
      cell: [
        { Check: true, onChange: handleChange, textBold: "Emma Smith" },
        {
          date: "emmasmith@gmail.com",
          chip: (
            <DoneRoundedIcon fontSize="small" style={{ borderRadius: "50%" }} />
          ),
          chipStatus: true,
        },
        { date: <div className="ofCoursesContainer">3</div> },
      ],
    },

    {
      cell: [
        { Check: true, onChange: handleChange, textBold: "Emma Smith" },
        {
          chip: (
            <div className="chipContainer">
              <DoneRoundedIcon fontSize="small" />
              Verified
            </div>
          ),
          chipStatus: true,
        },
        { date: <div className="ofCoursesContainer">2</div> },
      ],
    },
  ];

  return (
    <>
      <div className="TableSection lrTable">
        <Table
          headCells={headCells}
          rows={rows}
          footer={false}
          innerHeader={true}
          showSearchBox={true}
          
        />
      </div>
    </>
  );
}

export default SelectIndividual;
