import React, { useEffect, useState } from "react";
import Table from '../../../../../components/Table/Table';
import EmptyCard from '../../../../../components/EmptyCard/EmptyCard';

function Resources(props) {

  const { courseResources } = props;


  const headCells = [
    { id: 'Resource', numeric: false, disablePadding: true, label: 'Resource', width: "80%" },
    { id: 'Added', numeric: true, disablePadding: false, label: 'Added', width: "20%" },
    { id: 'Action', numeric: true, disablePadding: false, label: '', width: "15%" },

  ];


  return (
    <div className="TableSection">
      {courseResources?.length == 0?  
          <EmptyCard title="No resourses found" description="Added resourses will be visible here" /> :
          <Table
            innerTitle="Resources"
            innerHeader={true}
            headCells={headCells}
            rows={courseResources}
            perPageItems={10}
            footer={true}
          />
        }
    </div>

  );
}


export default Resources;

