import React, { useEffect, useState } from "react";
import Buttons from '../../../../../components/Form/Button/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Courses(props) {


  return (
    <>
      <div className="Table">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            


            <TableBody>

            <TableRow>
                <TableCell className="bold"><h1>Courses</h1></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">  </TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>

            <TableRow>
                <TableCell className="bold">Course</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Start date </TableCell>
                <TableCell align="left">Completed</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left">Introduction to Islamic Economics and Finance </TableCell>
                <TableCell align="left"><span className="status rounded success">Completed</span></TableCell>
                <TableCell align="left">Jan 12, 2022</TableCell>
                <TableCell align="left">Sep 10, 2022</TableCell>

                <TableCell align="left">
                  <Buttons
                    //icon={<AddRoundedIcon />}
                    label="Details "
                    buttonColor="#004FE0"
                    color="#fff"
                    height="50px"
                    fontSize="16px"
                    // link="/add-instructor"
                  />


                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell align="left">Introduction to Islamic Economics and Finance </TableCell>
                
                <TableCell align="left"><span className="status rounded inprogress">inprogress</span></TableCell>
                <TableCell align="left">Jan 12, 2022</TableCell>
                <TableCell align="left">Sep 10, 2022</TableCell>

                <TableCell align="left">
                  <Buttons
                    //icon={<AddRoundedIcon />}
                    label="Details "
                    buttonColor="#004FE0"
                    color="#fff"
                    height="50px"
                    fontSize="16px"
                    // link="/add-instructor"
                  />


                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell align="left">Introduction to Islamic Economics and Finance </TableCell>
                <TableCell align="left"><span className="status rounded error">Upcoming</span></TableCell>
                <TableCell align="left">Jan 12, 2022</TableCell>
                <TableCell align="left">Sep 10, 2022</TableCell>

                <TableCell align="left">
                  <Buttons
                    //icon={<AddRoundedIcon />}
                    label="Details "
                    buttonColor="#004FE0"
                    color="#fff"
                    height="50px"
                    fontSize="16px"
                    // link="/add-instructor"
                  />


                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer >
      </div >

    </>
  );
}






export default Courses;

