import React, { useState } from "react";
import { Grid } from "@mui/material";
import SelectField from "../../../../../../components/Form/SelectField/SelectField";
import TextField from "../../../../../../components/Form/TextField/TextField";

//import sidedrawer
import SideDrawer from "../../../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import Externaluse from "../../../../../../components/MediaLibrary/Externaluse";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Buttons from "../../../../../../components/Form/Button/Button";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import Avatar from "@mui/material/Avatar";

const ZellePayment = (props) => {

  const { paymentData, setPaymentData, options } = props;

  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(0);
  const [mediaItem, setMediaItem] = useState({});
  const [selectedValue, setSelectedValue] = React.useState(false);

  // const [zelleFile, setZelleFile] = useState();

  // === handleChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    const fieldData = event.target.value;
    console.log(fieldName, fieldData);
    setPaymentData({ ...paymentData, [fieldName]: fieldData });
  };

  // === handleChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setPaymentData({ ...paymentData, [fieldName]: fieldData });
  };


  //handleDrawerOpenSelectMedia
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
    // setOpenNewMedia(1);
  };
  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
    setOpenNewMedia(false);
  };
  
  const handleDrawerOpenNewMedia = () => {
    // console.log("handle Drawer Open New Media");
    // console.log( openNewMedia ) 
    // setOpenNewMedia(1);
    setTimeout(() => {
      setOpenNewMedia(1);
    }, 100); // 3000 milliseconds (3 seconds) delay   
    // console.log( openNewMedia ) 
  };

  //getMediaItem
  const getMediaItem = (item) => {
    console.log(item);
    // setting the media item
    setMediaItem(item);
  };

  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      //set the zelle file
      // setZelleFile(mediaItem);
      // console.log('test', mediaItem );
      // handleChangeWithData("receiptOrScreenshotFileName", mediaItem.name);
      // handleChangeWithData("receiptOrScreenshotFileUrl", mediaItem.file_url);
      setPaymentData({ ...paymentData, 'receiptOrScreenshotFileName': mediaItem.name, "receiptOrScreenshotFileUrl": mediaItem.file_url });
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
  };

  //delete zelle file
  const deleteSelectedMediaItem = () => {
    //set the zelle file
    // setZelleFile();
    handleChangeWithData("receiptOrScreenshotFileName", "");
    handleChangeWithData("receiptOrScreenshotFileUrl", "");
  };

  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    console.log("handle Open New Media", status);
    setOpenNewMedia( !status );
    handleDrawerOpenSelectMedia( status );
  };

  

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SelectField
            label="Status"
            validation
            options={options}
            selectedOption={paymentData.status}
            handleChange={handleChanges("status")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Amount Paid"
            validation={true}
            number={true}
            placeholder="$"
            // count={80}
            value={paymentData.amountPaid}
            handleChange={handleChanges("amountPaid")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Collected/Verified By"
            validation={false}
            // count={80}
            value={paymentData.collectedBy}
            handleChange={handleChanges("collectedBy")}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
          <div class="FieldLabel">Receipt/Screenshot</div>
            {paymentData.receiptOrScreenshotFileUrl ? (
              <div className="TableSection">

                <div
                  style={{
                    padding: '0.2rem',
                    // background: "#C3C3C3",
                    border: '1px solid #E4E8EC',
                    borderRadius: '10px',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "70px",
                    padding: "0 16px",
                  }}
                >

                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <span className="CellImg">
                      <Avatar variant="rounded" src={paymentData.receiptOrScreenshotFileUrl} alt="zelle" />
                    </span>
                    <span> {paymentData.receiptOrScreenshotFileName} </span>
                    <a href={paymentData.receiptOrScreenshotFileUrl} download className="viewPhoto">View Photo</a>
                  </div>


                  <span className="CellImg cursorPoint">
                    <DeleteForeverRoundedIcon
                      onClick={deleteSelectedMediaItem}
                      sx={{ mb: -0.5, color: '#676C7B' }}
                    />
                  </span>

                </div>

              </div>
            ) : (
              <div>
                <SideDrawer
                  title="Media Library"
                  ButtonLabel="Add"
                  //clickSaveButton={clickSaveButton}
                  cancelButtonLabel="Cancel"
                  sideOpen="right"
                  open={openSelectMedia}
                  handleDrawerClose={handleDrawerCloseSelectMedia}
                  FooterWithoutTab={true}
                  LeftButton={true}
                  ButtonLeft="Upload New"
                  ButtonLeftIcon={<FileUploadOutlinedIcon />}
                  clickButtonLeft={handleDrawerOpenNewMedia}
                  clickSaveButton={clickSelectMediaItem}
                  body={
                    <MediaLibrarySelectMedia
                      onClickRadioIcon={setSelectedValue}
                      onItemSelected={getMediaItem}
                    />
                  }
                />

                <Externaluse
                  open={openNewMedia}
                // commented by parsa .. don't know where this is function is .. code is added by atiq bhai
                // handleCloseButton={handleMediaUploadClose}
                  handleOpenNewMedia={handleOpenNewMedia}
                  handleCloseButton={handleDrawerCloseSelectMedia}
                />

                <div className="TableSection">
                  <Buttons
                    label="Add From Media Library"
                    buttonColor="transparent"
                    border="#ccc 1px solid"
                    icon={<AddRoundedIcon />}
                    color="#004FE0"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    link=""
                    onClick={() => handleDrawerOpenSelectMedia()}
                  />
                </div>
              </div>
            )}


          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Additional Information"
            validation={false}
            count={100}
            value={paymentData.additionalInfo}
            handleChange={handleChanges("additionalInfo")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ZellePayment;
