import React, { useState, useEffect } from "react";
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import './Datefield.css';

function Datefield(props) {

  const { label, required, validation, error, placeholder, value, timezone, 
    handleChange, disablePast, helperText, disabled } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");
  const [localDate, setLocalDate] = useState(null);

  // Function to convert the date to the custom timezone
  const convertToCustomTimezone = (date) => {
    if(typeof date == "string"){
      const dateObject = new Date(date);
      const convertedDate =  utcToZonedTime(dateObject, timezone);
      setLocalDate(convertedDate);
    } else{   
      
      if(!date){
        handleChange(date)
      }else{
        const dateObject = new Date(date);
        // console.log(label, dateObject, timezone);
        const convertedDate = zonedTimeToUtc(dateObject, timezone);
        setLocalDate(date);
        handleChange(convertedDate);
      }
    }  

  };

  useEffect(() => {
    convertToCustomTimezone(value);
  }, []);


  useEffect(() => {

    if (required || validation) {

      if (!value) {
        setValidationError(true);
        setValidationText("Required");        
      } else {
        setValidationError(false);
        setValidationText("");
      }

    }
    
  }, [value]);

  return (
    <div className="Datefield">
      <div className="FieldLabel">{label} {validation && <span>*</span>}</div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label=""
          inputFormat="MM/DD/YYYY"
          value={!timezone? value : localDate}
          onChange={!timezone? handleChange : (newDate) => {convertToCustomTimezone(newDate)}}
          renderInput={(params) => <TextField {...params} error={error || validationError} helperText={helperText || validationText} />}
          disablePast={disablePast? true : false}
        />
      </LocalizationProvider>

    </div>
  );
}


export default Datefield;

