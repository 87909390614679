import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './ModalsResponsiveFullScreen.css';



function ModalsResponsiveFullScreen(props) {


  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <div className="MdlResponsiveFullScreen">
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        className="MdFullScreenContainer"
      >
        <div className="ModaFulllTitle" style={{ color: props.Headercolor }}>{props.ModalTitle}</div>
        <span><CloseRoundedIcon className="ModaClose" onClick={() => props.handleClose()} style={{ color: props.Headercolor }} /></span>
        <DialogContent>
          {props.ModalBody}
        </DialogContent>

          {props.ModalFooter &&
              <div  style={{ background: props.Footerbackground }}>
                <div className="FooterButtonContainer">
                  <Button variant="contained" className='btLight' onClick={() => props.handleClose()} >{props.ButtonClose}</Button>
                  <Button variant="contained" className='btnBlue' onClick={() => props.handleConfirm()} >{props.ButtonConfirm}</Button>
                </div>
              </div>
            }
      </Dialog>


    </div>
  );
}


export default ModalsResponsiveFullScreen;

