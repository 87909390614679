import React, { useState } from "react";
import { Grid } from "@mui/material";
// import { PhoneInputField } from "master-react-lib";
import TextField from "../../../../components/Form/TextField/TextField";
import PhoneInputField from "../../../../components/Form/PhoneInputField/PhoneInputField"

const AddOneByOne = () => {
  const [phone, setPhone] = useState({
    country: "",
    number: "",
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextField label="First Name" required={true} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label="Middle Name" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label="Last Name" required={true} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="Email Address" required={true} helperText="Add email to send an invite" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            value={phone.number}
            countryCodeEditable={false}
            onChange={(phone, country) =>
              console.log("onChange", phone, country)
            }
            onBlur={() => console.log("onBlur")}
            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
            variant="outlined"
            // onlyCountries={["us", "ca", "gb", "my"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "40px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorText={"Phone number is required"}
            error={false}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            labelFixed="Phone Number"
            LabelStatus={true}
            // helpertext="Helper text"
            // error="error"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddOneByOne;
