import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ImageGrid from './ImageGrid';
import ImageDetials from './ImageDetials';
import img from './meida-img.jpg';
import UplaodModal from './UplaodModal';



import './MediaLibrary.css';


function MediaLibrary(props) {

  // ==== Name Modals ===== //
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ==== Name Modals ===== //


  const [items, setAgeItems] = React.useState('');
  const handleChangeItems = (event) => {
    setAgeItems(event.target.value);
  };

  const [dates, setAgedates] = React.useState('');
  const handleChangeDates = (event) => {
    setAgedates(event.target.value);
  };


  const imgsData = [
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
    { img: img, },
  ]



  return (

    <div className='MediaLibraryContainer'>

      <div className='MediaLibraryLeftCold'>
        <div className='MediaLibraryHeader'>
          <div className='MediaLibraryTitle'>Media Library</div>
          <div className='MediaLibraryRihtCol'>
            <Button className='MediaLibraryHeaderButton' onClick={handleClickOpen}><AddRoundedIcon /> Add Video</Button>
            <Button className='MediaLibraryHeaderButton'><AddRoundedIcon /> Upload Photo</Button>
          </div>
        </div>

        <div className='MediaLibraryFilter'>
          <Select
            id="demo-select-small"
            value={items}
            onChange={handleChangeItems}
          >
            <MenuItem value={10}>All media items</MenuItem>
            <MenuItem value={20}>Image</MenuItem>
            <MenuItem value={30}>Video</MenuItem>
          </Select>

          <Select
            id="demo-select-small"
            value={dates}
            onChange={handleChangeDates}
          >
            <MenuItem value={10}>All Dates</MenuItem>
            <MenuItem value={20}>Dec 2022</MenuItem>
            <MenuItem value={30}>Nov 2022</MenuItem>
          </Select>
        </div>

        <div className='MediaLibraryBody'>

          <div className='MediaLibraryImgGrid'>
            <ImageGrid
              imgsData={imgsData}
            />
          </div>

        </div>
      </div>

      <div className='MediaLibraryRightCold'>
          <ImageDetials />
      </div>



      <UplaodModal
        handleClose={handleClose}
        open={open}
        ButtonClose="Cancel"
        ButtonConfirm="Save"
        ModalFooter={true}
      />

      

    </div>

  );
}


export default MediaLibrary;

