import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Buttons from '../Form/Button/Button';
import Tooltip from '../Form/Tooltip/Tooltip';

import Box from '@mui/material/Box';
import TabsMobile, { tabsClasses } from '@mui/material/Tabs';
import TabMobile from '@mui/material/Tab';

import './HrTabsFill.css';

function HrTabsFill(props) {

  const { tabLabel, tabBody, } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // const tabLabel = [
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  // ]

  // const tabBody = [
  //   {
  //     id: 0,
  //     panel: "dsfsdf",
  //   },
  //   {
  //     id: 1,
  //     panel: "dsfsdfdsfsdv",
  //   },
  //   {
  //     id: 2,
  //     panel: "dsfsddsvsdvsdvsdvf",
  //   },

  // ]

  // <HrTabsFill 
  //       title="Introduction to Islamic Economics and Finance"
  //       name="MUNA Academy"
  //ButtonLabel=""
  //Button={true}
  //       link="#"
  //       tabLabel={tabLabel}
  //       tabBody={tabBody}
  //     />


  return (
    <div className="HrTabsFill">
      <div className="HrTabsFillTabSection">
        <div className="HrTabFillHeader">
          <h1 className="HrTabFillTitle">{props.title}</h1>
        </div>

        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {tabLabel.map((item, i) => (
            <Tab label={item.label} icon={item.icon} {...a11yProps(0)} />
          ))}
        </Tabs>
      </div>

      <div className="HrTabsFillBody">
        {tabBody.map((item, i) => (
          <TabPanel value={value} index={item.id}>
            {props.alertBox}
            {item.panel}
          </TabPanel>
        ))}
      </div>

    </div>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default HrTabsFill;

