import React from "react";
import InProgress from './InProgress';
import Tracks from './Tracks';
import HrTabsFill from '../../../components/HorizotalTabs/HrTabsFill';


function MyLearning(props) {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const tabLabel = [
    {
      label: "Courses",
    },
    {
      label: "Tracks",
    },
  ]

  const tabBody = [
    {
      id: 0,
      panel: <InProgress />,
    },
    {
      id: 1,
      panel: <Tracks />,
    },
  ]


  return (

    <>
      <HrTabsFill
        title="My learning"
        tabLabel={tabLabel}
        tabBody={tabBody}
      />
    </>

  );
}


export default MyLearning;





































