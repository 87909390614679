import React, { useState, useRef, useEffect } from 'react';
import TextField from '../Form/TextField/TextField';
import { Grid } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import YouTube from 'react-youtube';
import {getVideoId} from './Functions'
import './MediaLibrary.css';

const StyledTableCell = withStyles((theme) => ({

}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: "#FCFCFC",
    },
  },
}))(TableRow);


function EditMedia(props) {

  

  const [email, setEmail] = React.useState("Course cover: The firsts with Omar Suleiman");
  const [formValidationError, setFormValidationError] = useState(false);
  const handleValidation = (validationError) => {
    // set form validation error
    if (validationError) {
      setFormValidationError(true);
    } else {
      setFormValidationError(false);
    }
  }

  const handleChanges = (fieldName) => (event) => {
    const value = event.target.value;
    props.setMediaName( value )
    // setMediaName(value);
  };

  const opts = {
    height: '126',
    width: '224',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <div className='MediaLibPanelTextField'>
              <TextField
                label="Media Name"
                required={true}
                // email={true}
                value={props.medianame}
                handleValidation={handleValidation}
                handleChange={handleChanges("mediaName")}
                placeholder="Media Name"
                count={50}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className='EditImageSection'>
              {props.file_type != "video" && (props.file_type.startsWith('image/')) &&(                
                <div className='EditImageCol'><img className="MeidaImg" src={props.file_url} /></div>
              )}
              {props.file_type != "video" && (!props.file_type.startsWith('image/')) &&(                
                <div className='EditImageCol'>File: <a href={props.file_url}> Download </a> </div>
              )}
              {props.file_type == "video" ?(
                <div className='EditImageCol'>                    
                    <YouTube videoId={getVideoId(props.file_url)} opts={opts} />                    
                </div>
              ):""}              

            </div>
          </Grid>


          <Grid item xs={12} md={12}>

            <div className="TableContainer oddEvenTable">
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableBody>

                    <StyledTableRow >
                      <StyledTableCell align="left">Uploaded</StyledTableCell>
                      <StyledTableCell align="left">{props.created_at}</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow >
                      <StyledTableCell align="left" width="30%">File name</StyledTableCell>
                      <StyledTableCell align="left">{props.file_name}</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow >
                      <StyledTableCell align="left">File type</StyledTableCell>
                      <StyledTableCell align="left">{props.file_type}</StyledTableCell>
                    </StyledTableRow>
                    {props.file_type != "video" ?(
                      <StyledTableRow >
                        <StyledTableCell align="left">File size</StyledTableCell>
                        <StyledTableCell align="left">{props.file_size}</StyledTableCell>
                      </StyledTableRow>
                    ):""}
                    {props.file_type != "video" && (props.file_type.startsWith('image/')) && (
                    <StyledTableRow >
                      <StyledTableCell align="left">Dimension</StyledTableCell>
                      <StyledTableCell align="left">{props.fileMeta?.width} x {props.fileMeta?.height} pixels</StyledTableCell>
                    </StyledTableRow>
                    )}

                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default EditMedia;

