import React, { useEffect, useState } from "react";
import Buttons from '../../../../../components/Form/Button/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import VerifiedUser from '@mui/icons-material/Verified';

import Button from '@mui/material/Button';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function Security(props) {

  // Modal Add Category  start//

  const [openNewPayment, setOpenNewPayment] = useState(false);

  const handleOpenNewPayment = () => {
    setOpenNewPayment(true);
  };

  const handleCloseModalNewPayment = () => {
    console.log('close')
    setOpenNewPayment(false)
  };

  const handleConfirmNewPayment = () => {
    console.log('close')
    setOpenNewPayment(false)
  };

  // Modal Add Category  End//





  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div className="Table">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">



            <TableBody>

              <TableRow>
                <TableCell align="left"><h1>Access details</h1></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left"> Email address</TableCell>
                <TableCell align="left"><span  style={{display:'flex', alignItems:'center'}}><span style={{display:"inline-block"}}>abdurrehman@gmail.com</span>  <span style={{display:"inline-block"}}><span  style={{display:'flex', alignItems:'center'}} className="status rounded success"><VerifiedUser style={{fontSize: 16}}/>Verified</span></span></span> 
                </TableCell>
                <TableCell align="left">
                  <Buttons
                    //icon={<AddRoundedIcon />}
                    label="Change "
                    buttonColor="#004FE0"
                    color="#fff"
                    height="50px"
                    fontSize="16px"
                    // link="/add-instructor"
                  />


                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left">Phone </TableCell>
                <TableCell align="left">+1 (123) 123-1234</TableCell>
                <TableCell align="left">
                  <Buttons
                    //icon={<AddRoundedIcon />}
                    label="Change "
                    buttonColor="#004FE0"
                    color="#fff"
                    height="50px"
                    fontSize="16px"
                    // link="/add-instructor"
                  />


                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell align="left">Password </TableCell>
                <TableCell align="left">...................</TableCell>
                <TableCell align="left">
                  <Buttons
                    //icon={<AddRoundedIcon />}
                    label="Change "
                    buttonColor="#004FE0"
                    color="#fff"
                    height="50px"
                    fontSize="16px"
                    // link="/add-instructor"
                  />


                </TableCell>
              </TableRow>






            </TableBody>
          </Table>
        </TableContainer >
      </div >






      <div className="Table">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">



            <TableBody>

            <TableRow>
                <TableCell className="bold"><h1>Login Sessions</h1></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">  </TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">

                  <Buttons
                    //icon={<AddRoundedIcon />}
                    label=" Sign Out All Session "
                    buttonColor="#004FE0"
                    color="#fff"
                    height="48px"
                    fontSize="14px"
                    link="#"
                  //onClick={() => setOpenNewPayment(true)}
                  />

                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell align="left">Location</TableCell>
                <TableCell align="left">Device</TableCell>
                <TableCell align="left">ID ADDRESS</TableCell>
                <TableCell align="left">Time</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>

             

              <TableRow>
                <TableCell align="left">Bangladesh </TableCell>
                <TableCell align="left">Chrome - Windows</TableCell>
                <TableCell align="left">980.345.463.834 </TableCell>
                <TableCell align="left">1 min ago</TableCell>
                <TableCell align="left">
                  <div align="left">


                    <Button className="ActionButton" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                      Action <KeyboardArrowDownRoundedIcon />
                    </Button>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>Profile</MenuItem>
                      <MenuItem onClick={handleClose}>My account</MenuItem>
                    </Menu>

                  </div>
                </TableCell>
                
               
              </TableRow>


              <TableRow>
                <TableCell align="left">Bangladesh </TableCell>
                <TableCell align="left">Chrome - Windows</TableCell>
                <TableCell align="left">980.345.463.834 </TableCell>
                <TableCell align="left">1 min ago</TableCell>
                <TableCell align="left">
                  <div align="left">


                    <Button className="ActionButton" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                      Action <KeyboardArrowDownRoundedIcon />
                    </Button>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>Profile</MenuItem>
                      <MenuItem onClick={handleClose}>My account</MenuItem>
                    </Menu>

                  </div>
                </TableCell>
                
               
              </TableRow>



              <TableRow>
                <TableCell align="left">Bangladesh </TableCell>
                <TableCell align="left">Chrome - Windows</TableCell>
                <TableCell align="left">980.345.463.834 </TableCell>
                <TableCell align="left">1 min ago</TableCell>
                <TableCell align="left">
                  <div align="left">


                    <Button className="ActionButton" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                      Action <KeyboardArrowDownRoundedIcon />
                    </Button>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>Profile</MenuItem>
                      <MenuItem onClick={handleClose}>My account</MenuItem>
                    </Menu>

                  </div>
                </TableCell>
                
               
              </TableRow>


            






            </TableBody>
          </Table>
        </TableContainer >
      </div >

    </>
  );
}






export default Security;

