import React, { useEffect, useState } from "react";
import {dateFormatter, getUserTimezone} from '../../../../hooks/HelperFunctions';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../../../../assets/css/PaymentMethod.css';



const PaymentMethodCash = (props) => {

  const {paymentData} = props;

  const timezone = getUserTimezone();

  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "#F8F8F9",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========/


  return (
    <>



      <div className="PaymentMethod">
        <div className="PaymentMethodTitle">Payment Details</div>

        <div className="TableContainer oddEvenTable">
          
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableBody>

                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Method</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">Cash</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Appointment schedule</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">{dateFormatter(paymentData.cashReceivedAt, timezone, "D-MMM-YYYY h:mm A (Z)")}</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Support</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">+1 (123) 123-1234</StyledTableCell>
                </StyledTableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </div>


    </>
  );
};

export default PaymentMethodCash;


