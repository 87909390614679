import React, { useState, useEffect } from "react";
import axios from 'axios';
import {getUserData, getUserTimezone} from '../../../../../hooks/HelperFunctions';

import Buttons from '../../../../../components/Form/Button/Button';
import CampaignIcon from '@mui/icons-material/Campaign';
import SimpleBackdrop from '../../../../../components/Form/Backdrop/SimpleBackdrop';
import EmptyCard from '../../../../../components/EmptyCard/EmptyCard';


function Announcements(props) {

  const { courseId } = props;

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [itemLimit, setItemLimit] = useState(5);

  // fetching course data

  const getAnnouncements = () => {
    const apiBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    setOpenBackdrop(true);
    axios.get(`${apiBaseUrl}/studentPortal/getAnnouncements?courseId=${courseId}&itemLimit=${itemLimit}`).then((result) => {
      console.log(result.data.body);
      setAnnouncements(result.data.body);
      setItemLimit(result.data.itemLimit);
      setOpenBackdrop(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {

    getAnnouncements();

  }, []);


  return (
    <div className="AnnouncementContainer">
      <SimpleBackdrop open={openBackdrop} />
       <div className="RecentAnnouncementcards">

       {announcements?.map((item, i) => (
        <div className="RecentAnnouncementCard">
          <div className="RecentAnnouncementimg">
            <div className="box">
              <CampaignIcon />
            </div>
            <div> 
            <h1>{item.title}</h1>
            <h5>{item.timeAge}</h5></div>
          </div>
          <div className="Announcementcardcontent">
            {item.details && <p>{item.details}</p> }
          </div>
        </div>
        ))}

      {announcements?.length == 5 &&  
      <div className="AnnouncementBottombutton">
          <Buttons
            label="See More"
            buttonColor="FFFFFF00"
            color="#004FE0"
            height="48px"
            fontSize="15px"
            border="#E4E8EC 1px solid"
            width="100%"
            onClick={getAnnouncements}
          />
        </div> }

        {announcements?.length == 0 && 
          <EmptyCard title="No announcement found" description="Added announcements will be visible here" />
        }
      </div> 


    </div>
  );
}


export default Announcements;

