import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import SwitchAccordion from '../../../../../components/SwitchAccordion/SwitchAccordion';
import PriceTextField from '../../../../../components/Form/PriceTextField/PriceTextField';
import TextField from '../../../../../components/Form/TextField/TextField';
import SelectField from '../../../../../components/Form/SelectField/SelectField';
import CheckBox from '../../../../../components/Form/CheckBox/CheckBox';


function PricingOption(props) {

  const { fullPrice, setFullPrice, yearlyPrice, setYearlyPrice, monthlyPrice, setMonthlyPrice, totalTrackPrice, setTotalTrackPrice} = props;
  
  const [monthlyAmountPerPayment, setMonthlyAmountPerPayment] = useState(0);
  const [yearlyAmountPerPayment, setYearlyAmountPerPayment] = useState(0);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel, pricingOption) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    handleChangeValue(pricingOption, "enabled", "tabSwitch", event, isExpanded? true : false);
  };

  const handleChangeValue = (pricingOption, fieldName, fieldType, event, value='') => {    

    const fieldValue = fieldType=='checkbox'? event.target.checked : fieldType=='tabSwitch'? value : event.target.value;

    if(fieldType=='checkbox'){
      setFullPrice({ ...fullPrice, [fieldName]: false });
      setYearlyPrice({ ...yearlyPrice, [fieldName]: false });
      setMonthlyPrice({ ...monthlyPrice, [fieldName]: false });
    }

    console.log(pricingOption, fieldName, fieldType, value, fieldValue);

    if(pricingOption == "fullPrice"){
      setFullPrice({ ...fullPrice, [fieldName]: fieldValue });
    } else if(pricingOption == "yearlyPrice"){
      setYearlyPrice({ ...yearlyPrice, [fieldName]: fieldValue });
    } else if(pricingOption == "monthlyPrice"){
      setMonthlyPrice({ ...monthlyPrice, [fieldName]: fieldValue });
    }    

  };

  useEffect( () => {
    let tmpAmountPerPayment = fullPrice.price;
    setFullPrice({ ...fullPrice, ['amountPerPayment']: tmpAmountPerPayment });
  }, [fullPrice.price]);

  useEffect( () => {
    let tmpAmountPerPayment = yearlyPrice.totalNumOfPayments > 0? yearlyPrice.price / yearlyPrice.totalNumOfPayments : 0;
    tmpAmountPerPayment = typeof tmpAmountPerPayment === 'number' && !isNaN(tmpAmountPerPayment)? Math.round(tmpAmountPerPayment) : 0;
    setYearlyAmountPerPayment(tmpAmountPerPayment);
    setYearlyPrice({ ...yearlyPrice, ['amountPerPayment']: tmpAmountPerPayment });
  }, [yearlyPrice.price, yearlyPrice.totalNumOfPayments]);

  useEffect( () => {
    let tmpAmountPerPayment = monthlyPrice.totalNumOfPayments > 0? monthlyPrice.price / monthlyPrice.totalNumOfPayments : 0;
    tmpAmountPerPayment = typeof tmpAmountPerPayment === 'number' && !isNaN(tmpAmountPerPayment)? Math.round(tmpAmountPerPayment) : 0;
    setMonthlyAmountPerPayment(tmpAmountPerPayment);
    setMonthlyPrice({ ...monthlyPrice, ['amountPerPayment']: tmpAmountPerPayment });
  }, [monthlyPrice.price, monthlyPrice.totalNumOfPayments]);

  const paymentFrequencyOptions = [
    {
      label: "Monthly",
      value: "1",
    },

    {
      label: "Every 2 months",
      value: "2",
    },
    {
      label: "Every 3 months",
      value: "3",
    },

    {
      label: "Every 4 months",
      value: "4",
    },
    {
      label: "Every 5 months",
      value: "5",
    },
    {
      label: "Every 6 months",
      value: "6",
    },

    {
      label: "Every 7 months",
      value: "7",
    },
    {
      label: "Every 8 months",
      value: "8",
    },
    {
      label: "Every 9 months",
      value: "9",
    },
    {
      label: "Every 10 months",
      value: "10",
    },
    {
      label: "Every 11 months",
      value: "11",
    },
  ]

  return (
    <div>
      <p style={{ marginTop: "0" }}>To display a markdown, enter a value higher than your price. It will be shown to the learner with a strikethrough.</p>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <PriceTextField
            label="Total Track Price"
            number={true}
            TooltipStatus={true}
            TooltipText="Total Track Price Info"
            handleChange={(event) => setTotalTrackPrice(event.target.value)}
            values={totalTrackPrice}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <SwitchAccordion
            title="Pay in full"
            expanded={expanded === 'panel' || fullPrice.enabled}
            onChange={handleChange('panel', 'fullPrice')}
            //handleChangeSwitch={handleChange}

            body={
              <Grid container spacing={3}>

                <Grid item xs={12} sm={6} md={6}>
                  <PriceTextField
                    label="Price"
                    number={true}
                    handleChange={(event) => handleChangeValue('fullPrice','price', 'text', event)}
                    values={fullPrice.price}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <PriceTextField
                    label="Compare-at price"
                    number={true}
                    TooltipStatus={true}
                    TooltipText="Compare-at price"
                    handleChange={(event) => handleChangeValue('fullPrice','compareAtPrice', 'text', event)}
                    values={fullPrice.compareAtPrice}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <CheckBox
                    label="Set this as most popular"
                    checked={fullPrice.mostPopular}
                    onChange={(event) => handleChangeValue('fullPrice','mostPopular', 'checkbox', event)}
                  />
                </Grid>

              </Grid>
            }
          />

          <SwitchAccordion
            title="Yearly"
            expanded={expanded === 'panel2' || yearlyPrice.enabled}
            onChange={handleChange('panel2', 'yearlyPrice')}

            body={
              <Grid container spacing={3}>

                <Grid item xs={12} sm={6} md={6}>
                  <PriceTextField
                    label="Price"
                    number={true}
                    handleChange={(event) => handleChangeValue('yearlyPrice','price', 'text', event)}
                    values={yearlyPrice.price}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <PriceTextField
                    label="Compare-at price"
                    number={true}
                    TooltipStatus={true}
                    TooltipText="Compare-at price"
                    handleChange={(event) => handleChangeValue('yearlyPrice','compareAtPrice', 'text', event)}
                    values={yearlyPrice.compareAtPrice}
                  />
                </Grid>
                
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Total number of payments"
                      number={true}
                      handleChange={(event) => handleChangeValue('yearlyPrice','totalNumOfPayments', 'text', event)}
                      value={yearlyPrice.totalNumOfPayments}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <div>Amount per payment <strong>${yearlyAmountPerPayment}</strong></div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <CheckBox
                    label="Set this as most popular"
                    checked={yearlyPrice.mostPopular}
                    onChange={(event) => handleChangeValue('yearlyPrice','mostPopular', 'checkbox', event)}
                  />
                </Grid>

              </Grid>
            }
          />


          <SwitchAccordion
            title="Monthly"
            expanded={expanded === 'panel3' || monthlyPrice.enabled}
            onChange={handleChange('panel3', 'monthlyPrice')}

            body={
              <Grid container spacing={3}>

                <Grid item xs={12} sm={6} md={6}>
                  <PriceTextField
                    label="Price"
                    number={true}
                    handleChange={(event) => handleChangeValue('monthlyPrice','price', 'text', event)}
                    values={monthlyPrice.price}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <PriceTextField
                    label="Compare-at price"
                    number={true}
                    TooltipStatus={true}
                    TooltipText="Compare-at price"
                    handleChange={(event) => handleChangeValue('monthlyPrice','compareAtPrice', 'text', event)}
                    values={monthlyPrice.compareAtPrice}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <SelectField
                    label="Payment frequency"
                    options={paymentFrequencyOptions}
                    selectedOption={monthlyPrice.paymentFrequency}
                    handleChange={(event) => handleChangeValue('monthlyPrice','paymentFrequency', 'select', event)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Total number of payments"
                    number={true}
                    handleChange={(event) => handleChangeValue('monthlyPrice','totalNumOfPayments', 'text', event)}
                    value={monthlyPrice.totalNumOfPayments}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div>Amount per payment <strong>${monthlyAmountPerPayment}</strong></div>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                <CheckBox
                    label="Set this as most popular"
                    checked={monthlyPrice.mostPopular}
                    onChange={(event) => handleChangeValue('monthlyPrice','mostPopular', 'checkbox', event)}
                  />
                </Grid>

              </Grid>
            }
          />

        </Grid>

      </Grid>

    </div >
  );
}


export default PricingOption;

