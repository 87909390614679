import React, { useState } from "react";
import { Grid } from "@mui/material";
import TextField from "../../../../components/Form/TextField/TextField";
import PhoneInputField from "../../../../components/Form/PhoneInputField/PhoneInputField";
import MultipleSeletChip from "../../../../components/Form/MultipleSeletChip/MultipleSeletChip";
import TextArea from "../../../../components/Form/TextArea/TextArea";

const EditInformation = () => {
  const [role, setRole] = useState();
  const [bio, setBio] = useState("")
  const roles = [
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Instructor",
      value: "Instructor",
    },
  ];
  const handleChanges = (event) => {
    setBio(event.target.value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label="First Name"
            required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Middle Name"
            // required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Last Name"
            required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleSeletChip
            label="Select Role"
            validation={true}
            options={roles}
            selectedOption={role}
            // handleChange={() => handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Organization/Institution/Company Name"
            // required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Designation"
            // required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            label="Short bio"
            placeholder=""
            validation={false}
            value={bio}
            handleChange={handleChanges}
            helperText=""
            count={1000}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EditInformation;
