import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from '../../../../components/Form/TextField/TextField'
import SelectField from '../../../../components/Form/SelectField/SelectField'
import Datefield from '../../../../components/Form/Datefield/Datefield'

import FileUplaoder from '../../../../components/Form/FileUplaoder/FileUplaoder'
import { AttachEmail } from "@mui/icons-material";

function EditUser(props) {



  //==== radio button//
  const [radiovalue, radiosetValue] = React.useState('female');

  const handleChangeRadio = (event) => {
    radiosetValue(event.target.radiovalue);
  };
  //==== radio button End//




  // ==== Date Picker === //
  const [dateTime, dateSetValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChangeDate = (newValue) => {
    dateSetValue(newValue);
  };

  // ==== Date Picker end === //


  // ==== Level Value === //
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const selectValue = [
    {
      label: "Beginner level",
      value: "Beginner level",
    },
    {
      label: "Intermediate level",
      value: "Intermediate level",
    },
    {
      label: "Expert level",
      value: "Expert level",
    },

    {
      label: "All levels",
      value: "All levels",
    },

  ];
  // ==== Level Value end === //

  return (
    <div className="formSection">
      <div className="FormBody">

        <Grid container spacing={3}>


          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="First Name"
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Last Name"
              required={true}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <SelectField
              label="Country"
              validation={true}
              selectedOption={age}
              handleChange={handleChange}
              options={selectValue}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Phone Number"
              required={true}
            />
          </Grid>

    
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Address"
              required={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <FileUplaoder
              label="Upload Form"
              required={true}
              filePath="modules/resource-files/"
              uploadButton="Upload File"
              //file={tempResourse.resourseFile}
              fileType="*"
              //onUpload={(filePath) => updateResourseWithParemData("resourseFile", filePath)}
              helpertext="Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf."
            />
          </Grid>

        </Grid>

      </div>
    </div>
  );
}


export default EditUser;

