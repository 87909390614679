import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import ComingSoon from "../../../../components/ComingSoon/ComingSoon";
import VerticalTab from "../../../../components/VerticalTab/VerticalTab";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import Overview from "./overview/Overview";
import SecurityTab from "./security/Security";
import Courses from "./courses/Courses";
import Payments from "./payments/Payments";
import { Person, Receipt, Security } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import Certificate from "@mui/icons-material/EmojiEvents";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";

import Modals from "../../../../components/Form/Modals/Modals";
import EditUser from "../forms/EditUser";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import EditInformation from "../forms/EditInformation";
import DraggableDialog from "../../../../hooks/Dialog";

function TeamMemberDetails(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenEditInfoButton = () => {
    handleClose();
    handleOpenEditInfo();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabData = [
    {
      icon: <Person />,
      label: "Overview",
    },

    {
      icon: <InsertInvitationRoundedIcon />,
      label: "Calendar",
    },

    {
      icon: <MenuBookRoundedIcon />,
      label: "Courses",
    },

    {
      icon: <Receipt />,
      label: "Payments",
    },

    {
      icon: <Security />,
      label: "Security",
    },
  ];

  const tabBody = [
    {
      id: 0,
      panel: <Overview teamMemberData={teamMemberData} />,
    },
    {
      id: 1,
      panel: <ComingSoon title="Calendar" />, //<Payments />,
    },
    {
      id: 2,
      panel: <Courses />,
    },

    {
      id: 3,
      panel: <ComingSoon title="Payments" />,
    },

    {
      id: 4,
      panel: <SecurityTab />,
    },
  ];

  const [currentTab, setCurrentTab] = useState(0);
  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  };

  //  =========== edit info drawer ==========
  const [openEditInfo, setOpenEditInfo] = useState(false);

  const handleOpenEditInfo = () => {
    setOpenEditInfo(true);
  };

  const handleCloseEditInfo = () => {
    setOpenEditInfo(false);
  };
  //  ============ eidit info drawer =========

  //  ============ revoke pop up =============
  const [revokePopUp , setRevokePopUp] = useState(false)

  const handleOpenRevoke = () => {
    handleClose();
    setRevokePopUp(true)
  }

  const handleCloseRevoke = () => {
    setRevokePopUp(false)
  }
  //  ============ revoke pop up =============

  //  ============ delete pop up =============
  const [deletePopUp , setDeletePopUp] = useState(false)

  const handleOpenDelete = () => {
    handleClose();
    setDeletePopUp(true)
  }

  const handleCloseDelete = () => {
    setDeletePopUp(false)
  }
  //  ============ delete pop up =============

  //  ============ breadcrums ================
  const breadcrumbsData = [
    {
      label: "Team",
      link: "/team",
      icon: <GroupsRoundedIcon fontSize="small" />,
    },

    {
      label: "Details",
      link: "/team-details",
    },
  ];
  //  ============ breadcrums ================

  return (
    <div>
      <Breadcrumbs breadcrumbsData={breadcrumbsData} />
      <div className="UserDetails">
        <li>
          <div className="UserDetailsleftcol">
            <div className="UserDetailsrIcon">
              <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}>
                {" "}
                s
              </Avatar>
            </div>
            <div className="UserDetailsDetail">
              <div style={{display: 'flex', alignItems: 'center', gap:'5px'}}>
              <span style={{ display: "inline-block" }}>
                <h1>Sir Parsa 😎</h1>
              </span>{" "}
              <span
                style={{ display: "inline-block" }}
                className="status rounded success"
              >
                Active
              </span>
              </div>
              <p>sirparsa@gmail.com</p>
            </div>
          </div>

          <div className="EditUserProfile actionDoropdown">
            <Button
              className="ActionButton"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              Actions <KeyboardArrowDownRoundedIcon />
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleOpenEditInfoButton}>Edit Info</MenuItem>
              <MenuItem onClick={handleOpenRevoke}>Revoke Access</MenuItem>
              <MenuItem onClick={handleOpenDelete}>Delete Member</MenuItem>
            </Menu>
          </div>
        </li>

        {/* <div className="UserStatus">
          <div className="UserStatusCol">{totalRunningCourses} running courses</div>
          <div className="UserStatusCol">{totalCompletedCourses} Completed Courses</div>
          <div className="UserStatusCol">{totalCirtificates} Certificates</div>
        </div> */}
      </div>

      <VerticalTab
        //title="Introduction to Islamic Economics and Finance"
        //name="MUNA Academy"
        link="#"
        tabData={tabData}
        tabBody={tabBody}
        onTabSwitch={handleTabSwitch}
        //onClick={onClick()}
        //ButtonLabel="Start Class"
      />

      {/*====================== Edit new Form Modal ===================== */}
      <SideDrawer
        title="Edit Information"
        buttonLabel="Save"
        // buttonLink={}
        sideOpen="right"
        open={openEditInfo}
        handleDrawerClose={handleCloseEditInfo}
        FooterWithoutTab={true}
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        Checkbox={false}
        CheckboxLabel=""
        body={
          <>
            <EditInformation />
          </>
        }
      />
      {/*====================== Edit new Form Modal ===================== */}

      {/*====================== Revoke pop up  ========================== */}  
      <DraggableDialog
        title="Revoke access?"
        body="Are you sure you want to revoke access of this team member? User won't be able to access the portal anymore."
        openDialog={revokePopUp}
        handleCloseDialog={handleCloseRevoke}
        // handleConfirmCloseDialog={handleConfirmCloseSessionCompleteDialog}
        ModalFooter={true}
        actionButton="Revoke"
      />
      {/*====================== Revoke pop up  ========================== */}  

      {/*====================== Delete pop up  ========================== */}  
      <DraggableDialog
        title="Delete team member?"
        body="Are you sure you want to delete this team member? Deleting will permanently remove from everywhere."
        openDialog={deletePopUp}
        handleCloseDialog={handleCloseDelete}
        // handleConfirmCloseDialog={handleConfirmCloseSessionCompleteDialog}
        ModalFooter={true}
        actionButton="Delete"
      />
      {/*====================== Delete pop up  ========================== */}  
    </div>
  );
}

export default TeamMemberDetails;

const teamMemberData = {
  firstName: "sir",
  lastName: "parsa",
  role: "Admin",
  organization: "Southern Methodist University",
  designation: "Adjunct Professor of Islamic Studies",
  shortBio:
    "Imam Omar Suleiman is the Founder and President of the Yaqeen Institute for Islamic Research, and an Adjunct Professor... of Islamic Studies in the Graduate Liberal Studies Program at SMU (Southern Methodist University). He is also the Resident Scholar at Valley Ranch Islamic Center and Co-Chair Emeritus of Faith Forward Dallas at Thanks-Giving Square.",
};
