/**
 * authors: Rizwan Shah (Design), Ashrafuzzaman Sujan (Development)
 */

import React, { useState, useEffect } from "react";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';

import './StatusChip.css';

{/* <StatusChip Status="Paid"/> */}
{/* <StatusChip Status="Failed"/> */}
{/* <StatusChip Status="Canceled"/> */}
{/* <StatusChip Status="Refund"/> */}

function StatusChip(props) {
  const { Status } = props;


  return (
    <div className="StatusChip">
      <div className={Status}>
        <div className="StatusInner">
          <CheckCircleRoundedIcon className="icon-paid" />
          <WarningRoundedIcon  className="icon-faild"/> 
          <CancelRoundedIcon className="icon-canceled"/> 
          <UndoRoundedIcon className="icon-refund"/>  
          <span className="StatusChipLabel">{Status}</span>
        </div>
      </div>

    </div>
  );
}


export default StatusChip;

