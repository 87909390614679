/**
 * authors: Rizwan Shah (Design), Ashrafuzzaman Sujan (Development)
 */

import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import './AddTextField.css';

function AddTextField(props) {

  const { label, validation, placeholder, helperText, primaryField, textField, maxText, options, handleChange, addButtonLabel, minItem, maxItem} = props;


  const [inputList, setInputList] = useState(() => {
    if(options.length == 0){
      var obj = {};
      obj[primaryField] = 0;
      obj[textField] = "";
      return [obj];
    }
    return options;
  });

  /**
   * remove an item from input list and send the data to handleChange function
   * @param {*} event 
   * @param {*} index 
   */
  const handleRemove = (index, event) => {

    const list = [...inputList];

    list.splice(index, 1);
    // update state variable
    setInputList(list);
    // send input list to parent component
    handleChange(event, list);
    
  }

  /**
   * add new empty item
   */
  const handleaddclick = () => {
      var obj = {};
      obj[primaryField] = 0;
      obj[textField] = "";
    setInputList([...inputList, obj]);
  }

  /**
   * onChange handler
   * @param {*} index 
   * @returns 
   */
  const onChangeHandler = (index) => (event) => {

    const fieldData = event.target.value;
    const list = [...inputList];

    const currentListItem = list.findIndex( (element, objIndex, array) => {
      return (objIndex == index);
    });

    list[currentListItem][textField] = fieldData;
    // update state variable
    setInputList(list);
    // send input list to parent component
    handleChange(event, list);
  }


  return (
    <div className="TextField">
      <div className="FieldLabel">{label} {validation && <span>*</span>}</div>
      {
        inputList.map((obj, index) => {
          return (
            <>
              <div className="AddField">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // error={error}
                  placeholder={placeholder}
                  value={obj[textField]}
                  onChange={onChangeHandler(index)}
                  helperText={helperText}
                  inputProps={{
                    maxLength: maxText
                  }}
                />
                <span className="FieldCount">
                  {maxText ? (
                    <span style={{ color: "#B6B9B9", alignSelf: "flex-end", }}>
                      {"" + obj[textField]?.length + "/" + maxText}
                    </span>
                  ) : (
                    ""
                  )}
                </span>

                {
                  inputList.length > minItem &&
                  <Button className="RemoveField" variant="text" onClick={(event) => handleRemove(index, event)}><DeleteForeverRoundedIcon /> </Button>
                }

              </div>

              {
                inputList.length - 1 === index && inputList.length < maxItem && 
                <Button variant="text" onClick={handleaddclick} className="AddMoreButton"><AddRoundedIcon />{addButtonLabel}</Button>
              }

            </>
          );
        })}

    </div>
  );
}


export default AddTextField;

