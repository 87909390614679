import React, { useEffect, useState } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';


function Overview(props) {

  const {studentData} = props;

  function createData(label, data) {
    return { label, data };
  }

  const rows = [
    createData('Full Name', studentData.firstName ? <span>{studentData.firstName} {studentData.lastName}</span> : ""),
    createData('Gender', studentData.gender ? <span>{studentData.gender}</span> : ""),
    createData('Birthday', studentData.birthdate ? <span>{studentData.birthdate}</span> : ""),
    createData('Address', studentData.address ? <span>{studentData.address}</span> : ""),
    createData('Current Grade', studentData.currentGrade ? <span>{studentData.currentGrade}</span> : ""),
    createData('Age Group', studentData.ageGroup ? <span>{studentData.ageGroup}</span> : ""),
    createData('Timezone', studentData.timezone ? <span>{studentData.timezone}</span> : ""),
  ];

  return (
    <div className="TableContainer">
        <div className="TableInnerHeader">
          <h3>Personal Information</h3>
        </div>
        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="personal info">
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.label}
                  hover={true}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="left">{row.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>        
    </div>

  );
}


export default Overview;