import React, { useState, useEffect } from "react";
import VerticalTab from '../../../../components/VerticalTab/VerticalTab';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import Overview from './overview/Overview';
import Securityy from './security/Security';
import Courses from './courses/Courses';
import Payments from './payments/Payments';
import { Person, Receipt, Security } from "@mui/icons-material";
import Certificate from '@mui/icons-material/EmojiEvents';

import Buttons from '../../../../components/Form/Button/Button';
import Avatar from '@mui/material/Avatar';
import { deepPurple, } from '@mui/material/colors';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import Button from '@mui/material/Button';

function InstructorDetails(props) {

  const tabData = [
    {
      icon: <Person />,
      label: "Overview",
    },

    {
      icon: <Receipt />,
      label: "Payment",
    },

    {
      icon: <MenuBookRoundedIcon />,
      label: "Courses",
    },

    {
      icon: < Certificate />,
      label: "Certificates",
    },



    {
      icon: <Security />,
      label: "Security",
    },



  ]

  const tabBody = [

    {
      id: 0,
      panel: <Overview />,
    },
    {
      id: 1,
      panel: <Payments />,
    },
    {
      id: 2,
      panel: <Courses />,
    },

    {
      id: 3,
      panel: "certificates",
    },

    {
      id: 4,
      panel: <Securityy />,
    },

  ]


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [currentTab, setCurrentTab] = useState(0);
  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  }


  return (
    <div className="">

      <div className="UserDetails">
        <li>
          <div className="UserDetailsleftcol">
            <div className="UserDetailsrIcon">
              <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} ></Avatar>
            </div>

            <div className="UserDetailsDetail">
              <span style={{ display: "inline-block" }}><h4>Abdur Rahman</h4></span>  <span style={{ display: "inline-block" }} className="status rounded success">Active</span>
              <p>abdurrehman@gmail.com</p>
            </div>


          </div>

          <div className="UserListingAction">
            <div align="left">


              <div className="EditUserProfile">
                <div className="ActionButton">
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  Actions <KeyboardArrowDownRoundedIcon />
                </Button>
                </div>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleClose}>Revoke Access</MenuItem>
                  <MenuItem onClick={handleClose}>Delete Customer</MenuItem>
                </Menu>
              </div>


            </div>

          </div>



        </li>


        <div className="UserStatus">
          <div className="UserStatusCol">10 courses</div>
          <div className="UserStatusCol">500 Students</div>
          <div className="UserStatusCol">3 instructors</div>

        </div>



      </div>

      <VerticalTab
        //title="Introduction to Islamic Economics and Finance"
        //name="MUNA Academy"
        link="#"
        tabData={tabData}
        tabBody={tabBody}
        //onClick={onClick()}
        onTabSwitch={handleTabSwitch}
      //ButtonLabel="Start Class"
      />

    </div>
  );
}


export default InstructorDetails;

