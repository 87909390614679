import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from '../../../../../components/Form/TextField/TextField'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CircularProgress from '@mui/material/CircularProgress';



function BillingDetails(props) {

  return (
    <>

      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection Section">
            <div className="MdTitle">Billing Details</div>

            <Grid container spacing={3}>

              <Grid item xs={12} sm={12} md={12}>
                <div className="LargeText AvailaleContainer">
                  <TextField
                    label="Account ID"
                    required={true}
                  />
                  <a className="AvailableButton IfLoading">Available? <CircularProgress className="LoadingBar"/></a>
                  <div className="AvailVerify Yes"><CheckCircleRoundedIcon /> Available</div>
                  <div className="AvailVerify No"><CancelRoundedIcon /> Not Available</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    label="Email Address"
                    required={true}
                    helperText="An invite will be sent to this email and the customer will use this email to login."
                  />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div className="AvailaleContainer">
                  <TextField
                    label="Page URL"
                    required={true}
                    helperText="Page URL should be unique."
                    placeholder="https://deen360.com/"
                  />
                  <a className="AvailableButton" style={{ top: "41px" }}>Available?</a>
                  <div className="AvailVerify Yes"><CheckCircleRoundedIcon /> Available</div>
                  <div className="AvailVerify No"><CancelRoundedIcon /> Not Available</div>
                  
                </div>
              </Grid>

            </Grid>

          </div>
        </Grid>
      </Grid>





    </>
  );
}


export default BillingDetails;

