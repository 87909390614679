import React, { useState, useRef, useEffect } from 'react';
import SideDrawer from '../SideDrawer/SideDrawer';
import CustomizedSnackbar from '../../hooks/Snackbar';
import UploadFromDevice from './UploadFromDevice';
import UploadFromYoutube from './UploadFromYoutube';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SimpleBackdrop from '../Form/Backdrop/SimpleBackdrop';
import axios from 'axios';
import {onFileUpload } from './Functions'


import './MediaLibrary.css';

function Externaluse(props){
    const childRef = useRef(null);

    //base url for api taken from .env file 
    const baseUrl = process.env.REACT_APP_PROJECT_ENV == "dev" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

    const userToken = localStorage.getItem('userToken');
    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
    
    const [orgId, setOrgId] = useState(localStorage.getItem('orgId'));


    const [uploadButtonText, setUploadButtonText] = useState("Upload");
    const [openNewMedia, setOpenNewMedia] = useState(props.open);
    const [videoName, setVideoName] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [imageName, setImageName] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarType, setSnackbarType] = useState("success");
    const [checkboxClicked, setCheckboxClicked] = useState(false);
    const [videoUpload, setVideoUpload] = useState(false);
    const [addNewItem, setAddNewItem] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const [imageNameError, setImageNameError] = useState(false);
    const [isfileSelected, setFileSelected] = useState(false);
    const [fileUploadError, setFileUploadError] = useState(false);

    const [imaneNameErrorMessage, setImageNameErrorMessage] = useState("");

    const fileSelected = (status) => {
    
        setFileSelected(status);
    }

    const tabData = [
        {
          lebel: "Upload from device",
          icon: <InsertDriveFileIcon />,
        },
    
        {
          lebel: "YouTube video",
          icon: <YouTubeIcon />,
        },
    ]

    const handleTabChange = (event) => {
        if (event == 1) {
            setUploadButtonText("Import from YouTube");
            setVideoUpload(true);
        } else {
            setUploadButtonText("Upload Image");
            setVideoUpload(false);
        }
    };
    const handleCheckboxClicked = (event) => {
        console.log(event.target.checked);
        setAddNewItem(event.target.checked);
    };
    const handleSnackbarOpen = (message, type) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(type);
    };
    const handleSnackbarClose = () => {
        setShowSnackbar(false);
    };
    const handleDrawerOpenNewMedia = () => {
        setOpenNewMedia(true);
        };
    const handleDrawerCloseNewMedia = () => {
        setOpenNewMedia(false);
        props.handleCloseButton();
    }

    const clickSaveButton = async () => {
        console.log("videoUpload", videoUpload  )

        if (videoUpload) {
            if (videoName == "") {
                handleSnackbarOpen("Please enter video name!", "error");
            } else if (videoUrl == "") {
                handleSnackbarOpen("Please enter video url", "error");
            } else {
                // save data
                    // console.log("save data");
                    // console.log(videoName);
                    setOpenBackdrop(true);
                    //call to api via axios 
                    const response = await axios.post(baseUrl + '/videoUpload', {
                        name: videoName,
                        orgId: orgId,
                        url: videoUrl
                    });

                    console.log(response)

                    setOpenBackdrop(false)
                    //if response status is 200
                    if (response.data.message == 'success') {
                        console.log("response", response);
                        setVideoName("");
                        setVideoUrl("");

                        //if addNewitem is true then clear the form
                        if (addNewItem) {
                            
                            setShowSnackbar(true);
                            setSnackbarMessage("Video uploaded successfully.");
                            setSnackbarSeverity("success");
                        } else {
                            handleSnackbarOpen("Video uploaded successfully.", "success");
                        }
                        //set total items to empty 
                        // setTotalItems([]);
                        //close the drawer
                        handleDrawerCloseNewMedia();

                        //open the drawer
                        props.handleOpenNewMedia( true )
                    } else {
                    handleSnackbarOpen("Something went wrong.", "error");
                    }

                handleSnackbarOpen("Video uploaded successfully", "success");
                setVideoName("");
                setVideoUrl("");
            }
        }else{
            //check if imageName is given 
            console.log("imageName", imageName);
            if(imageName === ""){
                setImageNameError(true);
                setImageNameErrorMessage("Please enter media name");
                setOpenBackdrop(false);
                return false;
              }
              if( isfileSelected === false){
                setFileUploadError(true);
                setOpenBackdrop(false);
                return false ; 
              }
              if(childRef.current){
                childRef.current.uploadFile();
              }
        }
    }

    const handlePostUploadAction = () => {
        handleDrawerCloseNewMedia();

        //open the drawer
        props.handleOpenNewMedia( true );

        //set imageName empty 
        setImageName("");
    }

    //loadingIconStatusChange
    const loadingIconStatusChange = (status) => {
        setOpenBackdrop(status);
    }

    //function to process fileupload 
  const processFileUpload = async (filePath, fileName, fileSize, fileType, width, height) => {
    setOpenBackdrop(true);
    const response = await onFileUpload(imageName, filePath, fileName, fileSize, fileType, width, height);
    
    if(response){
      //show snackbar success message 
      let message = "File uploaded successfully.";
      let type = "success" ;

      setShowSnackbar(true);
      setSnackbarMessage(message);
      setSnackbarSeverity(type);

      //set total items to empty
    //   setTotalItems([]);
      //set opennewmedia to false

      setImageName("");

      if(addNewItem){
        
        if (childRef.current) {
          childRef.current.clickButton();
        }
      }else{
        
        // setOpen(false);
        setOpenNewMedia(false);
        handleDrawerCloseNewMedia();

        //open the drawer
        props.handleOpenNewMedia( true )
      }
      
    }else{
      handleSnackbarOpen("Something went wrong.", "error");
    }
    setOpenBackdrop(false);
  }
    
    const panelTab = [
        {
          id: 0,
          panleBody: <UploadFromDevice
            handleTabChange={handleTabChange}
            handleSnackbarOpen={handleSnackbarOpen}
            setVideoName={setVideoName}
            setVideoUrl={setVideoUrl}
            setImageName={setImageName}            
            imageName={imageName}
            postUploadAction={handlePostUploadAction}
            loadingIconStatusChange={loadingIconStatusChange}
            fileSelected={fileSelected}
            showFileUploadError={fileUploadError}
            imageErrorMessage={imaneNameErrorMessage}
            showImageNameError={imageNameError}
            onFileUpload={processFileUpload}
            ref={childRef}
          />,
    
          SaveButtonLabel: uploadButtonText,
          clickSaveButton:clickSaveButton,
          CancelButtonLabel: "Cancel",
    
          Checkbox: false,
          CheckboxLabel: "Add another after saving",
          //onClickCheckbox:onClickCheckbox,
    
          DeleteButton: false,
          DeleteLebel: "Delete Permanently",
          onClickCheckbox: handleCheckboxClicked,
          handleTabChange : handleTabChange,    
          //onClickDelete:onClickDelete,
        },
    
        {
          id: 1,
          panleBody: <UploadFromYoutube 
            setVideoName= {setVideoName} setVideoUrl= {setVideoUrl} 
            handleSnackbarOpen={handleSnackbarOpen} 
            onCheckboxClicked={handleCheckboxClicked}
            videoName={videoName} videoUrl={videoUrl}
             />,
          SaveButtonLabel: "Import From YouTube",
          clickSaveButton: clickSaveButton, 
          cancelButtonLabel: "Cancel",
          videoUrl: videoUrl,
          videoName: videoName,
          CancelButtonLabel: "Cancel", 
          handleTabChange : handleTabChange,    
          Checkbox: false,
          CheckboxLabel: "Add another after saving",
          onClickCheckbox: handleCheckboxClicked,
        },
    
    ];
    //closing the loading icon
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };

    return(
        <>
            <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
            <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
            <SideDrawer
                title="Upload New Media"
                ButtonLabel={uploadButtonText}
                clickSaveButton={clickSaveButton}
                cancelButtonLabel="Cancel"
                sideOpen="right"
                open={props.open}
                handleDrawerClose={handleDrawerCloseNewMedia}
                // CheckboxLabel="Add another after saving"
                Checkbox={true}
                onCheckboxClicked={handleCheckboxClicked}
                FooterWithoutTab={false}
                handleTabChange={handleTabChange}
                //=== Tabs Codes ===//
                TabsStatus={true}
                TabsPannelStatus={true}
                panelTab={panelTab}
                tabData={tabData}
            />
        </>
    )
}

export default Externaluse;