import React, { useState } from "react";

import CourseModule from '../../../../../components/CourseModule/CourseModule'

import Grid from '@mui/material/Grid';
import Buttons from '../../../../../components/Form/Button/Button';
import TextArea from '../../../../../components/Form/TextArea/TextArea'
import TextField from '../../../../../components/Form/TextField/TextField'
import Modals from '../../../../../components/Form/Modals/Modals';

function CourseMaterial(props) {

  const {courseMaterial, changeSessionStatus, startStopSessionHandler} = props;

  const [selectedSession, setSelectedSession] = useState([]);
  const [openZoom, setOpenZoom] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [sessionLink, setSessionLink] = useState("");

  const handleCloseModalZoom = () => {
    console.log('close')
    setOpenZoom(false)
  };

  const handleConfirmZoom = () => {
    console.log('close')
    setOpenZoom(false);
  };

  const showZoomModal = (sessionData) => {
    console.log('open');
    setSelectedSession(sessionData);
    setSessionLink(selectedSession.zoomLink);
    setOpenZoom(true);    
  }

  // do the text copy operation
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call sessionLinkToClipboard
    copyTextToClipboard(sessionLink)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>

      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} md={12}>

          <div className="AccrodionThreeStep">
          {courseMaterial.map((courseModuleData, index) => {
            return <CourseModule courseModuleData={courseModuleData} index={index} showZoomModal={showZoomModal} orgPortal={true} changeSessionStatus={changeSessionStatus} startStopSessionHandler={startStopSessionHandler} />
          })}
          </div>

        </Grid>

      </Grid>

      {/* Zoom Details Modal*/}

      <Modals
        ModalTitle="Zoom Details"
        handleClose={handleCloseModalZoom}
        handleConfirm={() => handleConfirmZoom()}
        open={openZoom}
        ButtonClose="Cancel"
        ButtonConfirm="Add Now"
        width="552px"

        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"

        ModalBody={
          <div>

            <Grid container spacing={3}>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Zoom Link"
                  // count={60}
                  value={selectedSession.zoomLink}
                  // onChange={handleChanges("name")}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Buttons
                  label={isCopied ? 'Copied!' : 'Copy Link'}
                  buttonColor="#004FE0"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  onClick={handleCopyClick} 
                />

              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextArea
                  label="Details"
                  count={1500}
                  value={selectedSession.details}
                  // onChange={handleChanges("name")}
                  disabled={true}
                />

              </Grid>

            </Grid>

          </div>
        }

      />
      {/* Zoom Details Modal*/}
    </>
  );
}


export default CourseMaterial;