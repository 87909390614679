import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import {getUserData, getUserTimezone} from '../../../hooks/HelperFunctions';

import AlrtMessage from '../../../components/Form/AlrtMessage/AlertMessage';
import Table from '../../../components/Table/Table';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import Modals from '../../../components/Form/Modals/Modals';

import AddNewUser from './forms/AddNewUser';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddOneByOne from "./forms/AddOneByOne";
import AddRoundedIcon from '@mui/icons-material/AddRounded';


function UserListing(props) {

  const [studentList, setStudentList] = useState([]);
  const [queryString, setQueryString] = useState("");

  const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
  const orgId = localStorage.getItem('orgId');
  const timezone = getUserTimezone();

  // const onClick = () => {
  //   alert("onclick working fine!");
  // }

  // fetching course material data
  const getStudentList = (searchText) => {
    
    axios.get(`${baseUrl}/orgPortal/getStudents?orgId=${orgId}&timezone=${timezone}&queryString=${searchText? searchText: ""}`).then((result) => {
      console.log(result.data.body);
      setStudentList(result.data.body);
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    getStudentList();
  }, []);

  useEffect(() => {
    getStudentList(queryString);
  }, [queryString]);

  const handleSearchText = (event) => {
    const fieldData = event.target.value;
    console.log(fieldData);
    setQueryString(fieldData);
    // getStudentList();
  };

  const handleSearch = () => {
    // console.log(queryString);
    setQueryString('');
    // setQueryString("");
    // getStudentList();
  }


  const headCells = [

    { id: 'Name', numeric: true, disablePadding: false, label: 'Name', width: "20%" },
    { id: 'Email', numeric: true, disablePadding: false, label: 'Email', width: "25%" },
    { id: 'courses', numeric: true, disablePadding: false, label: 'Courses', width: "10%" },
    // { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: "10%" },
    { id: 'Joined', numeric: true, disablePadding: false, label: 'Joined', width: "25%" },
    { id: 'Balance', numeric: true, disablePadding: false, label: 'Balance', width: "10%" },
    { id: 'Action', numeric: true, disablePadding: false, label: '', width: "10%" },

  ];


  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "All",
      value: "All",
    },

    {
      label: "Twentydd",
      value: "Twentyd",
    },

  ]

{/*==== Add new drawer */}
  const [OpenAddNewUser, setOpenNewUser] = useState(false);
  const handleOpenAddNewUser = () => {
    setOpenNewUser(true);
  };
  const CloseAddNewUser = () => {
    console.log('close')
    setOpenNewUser(false)
  };
  {/*==== Add new drawer */}

 

  return (

    <>

      <div className="TableSection">
        <Table
          //title="Users"
          // AddButton={false}
          // addLabel="New Users"
          linkInnerButton=""
          showSearchBox={true}
          showSearchCount={true}
          searchBoxPlaceholder="Search Students"
          searchBoxText={queryString}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          headCells={headCells}
          rows={studentList}
          // AddButton={true}
          tableHeader={true}
          Checkbox={false}
          footer={true}
          //onClick={() => handleOpenAddNewUser(true)}
          //onClick={() => handleOpenEditUser(true)}

        // SelectField={true}
        // handleChanges={handleChanges}
        // options={options}


        innerHeader={true}
        AddButtonInner={true}
        addLabelInner="New Users"
        InnerButtonColor="#004FE0"
        InnerButtonTextColor="#fff"
        InnderButtonIcon={<AddRoundedIcon />}
        onClickInnerButton={() => handleOpenAddNewUser(true)}
        />
      </div>


      {/* ============== new user drawer start ==================== */}
      <SideDrawer
      title="Add New User"
      buttonLabel="Save"
      // buttonLink={}
      sideOpen="right"
      open={OpenAddNewUser}
      handleDrawerClose={CloseAddNewUser}
      FooterWithoutTab={true}
      ButtonLabel="Save"
      cancelButtonLabel="Cancel"
      Checkbox={true}
      CheckboxLabel="Add another after saving"
      body={
        <>
          <AddOneByOne/>
        </>
      }
      />
      {/* ============== new user drawer start ==================== */}
      

    </>

  );
}


export default UserListing;