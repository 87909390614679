import React, { useState } from "react";
import dateFormat, { masks } from "dateformat";
import {dateFormatter} from '../../hooks/HelperFunctions'

import Grid from '@mui/material/Grid';
import Buttons from '../../components/Form/Button/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import Tooltip from '../../components/Form/Tooltip/Tooltip';
import DraggableDialog from '../../hooks/Dialog';

function CourseSession(props) {

    const { modIndex, courseSessions, sessionCompleteCounter, totalSession, showZoomModal, orgPortal, changeSessionStatus, startStopSessionHandler } = props;

    const [sessionExpanded, setSessionExpanded] = React.useState('');

    const [timezone, setTimezone] = useState(() => {
        const timezone = localStorage.getItem('course_timezone');
        return timezone;
    });

    const handleChangeSessionPannel = (panel) => (event, isExpanded) => {
        setSessionExpanded(isExpanded ? panel : false);
    };

    const [sessionChildExpanded, setSessionChildExpanded] = React.useState('');

    const handleChangeSessionChildPannel = (panel) => (event, isExpanded) => {
        setSessionChildExpanded(isExpanded ? panel : false);
    };

    //=== Dialog start===
    const [markCompletedSessionData, setMarkCompletedSessionData] = useState([]);
    const [openSessionCompleteDialog, setOpenSessionCompleteDialog] = useState(false);

    const handleOpenSessionCompleteDialog = (sessionData) => {
        setMarkCompletedSessionData(sessionData);        
        setOpenSessionCompleteDialog(true);
    };

    const handleCloseSessionCompleteDialog = () => {
        console.log('close')
        setOpenSessionCompleteDialog(false)
    };

    const handleConfirmCloseSessionCompleteDialog = () => {
        console.log('con close');
        setOpenSessionCompleteDialog(false);
        changeSessionStatus(markCompletedSessionData.sesId, markCompletedSessionData.sessionStatus);
    };
    //=== Dialog end===

    return (
        <Accordion expanded={sessionExpanded === `session-${modIndex}`} onChange={handleChangeSessionPannel(`session-${modIndex}`)}>
            <AccordionSummary
                expandIcon={<ArrowForwardRoundedIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
            >
                <div className="accTitleSection">
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Sessions
                    </Typography>
                    <div className="SessionStatus">Completed {sessionCompleteCounter}/{totalSession}</div>
                </div>
            </AccordionSummary>

            <AccordionDetails>
                <div className="AccThreeStep">
                    {courseSessions.map((sessionData, index) => {
                        return (
                            <Accordion expanded={sessionChildExpanded === `session-child-${modIndex}${index}` || sessionData.sessionStatus === 'Upcoming' ? true : false} onChange={handleChangeSessionChildPannel(`session-child-${modIndex}${index}`)} disabled={sessionData.sessionStatus == "Lock" && orgPortal == false ? "disabled" : ""}>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowDownRoundedIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <div className="accTitleSection">
                                        <div className="accTitleSectionIcon">
                                            {sessionData.sessionStatus == "Lock" ?
                                                <Tooltip
                                                    TooltipText="Locked"
                                                    Tooltip={<LockOutlinedIcon />}
                                                />
                                                :
                                                <CheckCircleRoundedIcon className={sessionData.sessionStatus} />
                                            }
                                            <div>
                                                <h4>{sessionData.title}</h4>
                                                {/* <p>{sessionData.startDate}  {sessionData.startTime} to {sessionData.endTime}</p> */}
                                                {/* <p>{dateFormat(sessionData.startDate, "ddd, mmm dS, yyyy Z", true)} <FiberManualRecordRoundedIcon className="SmallDot" /> {dateFormat(sessionData.startTime, "h:MM TT", true)} to {dateFormat(sessionData.endTime, "h:MM TT", true)}</p> */}
                                                <p>{dateFormatter(sessionData.startDate, timezone, "ddd, MMM Do, YYYY z")} <FiberManualRecordRoundedIcon className="SmallDot" /> {dateFormatter(sessionData.startTime, timezone, "hh:mm a")} to {dateFormatter(sessionData.endTime, timezone, "hh:mm a")}</p>
                                            </div>
                                        </div>
                                        <span className={`SesstionStatus status rounded ${sessionData.cssClass}`}>{sessionData.sessionStatus}</span>
                                    </div>
                                </AccordionSummary>

                                <AccordionDetails>

                                    <div className="ScardDetails">
                                        <p>{sessionData.details}</p>

                                        {sessionData.sessionStatus == "Upcoming" &&
                                            <Grid container spacing={3}>

                                                {orgPortal ? 
                                                <Grid item xs={12} sm={8} md={8}>                                                    
                                                    <Buttons
                                                        label={sessionData.classStarted == 0 ? "Start Session" : "Stop Session"}
                                                        buttonColor="#004FE0"
                                                        color="#fff"
                                                        width="100%"
                                                        height="48px"
                                                        fontSize="14px"
                                                        onClick={startStopSessionHandler}
                                                    />
                                                    
                                                </Grid> 
                                                :
                                                <Grid item xs={12} sm={8} md={8}>
                                                    {/* <Link href={sessionData.zoomLink} target='_blank' underline="none" rel="noopener">
                                                    <Buttons
                                                        label="Join Session"
                                                        buttonColor="#004FE0"
                                                        color="#fff"
                                                        width="100%"
                                                        height="48px"
                                                        fontSize="14px"
                                                    />
                                                    </Link> */}
                                                </Grid>
                                                }

                                                {/* <Grid item xs={12} sm={4} md={4}>
                                                    <Buttons
                                                        label="How to Join"
                                                        buttonColor="#fff"
                                                        border="#ccc 1px solid"
                                                        color="#004FE0"
                                                        width="100%"
                                                        height="48px"
                                                        fontSize="14px"
                                                        //link=""
                                                        onClick={() => showZoomModal(sessionData)}
                                                    />
                                                </Grid> */}

                                            </Grid>}

                                        <Grid container>
                                            {orgPortal &&
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <div className="MarkCheck">
                                                    <FormControlLabel control={<Checkbox
                                                        checked={sessionData.sessionStatus == "Completed" ? true : false}
                                                        onChange={() => handleOpenSessionCompleteDialog(sessionData) }
                                                        inputProps={{ 'aria-label': 'controlled' }} />}
                                                        label={sessionData.sessionStatus == "Completed" ? "Mark Uncompleted" : "Mark Completed"}
                                                        // onClick={() => }
                                                    /> 
                                                    </div>
                                                    {/* <a className="MarkCompleted" onClick={() => changeSessionStatus(sessionData.sesId, sessionData.sessionStatus)} >
                                                        {sessionData.sessionStatus == "Completed" ? <CheckCircleIcon /> : <FiberManualRecordRoundedIcon />}                                                        
                                                        <span>{sessionData.sessionStatus == "Completed" ? "Completed" : "Mark Completed"}</span>
                                                    </a> */}

                                                    <DraggableDialog 
                                                        title= {markCompletedSessionData.sessionStatus !== "Completed"? "Mark completed?" : "Mark Uncompleted?"}
                                                        body={markCompletedSessionData.sessionStatus !== "Completed"? "Are you sure you want to mark this session as completed? Learners will see this session as completed." : "Are you sure you want to mark this session as uncompleted? Learners will see this session as uncompleted."}
                                                        openDialog={openSessionCompleteDialog}
                                                        handleCloseDialog={handleCloseSessionCompleteDialog}
                                                        handleConfirmCloseDialog={handleConfirmCloseSessionCompleteDialog}
                                                        ModalFooter={true}
                                                        actionButton={markCompletedSessionData.sessionStatus !== "Completed"? "Mark Completed" : "Mark Uncompleted"}
                                                    />
                                                </Grid>}
                                        </Grid>

                                    </div>

                                </AccordionDetails>

                            </Accordion>
                        )
                    })}

                </div>

            </AccordionDetails>

        </Accordion>
    );
}

export default CourseSession;