import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  getUserData,
  getUserTimezone,
} from "../../../../../hooks/HelperFunctions";

import Table from "../../../../../components/Table/Table";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import Profile from "./Profile";
import EnrollLearners from "./EnrollLearners";
import SimpleBackdrop from "../../../../../components/Form/Backdrop/SimpleBackdrop";
import DraggableDialog from "../../../../../hooks/Dialog";
import AlrtMessage from "../../../../../components/Form/AlrtMessage/AlertMessage";
import AddOneByOne from "../../../users/forms/AddOneByOne";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CustomizedSnackbar from "../../../../../hooks/Snackbar";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ExportFilter from "../../../../../components/ExportFilter/ExportFilter";

function StudentsListing(props) {
  const apiBaseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const orgId = localStorage.getItem("orgId");
  const timezone = getUserTimezone();

  const initailObjectFilter = {
    format : '',
    dateRange : '',
    users : [],
    stream : [],
    payment : [],
    gender : [],
    status : []
  }

  const { studentList, courseId, getStudentList,getExportStudentInfo } = props;

  const [filterInfo, setFilterInfo] = useState(initailObjectFilter)
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [courseData, setCourseData] = useState([]);

  const [studentStatusDataArray, setStudentStatusDataArray] = useState([]);

  const [queryString, setQueryString] = useState("");

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openEnroll, setOpenEnroll] = useState(false);
  const handleDrawerOpenEnroll = () => {
    setOpenEnroll(true);
  };
  const handleDrawerCloseEnroll = () => {
    setOpenEnroll(false);
  };

  const [OpenAddNewUser, setOpenAddNewUser] = useState(false);
  const handleButtonLeftOpen = () => {
    setOpenAddNewUser(true);
  };
  const CloseAddNewUser = () => {
    setOpenAddNewUser(false);
  };

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const capitalizeFirstChar = (str) =>
    `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

  const handleOpenDialog = (status) => {
    console.log(status);
    const statusFull = capitalizeFirstChar(status);
    if (status == "blocked" || status == "dropped") {
      setDialogTitle(`Change status to "${statusFull}"?`);
      setDialogBody(
        "Are you sure, you want to change status for this student? The student won't be able to access this course anymore."
      );
    } else {
      setDialogTitle(`Change status to "${statusFull}"?`);
      setDialogBody(
        "Are you sure, you want to change status for this student? The student will be able to access this course again."
      );
    }

    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    getStudentData(studentStatusDataArray.userId);
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const getStudentData = (userId) => {
    setOpenBackdrop(true);
    axios
      .get(
        `${apiBaseUrl}/studentPortal/getStudentData?orgId=${orgId}&userId=${userId}&courseId=${courseId}&timezone=${timezone}`
      )
      .then((result) => {
        console.log(result.data.body);
        setStudentData(result.data.body.studentDetails);
        setPaymentData(result.data.paymentData);
        setCourseData(result.data.courseData);
        setOpenBackdrop(false);
        handleDrawerOpen();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStudentStatus = (userId, status) => {
    const dataArray = { userId: userId, courseId: courseId, status: status };
    // show confirm message
    handleOpenDialog(status);
    setStudentStatusDataArray(dataArray);
  };

  const showDataPostMessage = (response, dataArray) => {
    setOpenBackdrop(false);
    console.log(response, dataArray);
    if (response.error) {
      setOpenDialog(false);
      setAlertSeverity("error");
      setAlertMessage(response.message);
    } else {
      const statusFull = capitalizeFirstChar(dataArray.status);
      setAlertSeverity("success");
      setAlertMessage(`Status changed to "${statusFull}"`);
      setAlertOpen(true);
      getStudentList(queryString);
    }
  };

  const handleConfirmCloseDialog = () => {
    setOpenBackdrop(true);
    handleCloseDialog();
    axios
      .post(
        `${apiBaseUrl}/studentPortal/changeStudentStatus`,
        studentStatusDataArray
      )
      .then((response) =>
        showDataPostMessage(response, studentStatusDataArray)
      );
  };

  const handleSearchText = (event) => {
    const fieldData = event.target.value;
    console.log(fieldData);
    setQueryString(fieldData);
    getStudentList(fieldData);
  };

  const handleSearch = () => {
    console.log(queryString);
    setQueryString("");
    getStudentList("");
  };

  const headCells = [
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Name",
      width: "20%",
    },
    {
      id: "enrolled",
      numeric: true,
      disablePadding: false,
      label: "Enrolled",
      width: "40%",
    },
    {
      id: "stream",
      numeric: true,
      disablePadding: false,
      label: "Stream",
      width: "15%",
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "15%",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
      width: "10%",
      align: "right",
    },
  ];

  // const rows = [
  //   {
  //     cell: [
  //       { textBold: "Emma Smith", subtitle: "History of Islamic Finance", subtitle:true, },
  //       { date: "Dec 13, 2022", },
  //       { Status: true, background: "#EDEDEF", color: "#676C7B", label: "Online",  },
  //       { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Active", },
  //       { Button: true, buttonLabel: "Details", buttonTextcolor: "#004FE0", onClick: () => getStudentData(1), align:"right", padding:"0"},

  //     ],
  //   },

  //   {
  //     cell: [
  //       { textBold: "Emma Smith", subtitle: "History of Islamic Finance", subtitle:true, },
  //       { date: "Dec 13, 2022", },
  //       { Status: true, background: "#EDEDEF", color: "#676C7B", label: "Online",  },
  //       { Status: true, background: "#FFF5E6", color: "#FFAE35", label: "Dropped", },
  //       { Button: true, buttonLabel: "Details", buttonTextcolor: "#004FE0", onClick:handleDrawerOpen, align:"right", padding:"0"},

  //     ],
  //   },

  //   {
  //     cell: [
  //       { textBold: "Emma Smith", subtitle: "History of Islamic Finance", subtitle:true, },
  //       { date: "Dec 13, 2022", },
  //       { Status: true, background: "#EDEDEF", color: "#676C7B", label: "Online",  },
  //       { Status: true, background: "#FCE7E9", color: "#E63946", label: "Blocked", },
  //       { Button: true, buttonLabel: "Details", buttonTextcolor: "#004FE0", onClick:handleDrawerOpen, align:"right", padding:"0"}

  //     ],
  //   },

  // ];

  // Export Filter //
  const [openExport, setOpenExport] = useState(false);
  const handleDrawerOpenExport = () => {
    setOpenExport(true);
  };
  const handleDrawerCloseExport = () => {
    setOpenExport(false);
  };



  const exportStudentHandler = () => {
    getExportStudentInfo(filterInfo)
  }

  useEffect(() => {
    console.log('filterInfo : ', filterInfo)
  },[filterInfo])
  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <DraggableDialog
        openDialog={openDialog}
        title={dialogTitle}
        body={dialogBody}
        actionButton="Confirm"
        ModalFooter={true}
        handleCloseDialog={handleCloseDialog}
        handleConfirmCloseDialog={handleConfirmCloseDialog}
      />
      <CustomizedSnackbar
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleSnackbarClose}
      />

      <div className="TableSection">
        <Table
          title="Students"
          tableHeader={true}
          innerHeader={true}
          headCells={headCells}
          rows={studentList}
          btnHandleClick={getStudentData}
          // rows={rows}
          AddButton={true}
          addLabel="Enroll Learners"
          onClick={handleDrawerOpenEnroll}
          Checkbox={false}
          footer={true}
          showSearchBox={true}
          searchBoxText={queryString}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          FilterButton={false}
          AddButtonInner={true}
          addLabelInner="Export"
          InnerButtonColor="#E0EAFB"
          InnerButtonTextColor="#004FE0"
          InnderButtonIcon={<FileUploadOutlinedIcon />}
          onClickInnerButton={handleDrawerOpenExport}
        />
      </div>



      {/* Enroll Learners */}
      <SideDrawer
        title="Enroll Learners"
        buttonLabel="Enroll 10 Students"
        buttonLink={`/student-details/${studentData.stuId}`}
        sideOpen="right"
        open={openEnroll}
        handleDrawerClose={handleDrawerCloseEnroll}
        LeftButton={true}
        ButtonLeftIcon={<AddRoundedIcon />}
        ButtonLeft="New User"
        clickButtonLeft={handleButtonLeftOpen}
        FooterWithoutTab={true}
        ButtonLabel="Enroll 10 Learners"
        cancelButtonLabel="Cancel"
        body={<EnrollLearners />}
      />

      {/* new user drawer */}
      <SideDrawer
        title="Add New User"
        buttonLabel="Save"
        // buttonLink={}
        sideOpen="right"
        open={OpenAddNewUser}
        handleDrawerClose={CloseAddNewUser}
        FooterWithoutTab={true}
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        Checkbox={true}
        CheckboxLabel="Add another after saving"
        body={
          <>
            <AddOneByOne />
          </>
        }
      />













      {/* <SideDrawer
        title="Student Details"
        buttonLabel="View More Details"
        buttonLink={`/student-details/${studentData.stuId}`}
        sideOpen="right"
        open={open}
        handleDrawerClose={handleDrawerClose}
        body={
          <Profile
            studentData={studentData}
            courseData={courseData}
            changeStudentStatus={changeStudentStatus}
          />
        }
      /> */}

      <SideDrawer
        title="Export Students"
        ButtonLabel="Export"
        cancelButtonLabel="Cancel"
        //buttonLink={`/student-details/${studentData.stuId}`}
        sideOpen="right"
        open={openExport}
        handleDrawerClose={handleDrawerCloseExport}
        FooterWithoutTab={true}
        body={<ExportFilter 
        filterInfo={filterInfo}
        setFilterInfo={setFilterInfo}
        />}
        clickSaveButton={(e) => exportStudentHandler()}
      />

      <SideDrawer
        title="Student Details"
        buttonLabel="View More Details"
        sideOpen="right"
        open={open}
        handleDrawerClose={handleDrawerClose}
        FooterWithoutTab={true}
        ButtonLabel="View More Details"
        clickSaveButton={() =>
          (window.location.href = `/student-details/${studentData.userId}`)
        }
        cancelButtonLabel="Close"
        body={
          <>
            {/* <AddOneByOne/> */}
            <Profile
              studentData={studentData}
              courseData={courseData}
              paymentData={paymentData}
              changeStudentStatus={changeStudentStatus}
            />
          </>
        }
      />
    </>
  );
}

export default StudentsListing;
