import React, { useEffect, useState } from "react";
import dateFormat, { masks } from "dateformat";
import {dateFormatter, getAgeGroupBasedOnBirthDate} from '../../../../../hooks/HelperFunctions';

import VerifiedUser from '@mui/icons-material/Verified';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import UserIcon from '../../../../../assets/imgs/Session-img.png';

import SelectFieldMulticolor from '../../../../../components/Form/SelectFieldMulticolor/SelectFieldMulticolor';

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: "#FCFCFC",
    },
  },
}))(TableRow);



function Profile(props) {

  const {studentData, courseData, trackData, paymentData, changeStudentStatus} = props;

  const [courseOrTrack, setCourseOrTrack] = useState([]);
  const [userDetailsPageLink, setUserDetailsPageLink] = useState("");
  const [trackPriceOption, setTrackPriceOption] = useState("");


  useEffect(() => {
    setCourseOrTrack(courseData? courseData : trackData);
  }, [courseData, trackData]);

  useEffect(() => {
    if(!paymentData.trackPriceData){
      setTrackPriceOption("");
    } else{
      const priceOptionMap = {"fullPrice" : "Pay in full", "yearlyPrice":"Yearly", "monthlyPrice":"Monthly"};
      const selectedPrice = paymentData.trackPriceData?.pricingOption;
      console.log(priceOptionMap[selectedPrice]);
      setTrackPriceOption(priceOptionMap[selectedPrice]);
    }    
  }, [paymentData]);

  useEffect(() => {
    const link = `/student-details/${studentData.id}?activeTab=payment`;
    setUserDetailsPageLink(link);
  }, [studentData]);

  const statustData = [
    { value: "active", statuscolor: "#0B9444", select: "Active" },
    { value: "dropped", statuscolor: "#FF6600", select: "Dropped" },
    { value: "blocked", statuscolor: "#BA1A1A", select: "Blocked" },

  ];

  const handleChange = (event, value) => {
    const status = event.target.value;
    console.log(event.target.value, value);
    changeStudentStatus(studentData.userId, status);
  };

  const capitalizeFirstChar = (str) => {
    if (str.length === 0) {
      return str; // Return an empty string if the input is empty
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }


  return (
    <>
      <div className="TableContainer oddEvenTable BorderTable CondensedTable">
        <div className="TableInnerHeader">
          <div className="PeronalInfocard">
            <div className="UserIcon">
              {/* <Avatar sx={{ width: 80, height: 80 }} alt="Remy Sharp" src={studentData.image} /> */}
              {studentData?.image ?
                <Avatar sx={{ width: 80, height: 80 }} src={studentData.image ? studentData.image : ""} alt={`${studentData.firstName} ${studentData.lastName}`} ></Avatar>
                :
                <Avatar sx={{ width: 80, height: 80 }} > {studentData.firstName ? Array.from(studentData.firstName)[0] : ""} </Avatar>
              }
            </div>

            <div className="PeronalInfocardDetail">
              <div>
                <h1>{`${studentData.firstName} ${studentData.lastName}`}</h1>
                <p>{studentData?.address}</p>
              </div>
              {/* <Chip label="Online" /> */}
            </div>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Status</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">

                  <SelectFieldMulticolor
                    labelTop={false}
                    labelinsideStatus={true}
                    statustData={statustData}
                    defaultValue={courseData?.status? courseData.status : trackData?.status? trackData.status : ""}
                    handleChange={handleChange}
                    width="140px"
                    height="40px"
                    Status={true}
                  />

                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Enrolled</StyledTableCell>
                <StyledTableCell align="left" className={courseOrTrack.enrolledAt? "TableInfo" : "TableLabel"}>{courseOrTrack.enrolledAt? courseOrTrack.enrolledAt : "No data available"}</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Country</StyledTableCell>
                <StyledTableCell align="left" className={studentData.countryName? "TableInfo" : "TableLabel"}>{studentData.countryName? studentData.countryName : "No data available"}</StyledTableCell>
              </StyledTableRow>

              {/* <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">State/Province/District</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">New York</StyledTableCell>
              </StyledTableRow> */}

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Gender</StyledTableCell>
                <StyledTableCell align="left" className={studentData.gender? "TableInfo" : "TableLabel"}>{studentData.gender? studentData.gender : "No data available"}</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Age Group</StyledTableCell>
                <StyledTableCell align="left" className={studentData.birthdate? "TableInfo" : "TableLabel"}>{studentData.birthdate? getAgeGroupBasedOnBirthDate(studentData.birthdate) : "No data available"}</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Timezone</StyledTableCell>
                <StyledTableCell align="left" className={studentData.timezone? "TableInfo" : "TableLabel"}>{studentData.timezone? studentData.timezone : "No data available"}</StyledTableCell>
              </StyledTableRow>

              {courseData && 
              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Course fee</StyledTableCell>
                <StyledTableCell align="left" className={courseOrTrack.priceCat? "TableInfo" : "TableLabel"}><div className="FeeChip"><Chip label={courseOrTrack.priceCat == "Free" ? "Free" : paymentData?.status?  capitalizeFirstChar(paymentData.status) : "No data available" } /> <a href={userDetailsPageLink}>See payment history</a> </div></StyledTableCell>                                
              </StyledTableRow>}

              {trackData && 
              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Track fee</StyledTableCell>
                <StyledTableCell align="left" className={courseOrTrack.totalTrackPrice? "TableInfo" : "TableLabel"}><div className="FeeChip"> { trackPriceOption && <Chip label={ trackPriceOption } /> } <a href={userDetailsPageLink}>See payment history</a> </div></StyledTableCell>
              </StyledTableRow>
              }

            </TableBody>
          </Table>
        </TableContainer>
      </div>

    </>
  );
}


export default Profile;

