import React, { useEffect, useState } from "react";
import Buttons from '../../../../../components/Form/Button/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import VerifiedUser from '@mui/icons-material/Verified';




function Security(props) {


  return (
    <>
      <div className="Table">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            


            <TableBody>

            <TableRow>
                <TableCell align="left"><h1>Access details</h1></TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left"> Email address</TableCell>
                <TableCell align="left"><span  style={{display:'flex', alignItems:'center'}}><span style={{display:"inline-block"}}>shayanmahmud@gmail.com     </span>  <span style={{display:"inline-block"}}><span  style={{display:'flex', alignItems:'center'}} className="status rounded success"><VerifiedUser style={{fontSize:'16'}}/>Verified</span></span> </span>
                
                </TableCell>
                
             
              </TableRow>

              <TableRow>
                <TableCell align="left">Phone </TableCell>
                <TableCell align="left">+1 (123) 123-1234</TableCell>
              </TableRow>


              <TableRow>
                <TableCell align="left">Password </TableCell>
                <TableCell align="left">...................</TableCell>
              </TableRow>

              

              
      

            </TableBody>
          </Table>
        </TableContainer >
      </div >

    </>
  );
}






export default Security;

