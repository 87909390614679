import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import AWS from "aws-sdk";

import "./FileUplaoder.css";
import './FileUplaoderField.css';

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
//import deletebuttononhover icon from mui
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CustomizedSnackbar from "../../../hooks/Snackbar";

const CONF_S3_DIRECTORY =
  process.env.REACT_APP_PROJECT_ENV === "prod"
    ? process.env.REACT_APP_S3_DIRECTORY_PROD
    : process.env.REACT_APP_S3_DIRECTORY_DEV;

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const S3_DIRECTORY = CONF_S3_DIRECTORY;
const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;
const REGION = process.env.REACT_APP_S3_REGION;
const S3_ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
const S3_SECRET_ACCESS_KEY = process.env.REACT_APP_S3_SECRET_ACCESS_KEY;

const REACT_APP_CDN_URL = process.env.REACT_APP_CDN_URL;

AWS.config.update({
  accessKeyId: S3_ACCESS_KEY,
  secretAccessKey: S3_SECRET_ACCESS_KEY,
});

const s3Bucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const FileUplaoder = forwardRef((props, ref) => {
  const {
    filePath,
    label,
    validation,
    uploadButton,
    file,
    fileType,
    showThumb,
    onUpload,
  } = props;

  useImperativeHandle(ref, () => ({
    clickButton: () => deleteUploadedFile()
  }));

  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const [subDomain, setSubDomain] = useState("");

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [preview, setPreview] = useState(null);

  const [isImage, setIsImage] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");


  useEffect(() => {
    const localSubDomain = localStorage.getItem("subDomain");
    setSubDomain(localSubDomain);
  }, []);

  useEffect(() => {
    if(selectedFile){
      uploadFile(selectedFile);
    }    
  }, [selectedFile]);  

  const handleFileInput = (e) => {
    const item = e.target.files[0]; 
    const maxSize = Number (10 * 1024 * 1024 ) ; // 15MB in bytes

    if ( Number(item.size) > maxSize) {
      setSnackbarMessage("The selected file exceeds the max limit of 10 MB");
      setSnackbarSeverity("FieldError");
      setShowSnackbar(true);
      setSelectedFile(null);
      return "";
    }else{
      setShowSnackbar(false);
      setSnackbarMessage("");
      setSnackbarSeverity("");
    }

    setSelectedFile(e.target.files[0]);
    
    if(props.setSelectedFile){
      props.setSelectedFile(e.target.files[0]);
    }    
    
    console.log(e.target.files[0]);
    // return '' ;
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = reader.result;
      const isImage = result.startsWith("data:image/");

      if (isImage) {
        const img = new Image();
        setIsImage(true);

        img.onload = function () {
          setWidth(img.naturalWidth);
          setHeight(img.naturalHeight);

          //check if props.setWidth is defined then set width
          if (props.setWidth){
            props.setWidth(img.naturalWidth);
          }

          //check if props.setHeight is defined then set height
          if (props.setHeight){
            props.setHeight(img.naturalHeight);
          }          

        };

        img.src = event.target.result;
      }
    };

    reader.onloadend = () => {
      setPreview(reader.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const uploadFile = (file) => {
    const nameMandatory = props.nameMandatory ? props.nameMandatory : false;

    console.log(nameMandatory);

    if (nameMandatory) {
      //if props.mediaName is empty then return
      if (props.mediaName.length === 0) {
        alert("Please enter media name");
        return "";
      }
    }

        

    let objFilePath = subDomain ? S3_DIRECTORY + `${subDomain}/` : S3_DIRECTORY;
    // objFilePath += filePath + randString(5) + "-" + file.name;
    objFilePath += filePath + file.name;

    console.log(file);

    // return '';

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: objFilePath,
    };

    s3Bucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) console.log(err);
        props.onUpload(
          REACT_APP_CDN_URL + objFilePath,
          file.name,
          Number(file.size) / 1024,
          file.type,
          width,
          height
        );
      });
  };

  const handleFileDelete = (filePath) => {
    // console.log(filePath);
    filePath = filePath.replace(S3_BASE_URL, "");

    const params = {
      Bucket: S3_BUCKET,
      Key: filePath,
    };

    s3Bucket.deleteObject(params, function (deleteErr, data) {
      if (deleteErr) {
        console.log("Error: " + deleteErr, filePath);
      } else {
        console.log("Successfully deleted S3 object", filePath);
        props.onUpload("");
      }
    });
  };

  const deleteUploadedFile = () => {
    //delete from local
    setSelectedFile(null);
  };

  

  

  return (
    <div className="TextField">   

      <div className="FieldLabelUpload">
        {label} {validation && <span>*</span>}
      </div>
      <div>
        {file ? (
          " "
        ) : (
          <div className="FileUplaoderFieldContainer">
            <div className="FileUplaoderField">
              <div className={props.FieldError + " " + snackbarSeverity}>
              <input
               className="uploadfield"
                type="file"
                onChange={handleFileInput}
                accept={
                  fileType == "image" ? "image/png, image/jpeg,image/webp, application/pdf" : "*"
                }
              />
              <p className="HelperText" style={{ marginBottom: '10px' }}>
                
                {snackbarMessage ? snackbarMessage : props.helpertext}
               
                
              </p>
              </div>
              <DeleteForeverRoundedIcon
                //if no file selected then don't show delete icon
                style={{ display: selectedFile ? "block" : "none" }}
                className="DeleteIcon"
                onClick={() => deleteUploadedFile()}
              />
            </div>
            {progress > 0 && progress < 100 && (
              <CircularProgress variant="determinate" value={progress} />
            )}

            
            

            <Button
              variant="contained" ref={ref}
              onClick={() => uploadFile(selectedFile)}
              disabled={progress > 0 && progress < 100 ? true : false}
              //if props.showUploadButton is false then don't show upload button
              style={{ display: props.showUploadButton ? "block" : "none" }}  
              
            >
              {uploadButton}
            </Button>
          </div>
        )}

        {file ? (
          <div className="FileUplaoder">
            <div className="FileUplaoderInfo">
              {fileType == "image" && showThumb ? (
                <img src={file} />
              ) : (
                <span>
                  <AttachmentIcon />
                </span>
              )}
              <span> {file.replace(/^.*[\\\/]/, "")} </span>
            </div>
            <CloseRoundedIcon
              className="CloseIcon"
              onClick={() => handleFileDelete(file)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
});

export default FileUplaoder;
