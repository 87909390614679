import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from '../../../../../components/Form/TextField/TextField'



function AccessDetails(props) {

  return (
    <>

      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection Section">
            <div className="MdTitle">Payment Details</div>

            <Grid container spacing={3}>
              

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Stripe Account Email"
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Stripe ID"
                  required={true}
                />
              </Grid>

            </Grid>

          </div>
        </Grid>
      </Grid>


      


    </>
  );
}


export default AccessDetails;

