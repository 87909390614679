import React from "react";
import ComingSoon from '../../../components/ComingSoon/ComingSoon';


function Calendar(props) {



  return (

    <>
      <ComingSoon title="Calendar" />

    </>

  );
}


export default Calendar;





































