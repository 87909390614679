import React, { useEffect, useState } from "react";

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import '../../../../assets/css/PaymentMethod.css';



const PaymentMethodCheck = (props) => {

  const {paymentData} = props;

  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "#F8F8F9",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========/


  return (
    <>



      <div className="PaymentMethod">
        <div className="PaymentMethodTitle">Payment Details</div>

        <div className="TableContainer oddEvenTable">

          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableBody>

                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Method</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">Check</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Check Payable to</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">MUNA Academy</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow >
                  <StyledTableCell align="left" className="TableLabel">Mail the check to</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">MUNA Academy <div className="TableLabel">73 Mackay Pl <br />Brooklyn, NY 11209</div></StyledTableCell>
                </StyledTableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </div>

    </>
  );
};

export default PaymentMethodCheck;


