import React, { useState } from "react";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';

function CourseResourse(props) {

    const {modIndex, courseResourses, courseSessions, moduleTitle} = props;

    const [resourseExpanded, setResourseExpanded] = React.useState('');

    const handleChangeResoursePannel = (panel) => (event, isExpanded) => {
        setResourseExpanded(isExpanded ? panel : false);
    };

    const getAdditionalTitle = (sesId, currentModuleTitle) => {

        if(courseSessions.length == 0){
            return "";
        }
        const currentSessionItem = courseSessions.findIndex( (element, sesIndex, array) => {
            return (element.sesId == sesId);
        });
 
        return typeof courseSessions[currentSessionItem] == 'undefined' ? currentModuleTitle : courseSessions[currentSessionItem].title;
    }

    return (
        <Accordion expanded={resourseExpanded === `resourse-${modIndex}`} onChange={handleChangeResoursePannel(`resourse-${modIndex}`)}>
        <AccordionSummary
        expandIcon={<ArrowForwardRoundedIcon />}
        aria-controls="panel2bh-content"
        id="panel2bh-header"
        >
        <div className="accTitleSection">
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Resources
            </Typography>
            {/* <Button className="SessionButton" onClick={() => setOpenSession(true)}><AddRoundedIcon />Manage Resources</Button>  */}
        </div>
        </AccordionSummary>

        <AccordionDetails>
        <div className="SessionListing">
            {courseResourses.map((resourseData, index) => {
                return (
                    <li>
                    <div className="Sessionleftcol">

                        <div className="SessionrIcon">
                        <span>
                            {resourseData.type == 0? 
                            <InsertLinkOutlinedIcon /> :
                            <DownloadForOfflineOutlinedIcon />}
                        </span>
                        </div>

                        <div className="SessionListingDetail">

                        {resourseData.type == 0? (
                            <>
                            <h4>{resourseData.title}</h4>
                            <p>{getAdditionalTitle(resourseData.sesId, moduleTitle)}</p>
                            </>
                        ):(
                            <>
                            <h4> {resourseData.resourseFileName} </h4>
                            <p>{getAdditionalTitle(resourseData.sesId, moduleTitle)}</p>
                            </>
                        )} 

                        </div>

                    </div>

                    <div className="SessionListingAction">
                        {resourseData.type == 0? (
                            <Link href={resourseData.resourseLink} target='_blank' underline="none" rel="noopener"> <Button className="lstbtn"> <InsertLinkOutlinedIcon /> Visit Link</Button> </Link>
                            ):(
                            <Link href={resourseData.resourseFile} underline="none" download> <Button className="lstbtn"> <DownloadForOfflineOutlinedIcon /> Download</Button> </Link>
                        )} 
                        
                        
                    </div>
                    </li>
                    
                )
            })}
        </div>

        </AccordionDetails>

        </Accordion>
    );
}

export default CourseResourse;