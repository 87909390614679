import React, { useState } from "react";
import { Grid } from "@mui/material";
import TextField from "../../../../components/Form/TextField/TextField";
import PhoneInputField from "../../../../components/Form/PhoneInputField/PhoneInputField";
import MultipleSeletChip from "../../../../components/Form/MultipleSeletChip/MultipleSeletChip";
import TextArea from "../../../../components/Form/TextArea/TextArea";

const AddNewMembers = () => {
  const [role, setRole] = useState();
  const [bio, setBio] = useState("")
  const roles = [
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Instructor",
      value: "Instructor",
    },
  ];
  const handleChanges = (event) => {
    setBio(event.target.value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label="First Name"
            required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Middle Name"
            // required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Last Name"
            required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Email"
            required={true}
            email={true}
            helperText="We will send invite to this email"
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid xs={12} md={6} sx={{mt: 2.1, pl: 1}} className="addmemberPhoneFeild">
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            // value={phone.number}
            countryCodeEditable={false}
            onChange={(phone, country) =>
              console.log("onChange", phone, country)
            }
            onBlur={() => console.log("onBlur")}
            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
            variant="outlined"
            // onlyCountries={["us", "ca", "gb", "my"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "10px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorText={"Phone number is required"}
            error={false}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            labelFixed="Phone Number (Optional)"
            LabelStatus={true}
            // helpertext="Helper text"
            // error="error"
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleSeletChip
            label="Select Role"
            validation={true}
            options={roles}
            selectedOption={role}
            // handleChange={() => handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Organization/Institution/Company Name"
            // required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Designation"
            // required={true}
            // email={true}
            // value={props.medianame}
            // handleValidation={handleValidation}
            // handleChange={handleChanges("mediaName")}
            // count={50}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            label="Short bio"
            placeholder=""
            validation={false}
            value={bio}
            handleChange={handleChanges}
            helperText=""
            count={1000}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddNewMembers;
