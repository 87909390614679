import React from "react";
// import Buttons from '../../../components/Form/Button/Button';
// import AddRoundedIcon from '@mui/icons-material/AddRounded';


import AlrtMessage from '../../../components/Form/AlrtMessage/AlertMessage';
import Table from '../../../components/Table/Table';


function createData(name, ID, Track, Price,Status,Action ) {
  return { name, ID, Track, Price,Status,Action  };
}


function FeaturedListing(props) {
  const onClick = () => {
    alert("onclick working fine!");
  }


  const headCells = [

    { id: 'name', numeric: true, disablePadding: false, label: 'Name', width: "200px" },
    { id: 'ID', numeric: true, disablePadding: false, label: 'ID', width: "70px" },
    { id: 'Track', numeric: true, disablePadding: false, label: 'Track', width: "170px" },
    { id: '', numeric: true, disablePadding: false, label: '', width: "80px" },


  ];

  const rows = [
    {
      cell: [
        { name: "Imam Mohammad Ibn Saud Islamic University Curriculum", },
        { string: "MA010", },
        { linkText: "Islamic Studies", chip: "CERTIFICATE", chipStatus: true, },
        { string: "", },
        { Status: false, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
        { ActionButton: true, ActionButtonLabel:"Action"},

      ],

      
      //======= Action Date Start===== //
      action: [
        {label:"View",onClick:onClick,},
        {label:"Edit",onClick:onClick},
        {label:"Delete",onClick:onClick},
      ]
      //======= Action Date End===== //

  }];
  return (

    <>
    

        <div className="TableSection Section">

          <Table
            headCells={headCells}
            rows={rows}
            addLabel="Add Featured Courses"
            link="add-courses/0"
            AddButton={true}
            tableHeader={true}
            taleTitle="Featured Courses"
            titleTow="Table Name"
            Checkbox={false}
          />
        </div>

   
    </>
  );
}


export default FeaturedListing;

