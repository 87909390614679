import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useLocation, } from "react-router-dom";
import { getUserAuthData, getUserData } from '../hooks/HelperFunctions'

import Header from '../components/Header/Header';
import Sidebar from '../pages/student-portal/sidebar/Sidebar';
import SideBarDrawer from '../components/SideBarDrawer/SideBarDrawer';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Buttons from '../components/Form/Button/Button';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AlrtMessageFullWidth from '../components/Form/AlrtMessage/AlrtMessageFullWidth';
import RunningCourseAlertMessage from '../components/Form/AlrtMessage/RunningCourseAlertMessage';

const StudentAuth = ({ collapsed,children }) => {

    const [open, setOpen] = useState(false);
    const [profileCompleted, setProfileCompleted] = useState(true);

    const takbirUrl = "https://mytakbir.com/";

    useEffect(() => {

        getStudentAccountVerificationInfo();

    }, []);

    const getStudentAccountVerificationInfo = () => {
        // check user profile completed or not if not then show alert message
      const localUserVerified = localStorage.getItem('userVerified');
      console.log(localUserVerified);
      if(localUserVerified == "true"){
        const stuId = localStorage.getItem('stuId');
        const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
        axios.get(`${baseUrl}/studentPortal/checkProfileCompleted?stuId=${stuId}`).then((result) => {
          console.log(result.data.profileCompleted);
          setProfileCompleted(result.data.profileCompleted);
        }).catch((err) => {
          console.log(err.response.data.message);
        });
      }
    }

    const getAlertComponent = () => {

        return (
        <>
            { profileCompleted == false && 
            <AlrtMessageFullWidth
                message="Add missing information to your profile"
                width="60%"
                icon={<PersonRoundedIcon />}
                //ButtonLable={alertBtnLabel}
                // onClick={resendEmailHandler}
                link="/settings?editProfile=true"
                background="#004FE0"
                ButtonColor="#0D3FB3"
                ButtonStatus={true}
                ButtonLable="Complete Profile"
                duration="8000000"
            /> }

            <RunningCourseAlertMessage portal="student" />
        </>
        )
    }



    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const location = useLocation();
    const userAuthenticated = getUserAuthData();
    // retrive user data
    const userData = getUserData();
    // assign user role
    const userRole = userData.userRole === "undefined" ? "" : userData.userRole;

    if (userAuthenticated && userRole === "student") {



        return (
            <>                
                
                <div className="DesktopView">
                    <Header
                        AlertBody={
                            getAlertComponent()
                        }
                        ButtonBody={
                            <div>
                                <Buttons
                                    label="Browse Courses"
                                    buttonColor="#fff"
                                    border="#E4E8EC 1px solid"
                                    color="#004FE0"
                                    width=""
                                    height="40px"
                                    fontSize="14px"
                                    link={takbirUrl?.length > 0 ? takbirUrl : "#"}
                                />
                            </div>
                        }

                        SidebarBody={
                            <Sidebar collapsed={collapsed} />
                        }
                    />
                    
                </div>

                <div className="ResponsiveMenu">
                    <Header
                        AlertBody={
                            getAlertComponent()
                        }
                        IconMenu={<a onClick={handleDrawerOpen}><MenuRoundedIcon /></a>}
                        ButtonBody={
                            <div>
                                <Buttons
                                    label="Browse Courses"
                                    buttonColor="#fff"
                                    border="#E4E8EC 1px solid"
                                    color="#004FE0"
                                    width=""
                                    height="40px"
                                    fontSize="14px"
                                    link={takbirUrl?.length > 0 ? takbirUrl : "#"}
                                />
                            </div>
                        }

                    />
                    <SideBarDrawer
                        open={open}
                        handleDrawerClose={handleDrawerClose}
                        DrawerBody={
                            <Sidebar />
                        }
                    />
                </div>


                <div className="app-body"> {children} </div>


            </>
        );
    }

    return <Navigate to="/signin" state={{ from: location }} replace></Navigate>;
}

export default StudentAuth;