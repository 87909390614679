import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import tableImg from "../../../assets/imgs/tableImg.png";
import crown from "../../../assets/imgs/Crown.svg";
import AddNewMembers from "./forms/AddNewMembers";

const TeamListing = () => {
  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", width: "45%", },
    { id: "calories", numeric: true, disablePadding: false, label: "Role", width: "15%", },
    { id: "calories", numeric: true, disablePadding: false, label: "Email", width: "15%",},
    { id: "calories", numeric: true, disablePadding: false, label: "Status", width: "15%",},
    { id: "ButtonFilld", numeric: true, disablePadding: false, label: "", width: "10%",},
  ];

  const rows = [
    {
      cell: [
        {
          img: tableImg,
          imgStatus: true,
          imgText: "Shayan Mahmud",
          align: "left",
        },
        { textBold: "Admin" },
        { textBold: "shayanmahmud@gmail.com" },
        {
          Status: true,
          background: "#FCE7E9",
          color: "#E63946",
          label: "Inactive",
        },
        {
          Button: true,
          buttonLabel: "Details",
          buttonColor: "#FFFFFF",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonLink: "/team-details",
        },
      ],
    },
    {
      cell: [
        {
          img: tableImg,
          imgStatus: true,
          imgText: "Dr. Omar Suleiman",
          align: "left",
        },
        { textBold: "Instructor" },
        { textBold: "notrealomarsuleiman@gmail.com" },
        {
          Status: true,
          background: "#EEECFF",
          color: "#7266FB",
          label: "Invite Sent",
        },
        {
          Button: true,
          buttonLabel: "Details",
          buttonColor: "#FFFFFF",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonLink: "/team-details",
        },
      ],
    },
    {
      cell: [
        {
          img: tableImg,
          imgStatus: true,
          imgText: "Dr. Yasin Arafat",
          align: "left",
        },
        {
          textBold: (
            <>
              Owner&nbsp; <img src={crown} alt="owner" />
            </>
          ),
        },
        { textBold: "yasinarafat@gmail.com" },
        {
          Status: true,
          background: "#E1F2E8",
          color: "#0B9444",
          label: "Active",
        },
        {
          Button: true,
          buttonLabel: "Details",
          buttonColor: "#FFFFFF",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonLink: "/team-details",
          buttonTarget: "_blank",
          disabled: "disabled",
        },
      ],
    },
  ];

  {/*=========== Add new member drawer ========== */}
  const [OpenAddNewMemberDrawer, setopenAddNewMemberDrawer] = useState(false);

  const handleOpenAddNewMember = () => {
    setopenAddNewMemberDrawer(true);
  };

  const CloseAddNewMember = () => {
    console.log("close");
    setopenAddNewMemberDrawer(false);
  };
  {/*============ Add new member drawer ========= */}

  return (
    <>
      {/* ----------- page table ------------ */}
      <div className="TableSection">
        <Table
          title="Team Members"
          tableHeader={true}
          addLabel="New Member"
          onClick={() => handleOpenAddNewMember(true)}
          headCells={headCells}
          rows={rows}
          AddButton={true}
          Checkbox={false}
          footer={true}
          innerHeader={false}
        />
      </div>
      {/* ------------ drawer ---------------- */}
      <SideDrawer
        title="Invite Team Member"
        buttonLabel="Save"
        // buttonLink={}
        sideOpen="right"
        open={OpenAddNewMemberDrawer}
        handleDrawerClose={CloseAddNewMember}
        FooterWithoutTab={true}
        ButtonLabel="Send Invite"
        cancelButtonLabel="Cancel"
        Checkbox={false}
        CheckboxLabel="Add another after saving"
        body={<><AddNewMembers/></>}
      />
    </>
  );
};

export default TeamListing;
