import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import styles from './AlrtMessageFullWidth.module.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


export default function AlrtMessageFullWidth(props) {
  const [showElement,setShowElement] = React.useState(true)
  useEffect(()=>{
    setTimeout(function() {
      setShowElement(false)
         }, props.duration);
       },
   [])

   const [open, setOpen] = React.useState(true);

  return (

    <>
    {showElement?
    <Collapse in={open}>
    <div className={styles.AlrtMessage} style={{background:props.background}}>
      <div className={styles.AlrtMessageContainer} style={{ maxWidth: props.width }}>
        <div className={styles.AlrtMessageInfo}>
          <span>{props.icon}</span>
          <p>{props.message}</p>
        </div>
        {props.ButtonStatus && 
        <Button href={props.link} className={styles.AlrtButton} onClick={props.onClick} style={{background:props.ButtonColor}}>
          {props.ButtonLable}
        </Button>
        }
      </div>
      <div className={styles.AlrtMessageCloseIcon}><CloseRoundedIcon onClick={() => { setOpen(false);}} /></div>
    </div></Collapse>:<></>} 
    
    </>

  );
}