import React, { useState } from "react";
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import AttachmentIcon from '@mui/icons-material/Attachment';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';


function Resources(props) {

    const {courseResourses, courseSessions, handleOpenResourseModal, handleDeleteResourse, moduleIndex} = props;

    const getSessionTitle = (sesId) => {
        if(courseSessions.length == 0){
            return "";
        }
        const currentListItem = courseSessions.findIndex( (element, sesIndex, array) => {
            return (element.sesId == sesId);
        });
        return typeof courseSessions[currentListItem] == 'undefined' ? "" : courseSessions[currentListItem].title;
    }

    return (
        <Accordion expanded={props.expanded === props.panel} onChange={props.handleChange}>
            <AccordionSummary
                expandIcon={<ArrowForwardRoundedIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header" >

                <div className="accTitleSection">
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Resources
                    </Typography>
                    <Button className="SessionButton" onClick={() => handleOpenResourseModal(moduleIndex, -1)}> <AddRoundedIcon />Resource</Button>
                </div>
            </AccordionSummary>

            <AccordionDetails>

            <div className="SessionListing">
                {courseResourses.map((resourse, resIndex) => {
                    return (
                        <li>
                        <div className="Sessionleftcol">

                            <div className="SessionrIcon">
                            <span>
                                {resourse.type == 0? (<AttachmentIcon/>):(<DownloadForOfflineIcon/>)}
                            </span>
                            </div>

                            <div className="SessionListingDetail">
                                {resourse.type == 0? (
                                    <>
                                    <h4>{resourse.title}</h4>
                                    <p>{getSessionTitle(resourse.sesId)}</p>
                                    </>
                                ):(
                                    <>
                                    <h4> {resourse.resourseFileName} </h4>
                                    <p>{getSessionTitle(resourse.sesId)}</p>
                                    </>
                                )}                                                                                    
                            </div>

                        </div>

                        <div className="SessionListingAction">
                            <Button onClick={() => handleOpenResourseModal(moduleIndex, resIndex)} ><EditRoundedIcon /></Button>
                            <Button onClick={() => handleDeleteResourse(moduleIndex, resIndex)} ><DeleteForeverRoundedIcon /></Button>
                        </div>
                        </li>
                    );
                })}
                <p className="emptySettionMessage">Add resourses to this module</p>                
            </div>

            </AccordionDetails>

        </Accordion>
    );
}

export default Resources;