import React, { useState, useEffect } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import './SelectField.css';

function SelectField(props) {

  // const [age, setAge] = React.useState('');
  // const handleChanges = (event) => {
  //   setAge(event.target.value);
  // };

  // const options = [
  //   {
  //     label: "Twenty",
  //     value: "Twenty",
  //   },

  //   {
  //     label: "Twentydd",
  //     value: "Twentyd",
  //   },

  // ]

  //   < SelectField
  // label = "Month"
  // options = { options }
  // handleChange = { handleChanges }
  //   />


  const { label, validation, required, options, selectedOption, handleChange, error, helperText } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");


  useEffect(() => {

    if (required || validation) {

      if ( !selectedOption ) {
        setValidationError(true);
        setValidationText("Required");        
      } else {
        setValidationError(false);
        setValidationText("");
      }

    }
  }, [selectedOption]);

  return (
    <div className="SelectFiled">
      <div className="FieldLabel">{label} {(validation || required) && <span>*</span>}</div>
      <FormControl error={error || validationError} >
      <Select
        labelId="customized-select-label"
        id="customized-select"
        value={selectedOption}
        onChange={handleChange}
        error={error || validationError}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {options.map((item, i) => (
          <MenuItem value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
      { (helperText || validationText) && <FormHelperText>{helperText || validationText}</FormHelperText>}
      </FormControl>
    </div>
  );
}


export default SelectField;

