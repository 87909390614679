import React, { useEffect, useState } from "react";

import VerifiedUser from '@mui/icons-material/Verified';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@mui/material/Typography';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ZelleIcon from '../../../../../../assets/imgs/ZelleIconFill.png';
import Summary from './Summary';
import Items from './Items';



const PaymentMethodZelle = (props) => {

  const {paymentData, courseData, attachmentInfo} = props;


  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "#F8F8F9",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========/


  return (
    <>

      <Summary paymentData={paymentData} />

      <div className="SummarySection">
        <div className="SumamryTitle">Payment Details</div>
        <div className="SummaryCarItems">
          <div className="TableContainer oddEvenTable">

            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>

                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel">
                    <div className="SumamryTitle">Payment method</div>
                      <div className="TableImgCell">
                        <span className="PMI PupColor">
                          <img src={ZelleIcon} />
                        </span>
                        <p>Zelle</p>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel">
                    <div className="SumamryTitle">Sender Zelle account</div>
                      <div className="TableImgCell">
                        <span className="PMI PupColor">
                          <img src={ZelleIcon} />
                        </span>
                        <p>{paymentData.zelleAcc}</p>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  {paymentData.attachmentFile && 
                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableInfo">
                    <div className="SumamryTitle">Attachment</div>
                    <div className="TableImgCell">
                      <div className="Pmt-ImgBox">
                        <div className="PmtImg">
                          <img className="item-icon" src={paymentData.attachmentFile} />
                        </div>
                        <div className="PmtInfo">
                          <p>{paymentData.attachmentFile.length > 23?  "..." + paymentData.attachmentFile.slice(-20) : paymentData.attachmentFile}</p>
                          <a href={paymentData.attachmentFile} target="_blank">View Photo</a>
                        </div>
                      </div>                      
                    </div>
                    <span>{attachmentInfo.uploadedBy} </span>
                    <Typography variant="p" color="textSecondary">
                      uploaded on {attachmentInfo.uploadedAt}
                    </Typography>
                    </StyledTableCell>
                  </StyledTableRow>}

                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <Items courseData={courseData} />
    </>
  );
};

export default PaymentMethodZelle;


