import React, { useEffect, useState } from "react";

import AlrtMessage from '../../../../components/Form/AlrtMessage/AlertMessage';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@mui/material/Button';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
//import pending icon from material ui
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import Buttons from '../../../../components/Form/Button/Button';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import SimpleBackdrop from '../../../../components/Form/Backdrop/SimpleBackdrop';
import CustomizedSnackbar from '../../../../hooks/Snackbar';



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, email, email_verified_at, forth, fifth) {
  return { name, email, email_verified_at, forth, fifth };
}



const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});


function Account(props) {

  const { studentData } = props;

  console.log(studentData)


  const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;


  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const showSnackbarMessage = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  }


  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  // Modal Add Category  start//

  const [openNewCategory, setOpenNewCategory] = useState(false);

  const handleOpenNewCategory = () => {
    setOpenNewCategory(true);
  };

  const handleCloseModalNewCategory = () => {
    console.log('close')
    setOpenNewCategory(false)
  };

  const handleConfirmNewCategory = () => {
    console.log('close')
    setOpenNewCategory(false)
  };

  // Modal Add Category  End//

  const rows = [
    createData('Email address', studentData.email, studentData.email_verified_at),
  ];
  const phoneVerification = [
    createData('Phone Number',
      //chek if phone numebr not null or not empty 
      ((studentData?.phone_number !== "") && (studentData.phone_number !== null)) ? "+" + studentData.phone_number : "Add a phone number to keep your account safe",

      (studentData?.phone_number !== "") && (studentData.phone_number !== null),
      studentData.is_phone_verified,
      studentData.is_phone_verified),
  ];

  //addPhoneNumberbutton click event start
  const addPhoneNumberbutton = () => {
    console.log('addPhoneNumberbutton');
    //redirect to //add-phone-number
    window.location.href = '/add-phone-number';
  };

  const changePhoneNumberbutton = () => {
    console.log('changePhoneNumberbutton');
    //redirect to //add-phone-number
    window.location.href = '/add-phone-number?changePhoneNumber=1&oldPhoneNumber=' + studentData.phone_number + '&oldCountry=' + studentData.country_code + '';
  };

  //verifyNow click event start
  const verifyNow = async () => {
    console.log('verifyNow');
    //make a post request to send otp to the phone number
    const postData = {};

    //make await axios call
    const response = await axios.post(`${baseUrl}/resendOtpCode`, postData);
    console.log(response);
    if (response.status == 200) {
      console.log('success');
      //redirect to //add-phone-number
      window.location.href = '/verify-otp';

    } else {
      console.log('error');
      alert(response.data.message)
    }

  };

  //verifyNowEmail click event start
  const verifyNowEmail = async () => {

    console.log('verifyNow Email');

    const subDomain = localStorage.getItem('subDomain');
    let orgWebsite = process.env.REACT_APP_TAKBIR_SUBDOMAIN;
    orgWebsite = orgWebsite.replace("sub-domain", subDomain);

    const postData = {'orgWebsite': orgWebsite, 'id': studentData.userId};

    //make await axios call
    setOpenBackdrop(true);
    const response = await axios.post(`${baseUrl}/studentPortal/verifyEmail`, postData);
    console.log(response);
    setOpenBackdrop(false);
    if (response.status == 200) {
      console.log('success');
      showSnackbarMessage("success", "Email sent");
    } else {
      console.log('error');
      showSnackbarMessage('error', response.data.message);
    }

  };

  return (


    <>

````<SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
    
    <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />

      <div className="TableContainer">
        <div className="TableInnerHeader">
          <h3>Access details</h3>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>

                  <StyledTableCell component="th" scope="row" width="25%">
                    {row.name}
                  </StyledTableCell>

                  <StyledTableCell align="left" width="60%">
                    <div className="cellwithchip">
                      <div className="tabletdvrf">
                        {row.email}
                        {row.email_verified_at === null ? <div className="PendingChip"><WarningRoundedIcon /> Pending</div> : <div className="VerifyChip"><VerifiedRoundedIcon /> Verified</div>}
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left" width="20%">
                  </StyledTableCell>
                  <StyledTableCell align="left" width="20%">
                  {row.email_verified_at === null &&
                  <Buttons
                    label="Verify Now"
                    buttonColor="#FFF"
                    border="#ccc 1px solid"
                    color="#004FE0"
                    width="180px"
                    height="40px"
                    fontSize="14px"
                    link=""
                    onClick={verifyNowEmail}
                  />}
                  </StyledTableCell>

                </StyledTableRow>
              ))}
              {phoneVerification.map((row) => (
                <StyledTableRow key={row.name}>

                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>

                  <StyledTableCell align="left" width="60%">
                    <div className="tabletdvrf">
                      {studentData?.phone_number_formatted ? studentData.phone_number_formatted : row.email}

                      {(studentData?.phone_number !== "") && (studentData.phone_number !== null) ?
                        <span className="">
                          {row.fifth ? <div className="VerifyChip"><VerifiedRoundedIcon /> Verified</div> :
                            <div className="PendingChip"><WarningRoundedIcon /> Pending</div>
                          }
                        </span>
                        : <div></div>
                      }
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {(studentData?.phone_number !== "") && (studentData.phone_number !== null) ?
                      <span>
                        {row.fifth ? <div></div> :
                          <div>

                            <Buttons
                              label="Verify Now"
                              onClick={verifyNow}
                              buttonColor="#FFF"
                              border="#ccc 1px solid"
                              color="#004FE0"
                              width="130px"
                              height="40px"
                              fontSize="14px"
                            />
                          </div>
                        }
                      </span>
                      : <div></div>
                    }

                  </StyledTableCell>

                  <StyledTableCell align="left">{(studentData?.phone_number !== "") && (studentData.phone_number !== null) ?
                    <Buttons label="Change"
                      buttonColor="#FFF"
                      border="#ccc 1px solid"
                      color="#004FE0"
                      width="160px"
                      height="40px"
                      fontSize="14px"
                      onClick={changePhoneNumberbutton} /> :
                    <Buttons
                      label="Add Phone"
                      buttonColor="#FFF"
                      border="#ccc 1px solid"
                      color="#004FE0"
                      width="180px"
                      height="40px"
                      fontSize="14px"
                      link=""
                      onClick={addPhoneNumberbutton}
                    />}
                  </StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

    </>

  );
}


export default Account;





































