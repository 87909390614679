import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import SelectField from '../../../../../components/Form/SelectField/SelectField'
import { getDataMap } from '../../../../../hooks/HelperFunctions'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '../../../../../components/Form/Tooltip/Tooltip';
import RedioBox from '../../../../../components/Form/RedioBox/RedioBox';


function Publish(props) {

  const { courseData, setCourseData } = props;
  const { courseStatuses } = props.courseRelationalData;

  const [tabsData, setTabsData] = useState([]);
  const [savedCourseStatus, setSavedCourseStatus] = useState("");

  const statusRadioOptions = ['draft', 'public', 'active_for_track', 'private'];


  useEffect(() => {

    if(!courseData.temp_status){
      setCourseData({ ...courseData, 'temp_status': courseData.status });
    }

    let tabsDataLoc = [
      {
        title: "Draft",
        description: "Course will be saved as draft.",
        disabled:"disabled"
      },
  
      {
        title: "Public",
        description: "Course will be published and visible to everyone and also active to be added to track.",
      },
  
      {
        title: "Active for track",
        description: "Course is active to be added to track. It will not be published to webpage individually",
      },
      
      {
        title: "Private",
        description: "Only admins and instructors can see this course",
        disabled:"disabled"
      }
    ];


    if( (courseData.status == "" || courseData.status == "draft" || courseData.temp_status == "" || courseData.temp_status == "draft") ){
      tabsDataLoc[0].disabled = "";
    }

    console.log(courseData.formValidationError);

    if(courseData.formValidationError){
      tabsDataLoc[1].disabled = "disabled";
      tabsDataLoc[2].disabled = "disabled";
    } 

    if(courseData.temp_status == "public" || courseData.temp_status == "active_for_track"){
      tabsDataLoc[3].disabled = "";
    }

    if(courseData.status != "draft"){
      tabsDataLoc[3].disabled = "";
    }

    setTabsData(tabsDataLoc);

  }, [courseData.status]);

  // === onChange event handler, get field data from event === //
  // const handleChanges = (fieldName) => (event) => {
  //   const fieldData = event.target.value;
  //   console.log(fieldName, fieldData);
  //   setCourseData({ ...courseData, [fieldName]: fieldData });
  // };

  // ==== Get course status === //
  // const getCourseStatus = () => {
  //   const courseStatus = [{value: "draft", label:"Draft"}, {value: "published", label:"Published"}, {value: "unpublished", label:"Unpublished"}];
  //   return courseStatus;
  // }

  const handlePriceRadioBtn = (event, activeSwitchIndex) => {
    const courseStatus = statusRadioOptions[activeSwitchIndex];
    console.log(activeSwitchIndex, courseStatus);
    setCourseData({ ...courseData, 'status': courseStatus });
  };


  return (
    <>

      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} md={12}>
          {/* <div className="formSection Section">
            <div className="MdTitle">Publish</div>


            <Grid item xs={12} sm={12} md={12}>
              <div className="StatusFiled">
                <SelectField
                  label="Status"
                  required={true}
                  options={getDataMap(courseStatuses, "nameSlug", "name")}
                  selectedOption={courseData.status}
                  handleChange={handleChanges("status")}
                />
              </div>

            </Grid>
          </div> */}

          <div className="formSection Section">
            <div className="MdTitle">Publish</div>
            <Grid item xs={12} sm={12} md={12}>
              <RedioBox
                tabsData={tabsData}
                value={statusRadioOptions.indexOf(courseData.status)}
                FullWidth="FullWidth"
                onChange={handlePriceRadioBtn}

              />
            </Grid>
          </div>
        </Grid>
      </Grid>

    </>
  );
}






export default Publish;

