import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  getUserData,
  getUserTimezone,
} from "../../../../../hooks/HelperFunctions";
import Table from "../../../../../components/Table/Table";
import SimpleBackdrop from "../../../../../components/Form/Backdrop/SimpleBackdrop";

function CoursesListing(props) {
  const { trackId } = props;

  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const timezone = getUserTimezone();

  const [courseList, setCourseList] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const onClick = () => {
    alert("onclick working fine!");
  };

  const headCells = [
    {
      id: "Seq",
      numeric: true,
      disablePadding: false,
      label: "Seq",
      width: "5%",
    },
    {
      id: "Course",
      numeric: true,
      disablePadding: false,
      label: "Course",
      width: "40%",
    },
    {
      id: "NextSession",
      numeric: true,
      disablePadding: false,
      label: "Next Session",
      width: "30%",
    },
    {
      id: "Status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "20%",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "15%",
    },
  ];

  const rows = [
    {
      cell: [
        { textBold: "1" },
        {
          img: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80",
          imgStatus: true,
          imgText: "Islamic studies and history",
          align: "left",
        },
        { date: "Tue, Jan 31st, 2023 EST" },
        {
          Status: true,
          background: "#EEECFF",
          color: "#7266FB",
          label: "In Progress",
        },
        {
          Button: true,
          buttonLabel: "Go To Course",
          buttonColor: "#fff",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonLink: "#",
          buttonTarget: "_blank",
        },
      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ],
      //======= Action Date End===== //
    },
  ];

  const getCourseListingData = () => {
    // const orgId = localStorage.getItem('orgId');
    const userData = getUserData();
    const userId = userData.id;
    axios
      .get(
        `${baseUrl}/studentPortal/getStudentCourses?userId=${userId}&trackId=${trackId}`
      )
      .then((result) => {
        console.log(result.data.body);
        setCourseList(result.data.body);
        setOpenBackdrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setOpenBackdrop(true);
    getCourseListingData();
  }, []);

  return (
    <>
      <div className="TableSection">
        <Table
          tableHeader={true}
          innerTitle="Courses"
          innerHeader={true}
          headCells={headCells}
          rows={courseList}
          AddButton={false}
          Checkbox={false}
          footer={true}
        />
      </div>
    </>
  );
}

export default CoursesListing;
