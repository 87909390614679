import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import SwitchButton from '../../../../../components/Form/Switch/Switch'
import SelectField from '../../../../../components/Form/SelectField/SelectField'
import Datefield from '../../../../../components/Form/Datefield/Datefield'
import Buttons from '../../../../../components/Form/Button/Button';
import TextArea from '../../../../../components/Form/TextArea/TextArea'
import TextField from '../../../../../components/Form/TextField/TextField'
// import Datefield from '../../../../../components/Form/Datefield/Datefield'
//import DateAndTime from '../../../../../components/Form/DateAndTime/DateAndTime'
import Modals from '../../../../../components/Form/Modals/Modals';
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';



function Individual(props) {


  //==== radio button//
  const [radiovalue, radiosetValue] = React.useState('female');

  const handleChangeRadio = (event) => {
    radiosetValue(event.target.radiovalue);
  };
  //==== radio button End//




   // ==== Date Picker === //
   const [dateTime, dateSetValue] = React.useState(new Date('2014-08-18T21:11:54'));

   const handleChangeDate = (newValue) => {
     dateSetValue(newValue);
   };
 
   // ==== Date Picker end === //

  // ==== Level Value === //
  const [age, setAge] = React.useState('');
  const handleCountry = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Beginner level",
      value: "Beginner level",
    },
    {
      label: "Intermediate level",
      value: "Intermediate level",
    },
    {
      label: "Expert level",
      value: "Expert level",
    },

    {
      label: "All levels",
      value: "All levels",
    },

  ];
  // ==== Level Value end === //


  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangePannel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [expanded_2, setExpanded_2] = React.useState('panel1');

  const handleChangePannel_2 = (panel) => (event, isExpanded_2) => {
    setExpanded_2(isExpanded_2 ? panel : false);
  };


  // Modal Module //

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    console.log('close')
    setOpen(false)
  };

  const handleConfirm = () => {
    console.log('close')
    setOpen(false)
  };

  // Modal Module  End//


  // Modal Session //

  const [openSession, setOpenSession] = useState(false);

  const handleOpenSession = () => {
    setOpenSession(true);
  };

  const handleCloseModalSession = () => {
    console.log('close')
    setOpenSession(false)
  };

  const handleConfirmSession = () => {
    console.log('close')
    setOpenSession(false)
  };

  // Modal Session  End//


  // Modal Resource //

  const [openResource, setOpenResource] = useState(false);

  const handleOpenResource = () => {
    setOpenResource(true);
  };

  const handleCloseModalResource = () => {
    console.log('close')
    setOpenResource(false)
  };

  const handleConfirmResource = () => {
    console.log('close')
    setOpenResource(false)
  };

  // Modal Resource End //

  const [values, setValues] = React.useState({
    name: "hello",
    number: "",
  });

  const handleChanges = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };



  return (
    <>

      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} md={12}>
          <div className="">

            <Grid container spacing={3}>

            <Grid item xs={12} sm={6} md={6}>
              <TextField
                  label="First Name"
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Last Name"
                  required={true}
                />
              </Grid>


              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Short Name*"
                  required={true}
                />
              </Grid>
              

              <Grid item xs={12} sm={12} md={12}>
                <Datefield
                  label="Date of Birth" 
                  required={true} 
                  value={values.enrollmentStartDate} 
                  handleChange ={ (selectedDate) => handleChangeDate("enrollmentStartDate", selectedDate) }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup aria-label="gender" name="gender1" value={radiovalue} onChange={handleChangeRadio}>
                      <div>
                        <FormControlLabel value="External-Resource" control={<Radio />} label="Male" />
                        <FormControlLabel value="Downloadable-File" control={<Radio />} label="Female" />
                      </div>
                      
                    </RadioGroup>
                  </FormControl>

                  </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <SelectField
                  label="Country"
                  validation={false}
                  value={age}
                  options={options}
                  handleChange={handleCountry}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Phone Number"
                  required={true}
                />


              </Grid>

              <Grid item xs={12} sm={6} md={6}>
              <TextField
                  label="Street Address"
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Fl/Suite Number"
                  required={true}
                />
              </Grid>


              <Grid item xs={12} sm={4} md={4}>
              <TextField
                  label="City"
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <SelectField
                  label="State"
                  validation={false}
                  value={age}
                  options={options}
                  handleChange={handleCountry}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label="Zip Code*"
                  required={true}
                />
              </Grid>

            </Grid>

          </div>
        </Grid>
      
      </Grid>
    </>
  );
}


export default Individual;

