import React, { useState, useEffect } from "react";
import {dateFormatter} from '../../../../../hooks/HelperFunctions';

import CustomizedSnackbar from '../../../../../hooks/Snackbar';
import DraggableDialog from '../../../../../hooks/Dialog';
import Button from '../../../../../components/Form/Button/Button';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SelectFieldMulticolor from '../../../../../components/Form/SelectFieldMulticolor/SelectFieldMulticolor';
import Grid from '@material-ui/core/Grid';
import InfoAlert from '../InfoAlert';
import SlateEditor from '../../../../../components/SlateEditor/SlateEditor';

function Settings(props) {

  //  temp code 
  // const timezone = "America/New_York"

  const { trackId, trackDetails, trackCourses, trackPricing, updateTrackData, deleteTrack, timezone } = props;

  const [hasRequiredData, setHasRequiredData] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");


  /**
   * When click on snackbar close btn
   */
     const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setShowSnackbar(false);
    };

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  const handleConfirmCloseDialog = () => {

    setOpenDialog(false)
    // redirect to detail page
    deleteTrack();
  }
  //======= Delete dialog ===== //

  useEffect(() => {

    if(trackCourses.length == 0){
      setHasRequiredData(false);
    } else if( !(trackPricing?.fullPrice?.enabled || trackPricing?.yearlyPrice?.enabled || trackPricing?.monthlyPrice?.enabled) ){
      setHasRequiredData(false);
    } else{
      setHasRequiredData(true);
    }

  }, [trackCourses, trackPricing]);


  const handleOpenDialog = (id) => {
    console.log(id);
    // setActiveCourseId(id);
    // setDialogTitle("Delete this track?");
    // setDialogBody("Are you sure, you want to delete a course record and it's related data?");
    setOpenDialog(true);
  }


  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "#FCFCFC",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//


  const statustData = [
    { value: "draft", statuscolor: "#ffaa00", select: "Draft" },
    { value: "published", statuscolor: "#0B9444", select: "Published" },
    { value: "unpublished", statuscolor: "#BA1A1A", select: "Unpublished" },
  ];

  const handleChange = (event) => {
    const selectedStatus = event.target.value;
    if(selectedStatus == "published" && !hasRequiredData){
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please fill all the required fields and publish the track.");
    } else{
      const trackData = {'trackId': trackId, 'status': selectedStatus, 'onlyStatus': 1};
      updateTrackData(trackData);
    }
    
  };



  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  return (

    <>
      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} md={12}>
          <div className="TableContainer oddEvenTable">
            <div className="TableInnerHeader">
              <h3>Track overview</h3>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>

                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel">Status</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <SelectFieldMulticolor
                        labelTop={false}
                        labelinsideStatus={true}
                        statustData={statustData}
                        defaultValue={trackDetails.status}
                        handleChange={handleChange}
                        width="165px"
                        height="30px"
                        Status={true}
                      />
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel" width="35%">Type</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">{trackDetails.trackType === 'diploma'? "Diploma": "Certificate"}</StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel">Track name</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">{trackDetails.name}</StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel">Enrollment start date</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">{dateFormatter(trackDetails.enrollmentStartDate, timezone, "ddd, MMM Do, YYYY z")}</StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel">Enrollment end date</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">{dateFormatter(trackDetails.enrollmentEndDate, timezone, "ddd, MMM Do, YYYY z")}</StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel">Session start date</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">{dateFormatter(trackDetails.sessionStartDate, timezone, "ddd, MMM Do, YYYY z")}</StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow className="TableColDescription">
                    <StyledTableCell align="left" className="TableLabel">Description</StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                    <SlateEditor 
                      label=""
                      required={false}
                      onChange={(fieldData) => {}}
                      value={trackDetails.description}              
                      readOnly={true}
                      showCharLimit={false}
                    />
                    </StyledTableCell>
                  </StyledTableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>


        <Grid item xs={12} sm={12} md={12}>
          <div className="DeleteTrack">
            <h3>Delete track</h3>
            <p>Once you delete it, you can't get it back.</p>
            <Button
              label="Delete"
              buttonColor="#E63946"
              border="#E63946 1px solid"
              color="#fff"
              width="88px"
              height="40px"
              fontSize="14px"
              link=""
              onClick={handleOpenDelete}

            />
          </div>
        </Grid>

      </Grid>

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={handleConfirmCloseDialog}
        title="Delete this track?"
        body={<div>Are you sure you want to delete this? <br></br>Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        actionButton="Delete"
      />

    </>
  );  
}


export default Settings;

