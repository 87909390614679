import React, { useRef, useState, useEffect } from "react";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";

import CustomizedSnackbar from '../../hooks/Snackbar';
import { Grid } from '@material-ui/core/';
import Buttons from '../../components/Form/Button/Button';
import PasswordStrength from '../../components/Form/FieldPassword/PasswordStrength';
import FieldPassword from '../../components/Form/FieldPassword/FieldPassword';
import Header from './header/Header';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import './Form.css';


function ResetPassword(props) {

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");

  const handleChanges = (fieldName) => (event) => {
    const fieldValue = event.target.value;
    console.log(fieldName, fieldValue);
    setConfirmPassword(fieldValue);
  };

  const processResponse = (response) => {
    console.log(response);
    if (response.data.body.status == true) {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.body.message);
      // redirect to sign in page
      setTimeout(() => {
        setSnackbarMessage("Redirecting to sign in page...");
        window.location.replace(`${window.location.origin}/signin`);
      }, 3000);

    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.body.message);
    }
  }

  const sendResetPasswordRequest = () => {

    console.log(password, confirmPassword);

    if (passwordValidation == false) {

      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Password should have matched all the below criteria.");
      return false;
      
    } else if (password !== confirmPassword) {

      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Password is not matching.");
      return false;

    } else {

      const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
      const postData = { password: password, token: token };

      axios.post(`${baseUrl}/resetPassword`, postData)
        .then(response => processResponse(response))
        .catch((err) => {
          console.log(err);
          if (err.response.data.message) {
            setShowSnackbar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage(err.response.data.message);
          }
        });

      return true;

    }

  }

  // handle reference login
  let [searchParams, setSearchParams] = useSearchParams();
  let userToken = searchParams.get("token");
  // console.log(userToken);

  useEffect(() => {

    if (userToken) {
      // console.log(userToken);
      // userToken = token.replace(" ", "+");
      // console.log(userToken);
      setToken(userToken);
    }

  }, []);

  /**
   * 
   * @param {*} event 
   * @param {*} reason 
   * @returns 
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };



  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
        <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
          <div className="SignForm">
            <h1>Setup New Password</h1>

            <div className="SignFormGrid">
            <Grid container spacing={3}>

              <Grid item xs={12} md={12}>
                <PasswordStrength
                  placeholder="New Password"
                  handlePasswordText={setPassword}
                  handlePasswordValidation={setPasswordValidation}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FieldPassword
                  placeholder="Confirm New Password"
                  handleChange={handleChanges("confirmPassword")}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Reset Password"
                  buttonColor="#004FE0"
                  border="#ccc 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={sendResetPasswordRequest}
                />
                <div className="BottomButton">
                  <Buttons
                    label="Cancel"
                    color="#004FE0"
                    width=""
                    height="48px"
                    fontSize="14px"
                    link="/signin"
                  />
                </div>
              </Grid>

            </Grid>
            </div>
          </div>
          <div className="SignFormFooter">
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>

      </div>
    </>

  );
}


export default ResetPassword;

