import * as React from 'react';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import './AlertMessageTwo.css';



{/* <AlertMessageTwo
  Message="Learners can't see this track yet. Please fill all the required fields and change the status to Publish from track"
  linkText="settings"
  link="#"
/> */}



export default function AlertMessageTwo(props) {

  return (

    <div className='AlertMessageTwo'>
      <ErrorRoundedIcon />
      <p>{props.Message} <a href={props.link} onClick={props.onClick}>{props.linkText}</a></p>
    </div>
  );
}