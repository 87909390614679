import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import './Header.css';
import DeenLogo from '../../../assets/imgs/deen360-logo.png';
// import OrgLogo from 'https://deen360.s3.amazonaws.com/jayhan/logo/deen360-muna-academy-lms-learn-islam-logo.png';
// import OrgLogo from '../../../assets/imgs/deen360-muna-academy-lms-learn-islam-logo.png';


function Header(props) {

const [orgLogo, setOrgLogo] = useState("");
const [orgWebsiteLink, setOrgWebsiteLink] = useState("");

// handle token based signin
let [searchParams, setSearchParams] = useSearchParams();
let org = searchParams.get("org");
console.log(org);


useEffect(() => {
    
    if(org){
      let orgWebsite = process.env.REACT_APP_TAKBIR_SUBDOMAIN;
      orgWebsite = orgWebsite.replace("sub-domain", org);
      // let orgLogoLink = `${orgWebsite}/deen360-org-logo.png`;
      let orgLogoLink = process.env.REACT_APP_ORG_LOGO;
      orgLogoLink = orgLogoLink.replace("sub-domain", org);
      setOrgLogo(orgLogoLink);
      setOrgWebsiteLink(orgWebsite);
    }

}, []);

  return (
    <>
    <div className="HeaderTwo">
      <a href="#"><img src="https://cdn.mytakbir.com/static-assets/logos/takbir-lms-learning-management-system-muslim-logo-3x.png" /></a>
      {/* <div className="HeaderTwoR">
        <span>Need help?</span>
        <a href="#">Contact Us </a>
      </div> */}
    </div>
    {orgLogo?.length > 0 && <a href={ orgWebsiteLink?.length > 0? orgWebsiteLink : "#" } className="OrgLogo"><img src={orgLogo} /></a> }
    </>

  );
}


export default Header;

