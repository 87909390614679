import React, { useEffect, useState } from "react";

import VerifiedUser from '@mui/icons-material/Verified';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import tableImg from '../../../../assets/imgs/tableImg.png';



const OrderItems = (props) => {


  const {courseData} = props;


  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "#F8F8F9",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========/


  return (
    <>

      <div className="PaymentMethod">
        <div className="PaymentMethodTitle">Item(s)</div>
        <div className="PaymentMethodCarItems">
          <div className="TableContainer oddEvenTable">

            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>

                {courseData && courseData.map((item, i) => (
                  <StyledTableRow >
                    <StyledTableCell align="left" className="TableLabel">
                      <div className="TableItemImgCell">
                        <img className="item-icon" src={item.courseImage} />
                        <p>{item.courseTitle}</p>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>  
                ))}               

                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderItems;


