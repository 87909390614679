import React, { useEffect, useState } from "react";
import Table from '../../../../../components/Table/Table';

function Courses(props) {

  const { courseList } = props;


  const headCells = [
    { id: 'Course', numeric: false, disablePadding: true, label: 'Course', width: "40%"},
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status', width: "20%"},
    { id: 'Enrolled', numeric: false, disablePadding: true, label: 'Enrolled', width: "20%"},
    { id: 'Completed', numeric: false, disablePadding: true, label: 'Completed', width: "20%"},
  ];



  return (
    <>
      
      <div className="TableSection">
      <Table
        tableHeader={true}
        innerTitle="Courses"
        innerHeader={true}
        headCells={headCells}
        rows={courseList}
        footer={true}
      />
    </div>
    </>
  );
}


export default Courses;

