import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import {getUserData, getUserTimezone} from '../../../../hooks/HelperFunctions';

import DraggableDialog from '../../../../hooks/Dialog';
import VerticalTab from '../../../../components/VerticalTab/VerticalTab';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';

import CustomizedSnackbar from '../../../../hooks/Snackbar';

import Announcements from './announcements/Announcements';
import CourseMaterial from './coursematerials/CourseMaterial';
import StudentsListing from './students/StudentsListing';
import Resources from './resources/Resources';

import ComingSoon from '../../../../components/ComingSoon/ComingSoon';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import jsonexport from 'jsonexport/dist'
import * as XLSX from 'xlsx';

function CourseDetails(props) {
 

  const timezone = getUserTimezone();

  let { courseId } = useParams();

  const [courseDetails, setCourseDetails] = useState([]);
  const [courseMaterial, setCourseMaterial] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [courseResources, setCourseResources] = useState([]);
  const [resourseModalData, setResourseModalData] = useState([]);
  const [courseModules, setCourseModules] = useState([]);
  const [courseSessions, setCourseSessions] = useState([]);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");
  const [dialogActionBtn, setDialogActionBtn] = useState("");
  const [dialogOperation, setDialogOperation] = useState("");
  const [runningCourseLink, setRunningCourseLink] = useState("");
  const [csvData, setCsvData] = useState("");

  const orgId = localStorage.getItem('orgId');

  // fetching course details data

  const getCourseDetails = () => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${baseUrl}/orgPortal/getCourseDetailsPageData?courseId=${courseId}`).then((result) => {
      console.log(result.data.body);
      const courseDetails = result.data.body.courseDetails;
      setCourseDetails(courseDetails);
      // set course timezone into local storage
      localStorage.setItem('course_timezone', courseDetails?.timezone);
    }).catch((err) => {
      console.log(err);
    });
  }

  // fetching course material data
  const getCourseMaterial = () => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${baseUrl}/orgPortal/getCourseMaterial?courseId=${courseId}`).then((result) => {
      console.log(result.data);
      setCourseMaterial(result.data.body);
      setCourseResources(result.data.resources);
      setResourseModalData(result.data.resourseModalData);
    }).catch((err) => {
      console.log(err);
    });
  }

  // fetching course material data
  const getStudentList = (queryString="") => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${baseUrl}/orgPortal/getStudents?orgId=${orgId}&courseId=${courseId}&timezone=${timezone}&queryString=${queryString}`).then((result) => {
      console.log(result.data.body);
      setStudentList(result.data.body);
    }).catch((err) => {
      console.log(err);
    });
  }

  // fetching Announcements data
  const getAnnouncements = () => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;    
    axios.get(`${baseUrl}/orgPortal/getAnnouncements?courseId=${courseId}&timezone=${timezone}`).then((result) => {
      console.log(result.data.body);
      setAnnouncements(result.data.body);
    }).catch((err) => {
      console.log(err);
    });
  }

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const processCourseStatusChangeReseult = (response) => {
      console.log(response.data);
      if(response.data.error){
        setShowSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(response.data.message);
        // getCourseMaterial();
      } else{
        const message = response.data.sessionStatus == "Completed"? "Session marked as completed." : "Session unmarked as completed.";
        setShowSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(message);
        getCourseMaterial();
      }
      
  }

  const changeSessionStatus = (sesId, sessionStatus) => {
    console.log(sessionStatus);
    const newStatus = sessionStatus == "Completed" ? "" : "Completed";
    const sessionUpdateData = { sesId: sesId, sessionStatus: newStatus };
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.post(`${baseUrl}/orgPortal/updateCourseSessionStatus`, sessionUpdateData)
      .then(response => { processCourseStatusChangeReseult(response) });
  }

  const handleOpenSnackbar = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  }

  useEffect(() => {
    getAnnouncements();
    getCourseDetails();
    getCourseMaterial();
    getStudentList();    
  }, []);


  /** get Organization or Individual Name */
  const showOrgOrIndName = () => {

    if (courseDetails.orgType === "org") {
      return courseDetails.orgFullName;
    } else {
      if (courseDetails.indFirstName) {
        return `${courseDetails.indFirstName} ${courseDetails.indLastName}`;
      } else {
        return "";
      }
    }

  }

  /** handle session start/stop */
  const startStopSessionHandler = () => {    
    const classStarted = courseDetails.classStarted;
    const sessionLink = courseDetails.buttonLink;

    console.log(classStarted, sessionLink);

    setRunningCourseLink(sessionLink);

    if(classStarted == 0){
      handleOpenDialog("sessionStart");
    } else{
      handleOpenDialog("sessionStop");
    }
    
  }

  const handleOpenDialog = (operation) => {
    // console.log(id);
    setDialogOperation(operation);

    if(operation === "sessionStart"){
      setDialogTitle("Start session?");
      setDialogBody(<div>Are you sure you want to start this session? <br></br>This will enable <strong>“Join Session”</strong> option for learners.</div>);
      setDialogActionBtn("Start Now");
    } else if(operation === "sessionStop"){
      setDialogTitle("Stop session?");
      setDialogBody(<div>Are you sure you want to stop this session? <br></br>This will disable <strong>“Join Session”</strong> option for learners.</div>);
      setDialogActionBtn("Stop Now");
    }
    
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirmCloseDialog = () => {

    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    const postData = { courseId: courseId, operation: dialogOperation };
    console.log(postData);
    axios.post(`${baseUrl}/orgPortal/startStopSession`, postData)
      .then(response => processStartStopResponse(response))
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          handleOpenSnackbar("error", err.response.data.message);
        }
      });
  }

  const processStartStopResponse = (response) => {
    console.log(response);
    setOpenDialog(false);
    // getCourseDetails();
    if(dialogOperation == "sessionStart"){      
      localStorage.setItem('runningSessions', JSON.stringify(courseDetails));
      window.open(runningCourseLink, '_blank', 'noreferrer');
    } else{
      localStorage.removeItem('runningSessions');
    }

    window.location.reload(false);
    
  }

  const convertToCSV = async (jsonData) => {
    return jsonexport(jsonData);
  };

  const convertToExcel = async (jsonData) => {
    const resolvedData = await jsonData;
    const ws = XLSX.utils.json_to_sheet(resolvedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  };

  const downloadFile = (fileData, fileName, fileType) => {
    const blob = new Blob([fileData], { type: fileType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  const getExportStudentInfo = async (filterStudent) => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    let postData = {
      ...filterStudent,
      orgId: orgId,
      courseId: courseId,
      timezone: timezone
    }

    axios.post(`${baseUrl}/orgPortal/exportStudents`, postData)
      .then(async (response) => {
        let infoToShow = response?.data?.body?.map(singleInfo => {
          singleInfo.stream = (singleInfo.stream) ? "Manual" : "Online" 
          return singleInfo
        })
        if(filterStudent?.format){
          if(filterStudent?.format == 'excel'){
            let excelData = await convertToExcel(infoToShow)
            downloadFile(excelData, 'enrolled_students.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          }else if(filterStudent?.format == 'csv'){
            let csvDataResponse = await convertToCSV(infoToShow)
            setCsvData(csvDataResponse)

          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          handleOpenSnackbar("error", err.response.data.message);
        }
      });
  }

  useEffect(() => {
    if(csvData != ""){
      downloadFile(csvData, 'enrolled_students.csv', 'text/csv');
    }
  },[csvData])


  const tabData = [
    // {
    //   icon: <HomeRoundedIcon />,
    //   label: "Home",
    // },
    {
      icon: <CampaignRoundedIcon />,
      label: "Announcements",
    },
    {
      icon: <EventRoundedIcon />,
      label: "Calendar",
    },

    {
      icon: <MenuRoundedIcon />,
      label: "Course Sessions",
    },
    // {
    //   icon: <MenuBookRoundedIcon />,
    //   label: "Assignments & Exams",
    // },
    {
      icon: <SchoolRoundedIcon />,
      label: "Resources",
    },


    // {
    //   icon: <FolderRoundedIcon />,
    //   label: "Attendance",
    // },

    {
      icon: <SupervisorAccountRoundedIcon />,
      label: "Students",
    },

    {
      icon: <InsightsRoundedIcon />,
      label: "Certificates",
    },
  ]

  const tabBody = [

    // {
    //   id: 0,
    //   panel: "Home",
    // },
    {
      id: 0,
      panel: <Announcements courseId={courseId} announcements={announcements} getAnnouncements={getAnnouncements} handleOpenSnackbar={handleOpenSnackbar}/>,
    },
    {
      id: 1,
      panel: <ComingSoon title="Calendar" />,
    },

    {
      id: 2,
      panel: <CourseMaterial courseMaterial={courseMaterial} changeSessionStatus={changeSessionStatus} startStopSessionHandler={startStopSessionHandler} />,
    },

    // {
    //   id: 3,
    //   panel: "Assignments & Exams",
    // },

    {
      id: 3,
      panel: <Resources courseResources={courseResources} getCourseMaterial={getCourseMaterial} resourseModalData={resourseModalData} courseModules={courseModules} courseSessions={courseSessions} handleOpenSnackbar={handleOpenSnackbar} />,
    },
    // {
    //   id: 5,
    //   panel: "Attendance",
    // },

    {
      id: 4,
      panel: <StudentsListing studentList={studentList} courseId={courseId} getStudentList={getStudentList} getExportStudentInfo={getExportStudentInfo}/>,
    },


  ]

  const breadcrumbsData = [
    {
      label: "Courses",
      link:"/courses-listing",
      icon:<MenuBookRoundedIcon fontSize="small" />,
    },

    {
      label: "Course details",
      link:"",
    },

  ];



  const [currentTab, setCurrentTab] = useState(0);

  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  }

  

  return (
    <div>
      <DraggableDialog 
      openDialog={openDialog} 
      title={dialogTitle} 
      body={dialogBody} 
      actionButton={dialogActionBtn} 
      handleCloseDialog={handleCloseDialog} 
      handleConfirmCloseDialog={handleConfirmCloseDialog}
      ModalFooter={true} 
      />
      <Breadcrumbs
        breadcrumbsData={breadcrumbsData}
      />
      <VerticalTab
        title={courseDetails.courseTitle}
        name={showOrgOrIndName()}
        onTabSwitch={handleTabSwitch}
        tabData={tabData}
        tabBody={tabBody}
        Button={true}
        ButtonLabel={courseDetails.classStarted == 0? "Start Session":"Stop Session"}  //"Start Session"
        buttonLink=""
        buttonTarget=""
        buttonOnClick= { () => startStopSessionHandler()}
        tooltipText={courseDetails.tooltipText}
        disabled={courseDetails.buttonDisabled}
      />
      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
    </div>
  );
}


export default CourseDetails;

