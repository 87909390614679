import React, { useState, forwardRef, useImperativeHandle , useRef  } from 'react';
import TextField from '../Form/TextField/TextField';
import { Grid } from '@material-ui/core/';
import FileUplaoder from '../Form/FileUplaoder/FileUplaoder'
import SimpleBackdrop from '../../components/Form/Backdrop/SimpleBackdrop';
import './MediaLibrary.css';
import { set } from 'lodash';
import AWS from "aws-sdk";

const CONF_S3_DIRECTORY =
  process.env.REACT_APP_PROJECT_ENV === "prod"
    ? process.env.REACT_APP_S3_DIRECTORY_PROD
    : process.env.REACT_APP_S3_DIRECTORY_DEV;

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const S3_DIRECTORY = CONF_S3_DIRECTORY;
const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;
const REGION = process.env.REACT_APP_S3_REGION;
const S3_ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
const S3_SECRET_ACCESS_KEY = process.env.REACT_APP_S3_SECRET_ACCESS_KEY;

const REACT_APP_CDN_URL = process.env.REACT_APP_CDN_URL;

AWS.config.update({
  accessKeyId: S3_ACCESS_KEY,
  secretAccessKey: S3_SECRET_ACCESS_KEY,
});

const s3Bucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const UploadFromDevice = forwardRef( (props, ref) => {

  const childRef = useRef(null);

  const [orgId, setOrgId] = useState(localStorage.getItem('orgId'));

  const [progress, setProgress] = useState(0);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  
  const [imageName, setImageName] = useState(props.imageName);

  const [selectedFile, setSelectedFile] = useState(null);

  const [subDomain, setSubDomain] = useState("");

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const clickButton = () => {

    if(childRef.current){
      childRef.current.clickButton();
    }
  }  
  

  useImperativeHandle(ref, () => ({
    clickButton: () => clickButton() ,
    uploadFile: () => uploadFile() ,
  }));

     
  
  const handleValidation = (validationError) => {
    // set form validation error
    if (validationError) {
      
    }
  }
  const handleChanges = (fieldName) => (event) => {
    if(fieldName === "imageName"){
      setImageName(event.target.value);
      props.setImageName(event.target.value);
    }    
  };

  const uploadFile = () => {
    setOpenBackdrop(true);
    let file = selectedFile;    

    let filePath = 'courses/course-images/'+orgId+'/' ; 

    let objFilePath = subDomain ? S3_DIRECTORY + `${subDomain}/` : S3_DIRECTORY;
    // objFilePath += filePath + randString(5) + "-" + file.name;
    objFilePath += filePath + file.name;

    console.log(file);

    // return '';

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: objFilePath,
    };

    s3Bucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) console.log(err);
        props.onFileUpload(
          REACT_APP_CDN_URL + objFilePath,
          file.name,
          Number(file.size) / 1024,
          file.type,
          width,
          height
        );
      });
    setOpenBackdrop(false);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleFileSelected = (file) => {
    setSelectedFile(file);
    props.fileSelected( true );
  };


  return (
    <>   
          <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
          

          <Grid container spacing={3}>

            <Grid item xs={12} md={12}>
              <div className='MediaLibPanelTextField'>
                <TextField
                  label="Media Name"
                  error={props.showImageNameError}
                  helperText={props.imageErrorMessage}
                  required={true}
                  // email={true}
                  value={props.imageName}
                  handleValidation={handleValidation}
                  handleChange={handleChanges("imageName")}
                  placeholder=""
                  count={50}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <FileUplaoder
                FieldError={props.showFileUploadError? "FieldError" : ""}
                label="Upload from your device*"
                required={true}
                filePath={`courses/course-images/`}
                uploadButton="Upload Image"
                //file = {courseData.courseImage} 
                fileType="image"
                showThumb={true}
                //onUpload={ (filePath) => handleChangeWithData("courseImage", filePath) }
                //helpertext="Maximum size of 5.0MB."
                helpertext="Maximum size of 10.0MB."
                onUpload={props.onFileUpload}
                mediaName={imageName}
                nameMandatory="1"
                
                ref={childRef}
                setWidth={setWidth}
                setHeight={setHeight}
                setSelectedFile={handleFileSelected}
              />
              
            </Grid>
          </Grid>

          <Grid container spacing={3}>
                      

          </Grid>

    </>


  );
});

export default UploadFromDevice;

