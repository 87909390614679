import React, { useState } from "react";
import AlrtMessage from '../../../components/Form/AlrtMessage/AlertMessage';
import Table from '../../../components/Table/Table';

function createData(name, Role, Email, Status,) {
  return { name, Role, Email, Status, };
}



function InstructorsListing(props) {
  const [openNewCategory, setOpenNewCategory] = useState(false);

  const handleOpenNewCategory = () => {
    setOpenNewCategory(true);
  };

  const handleCloseModalNewCategory = () => {
    console.log('close')
    setOpenNewCategory(false)
  };

  const handleConfirmNewCategory = () => {
    console.log('close')
    setOpenNewCategory(false)
  };

  // Modal Add Category  start//

  const onClick = () => {
    alert("onclick working fine!");
  }


  const headCells = [

    { id: 'name', numeric: true, disablePadding: false, label: 'Name', width: "30%" },
    { id: 'Role', numeric: true, disablePadding: false, label: 'Role', width: "15%" },
    { id: 'Email', numeric: true, disablePadding: false, label: 'Email', width: "40%" },
    { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: "20%" },
    { id: '', numeric: true, disablePadding: false, label: '', width: "20%" },
    { id: '', numeric: true, disablePadding: false, label: '', width: "20%" },

  ];

  const rows = [
    {
      cell: [
        { textBold: "Shayan Mahmud", },
        { text: "Admin", },
        { text: "shayanmahmud@gmail.com", },
        { Status: true, background: "BA1A1A", color: "rgb(229 9 11)", label: "Inactive", },
        { Status: false, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
        { ActionButton: true, ActionButtonLabel: "Action" },

      ],


      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //

    }];

  return (


    <>
      <AlrtMessage
        message="Course added"
        background="#0B9444"
      />

      <div className="TableSection">

        <Table
          title="Team"
          addLabel="New Member"
          link="add-courses/0"
          headCells={headCells}
          rows={rows}
          AddButton={true}
          tableHeader={true}
          Checkbox={false}
          footer={true}
        />
      </div>

    </>

  );
}


export default InstructorsListing;
