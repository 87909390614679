import React from "react";
import './ComingSoon.css';

import ClockIcon from '../../assets/imgs/Clock.svg';




function ComingSoon(props) {



  return (
    <div className="ComingsoonCont">
      <h1>{props.title}</h1>
    <div className="ComingSoon">
      <img src={ClockIcon} />
      <h3>Coming soon</h3>
      <p>We are working very hard to make this feature available. Stay tuned.</p>
    </div>
    </div>
  );
}


export default ComingSoon;

