import React from "react";
import ComingSoon from '../../../components/ComingSoon/ComingSoon';




function Coupons(props) {

  return (

    <>
      <ComingSoon title="Coupons" />
    </>
  );
}


export default Coupons;

