import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import Grid from '@mui/material/Grid';
import TextField from '../../../../../components/Form/TextField/TextField'
import TextArea from '../../../../../components/Form/TextArea/TextArea'
import SelectField from '../../../../../components/Form/SelectField/SelectField'
import MultipleSelet from '../../../../../components/Form/MultipleSelet/MultipleSelet'
import DateAndTime from '../../../../../components/Form/DateAndTime/DateAndTime'

import Buttons from '../../../../../components/Form/Button/Button';
import Modals from '../../../../../components/Form/Modals/Modals';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';


import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';




function Assignments(props) {


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  // Modal //

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    console.log('close')
    setOpen(false)
  };

  const handleConfirm = () => {
    console.log('close')
    setOpen(false)
  };

  const handleCopied = () => {
    console.log('Copied');
  };
  // Modal //

  // Radio //
  const [value, setValue] = React.useState('female');

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };
  // Radio //


  // ==== Level Value === //
  const [age, setAge] = React.useState('');
  const handleChangelevel = (event) => {
    setAge(event.target.value);
  };

  const selectValue = [
    {
      label: "1st grade",
      value: "1st grade",
    },
    {
      label: "2nd grade",
      value: "2nd grade",
    },
    {
      label: "3rd grade",
      value: "3rd grade",
    },

    {
      label: "4th grade",
      value: "4th grade",
    },
    {
      label: "5th grade",
      value: "5th grade",
    },
    {
      label: "6th grade",
      value: "6th grade",
    },
    {
      label: "7th grade",
      value: "7th grade",
    },
    {
      label: "8th grade",
      value: "8th grade",
    },
    {
      label: "9th grade/Freshman",
      value: "9th grade/Freshman",
    },
    {
      label: "10th grade/Sophomore",
      value: "10th grade/Sophomore",
    },
    {
      label: "11th grade/Junior",
      value: "11th grade/Junior",
    },
    {
      label: "12th grade/Senior",
      value: "12th grade/Senior",
    },

  ];
  // ==== Level Value end === //


  // ==== Date Picker === //
  const [dateTime, dateSetValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChangeDate = (newValue) => {
    dateSetValue(newValue);
  };

  // ==== Date Picker end === //

  return (
    <>

      <div className="TableSection Section">

        <div className="TableHeader">
          <h1 className="MdTitle">Assignments & Exams</h1>
          <div>
            <Buttons
              label="New Assignment"
              buttonColor="#004FE0"
              color="#fff"
              height="50px"
              fontSize="16px"
              onClick={() => setOpen(true)}
            />
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell align="left">Marks</TableCell>
                <TableCell align="left">Start Date</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>


              <TableRow>
                <TableCell align="left">Haroon</TableCell>
                <TableCell align="left">Exam</TableCell>
                <TableCell align="left">600</TableCell>
                <TableCell align="left">12 Dec 2022</TableCell>
                <TableCell align="left">Publish</TableCell>
                <TableCell align="left"><Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                  Action <KeyboardArrowDownRoundedIcon />
                </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Edit</MenuItem>
                    <MenuItem onClick={handleClose}>Delete</MenuItem>
                  </Menu></TableCell>
              </TableRow>




            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Modals
        ModalTitle="Add Assignment or exam"
        handleClose={handleCloseModal}
        handleConfirm={() => handleConfirm()}
        open={open}
        ButtonClose="Cancel"
        ButtonConfirm="Add Now"
        width="651px"

        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"

        ModalBody={
          <div>

            <Grid container spacing={3}>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Name"
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <p>Is this assignment graded?</p>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChangeRadio}>
                  <FormControlLabel value="female" control={<Radio />} label="Yes" />
                  <FormControlLabel value="male" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <SelectField
                  label="Select Grade Category"
                  required={true}
                  options={selectValue}
                  selectedOption={age}
                  handleChange={handleChangelevel}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Marks"
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <SelectField
                  label="Select module"
                  required={true}
                  options={selectValue}
                  selectedOption={age}
                  handleChange={handleChangelevel}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <SelectField
                  label="Select session"
                  required={true}
                  options={selectValue}
                  selectedOption={age}
                  handleChange={handleChangelevel}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                {/* <DateAndTime
                  label="Start Date"
                  required={true}
                  value={dateTime}
                  handleChange={handleChangeDate}
                /> */}
              </Grid>



            </Grid>

          </div>
        }

      />

    </>
  );
}






export default Assignments;

