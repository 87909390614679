import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import AlrtMessage from '../../../components/Form/AlrtMessage/AlertMessage';
import Table from '../../../components/Table/Table';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import EditTrackData from './add/EditTrackData';
import CustomizedSnackbar from '../../../hooks/Snackbar';
import DraggableDialog from '../../../hooks/Dialog';
import SimpleBackdrop from '../../../components/Form/Backdrop/SimpleBackdrop';


function TracksListing(props) {

  const navigate = useNavigate();

  const [orgId, setOrgId] = useState(localStorage.getItem('orgId'));

  const [items, setItems] = useState([]);

  const [trackData, setTrackData] = useState({'trackType': 'diploma', 'status': 'draft'});

  const [timezones, setTimezones] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [hasExistingTrackName, setHasExistingTrackName] = useState(false);

  //base url for api taken from .env file 
  const baseUrl = process.env.REACT_APP_PROJECT_ENV == "dev" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

  /**
   * When click on snackbar close btn
   */
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setShowSnackbar(false);
    };

    const showSnackbarMessage = (severity, message) => {
      setShowSnackbar(true);
      setSnackbarSeverity(severity);
      setSnackbarMessage(message);
    }

    useEffect(() => {    
      const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
      axios.get(`${baseUrl}/orgPortal/checkDuplicateTrackName?name=${trackData.name}&trackId=${trackData.trackId}`).then((result) => {
        console.log(result.data.duplicateTrackName);
        if(result.data.duplicateTrackName){
          setHasExistingTrackName(true);        
        } else{
          setHasExistingTrackName(false);
        }
      }).catch((err) => {
        console.log(err);
      });
    }, [trackData.name]);

    useEffect(() => {
      const fieldName = "trackNameError";
      if(hasExistingTrackName){
        setTrackData({ ...trackData, [fieldName]: true });
      } else{
        setTrackData({ ...trackData, [fieldName]: false });
      }
    }, [hasExistingTrackName]);

  //======= Drawer ===== //
  const [drawerAction, setDrawerAction] = useState("");
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = (rowData) => {
    console.log("row data", rowData);    
    if(rowData){
      setTrackData(rowData);
      setDrawerAction("Update");
    } else{      
      setTrackData({ ...trackData, ['orgId']: orgId });
      setDrawerAction("Add");
    }    
    setOpen(true);
  };

  const gotoDetailsPage = (trackId) => {
    navigate(`/track-details/${trackId}`, { replace: true });
  }


  const handleDrawerClose = () => {
    setTrackData({'trackType': 'diploma', 'status': 'draft'});
    setOpen(false);
  };
  //======= Drawer ===== //


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  //======= Delete dialog ===== //


const handleOpenDialog = (id) => {
  console.log(id);
  // setActiveCourseId(id);
  // setDialogTitle("Delete this track?");
  // setDialogBody("Are you sure, you want to delete a course record and it's related data?");
  setOpenDialog(true);
}




  const onClick = () => {
    alert("onclick working fine!");
  }

    /**
   * close backdrop
   */
    const handleCloseBackdrop = () => {
      setOpenBackdrop(false);
    }


  useEffect(() => {

    //call api to get media library data
    console.log("baseUrl", baseUrl);
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/orgPortal/getTracks?orgId=${orgId}`, {})
      .then(res => {
        console.log("res", res.data);
        console.log("type:", typeof res.data);
        // set timezones
        setTimezones(res.data.timezones);
        //traverse through the data and set the values in the table
        let data = res.data.body;
        
        // const data = JSON.parse(res.data);
        // setTotalItems(data);
        let temp = [];


        data.map((item, index) => {
          console.log("item", item);
          temp.push(
            {
              cell: item.cell,
              //======= Action Date Start===== //
              action: [
                { label: "View", onClick: () => gotoDetailsPage(item.row.trackId) },
                { label: "Edit", onClick: () => handleDrawerOpen(item.row) },
                // { label: "Delete", onClick: handleOpenDelete, },
              ]
              //======= Action Date End===== //
            }
          )
        })
        setItems(temp);
        setOpenBackdrop(false);

      }
      )
      .catch(err => {
        console.log("err", err)
      }
      )

  }, []);


  const updateTrackData = () => {

    console.log("Track data", trackData);
    const enrollmentStartDate = new Date(trackData.enrollmentStartDate);
    const enrollmentEndDate = new Date(trackData.enrollmentEndDate);
    if( trackData?.trackType?.length == 0 ){
      showSnackbarMessage("error", "Track type required.");
    } else if(!trackData.name){
      showSnackbarMessage("error", "Track name is required.");
    } else if(!trackData.trackImage){
      showSnackbarMessage("error", "Track image is required.");
    } else if(!trackData.timezone){
      showSnackbarMessage("error", "Track timezone is required.");
    } else if(!trackData.enrollmentStartDate){
      showSnackbarMessage("error", "Enrollment start date is required.");
    } else if(!trackData.enrollmentEndDate){
      showSnackbarMessage("error", "Enrollment end date is required.");
    } else if(enrollmentStartDate > enrollmentEndDate){
      showSnackbarMessage("error", "Enrollment date range is not valid.");
    } else if(!trackData.sessionStartDate){
      showSnackbarMessage("error", "Session start date is required.");
    } else if(!trackData.totalMonths){
      showSnackbarMessage("error", "Total months field is required.");
    } else{
      // show loader
      setOpenBackdrop(true);
      axios.post(`${baseUrl}/orgPortal/updateTrack`, trackData)
      .then(response => showDataPostMessage(response));
    }
  
  };

  const showDataPostMessage = (response) => {

    console.log(response.data);

    // hide loader
    setOpenBackdrop(false);

    const error = typeof response.data.error == 'undefined' ? true : response.data.error;

    if (error) {
      showSnackbarMessage("error", response.data.message);
    } else{
      if(drawerAction == "Add"){
        showSnackbarMessage("success", "Track added successfully");
      } else{
        showSnackbarMessage("success", "Track updated successfully");
      }

      // redirect to detail page
      setTimeout(function() {
        navigate(`/track-details/${response.data.trackId}`, { replace: true });
      }, 3000);
    }
    
  }


  const headCells = [

    { id: 'name', numeric: true, disablePadding: false, label: 'Track Name', width: "50%" },
    { id: 'Type', numeric: true, disablePadding: false, label: 'Type', width: "15%" },
    { id: 'Track', numeric: true, disablePadding: false, label: 'ID', width: "15%" },
    { id: '# of courses', numeric: true, disablePadding: false, label: '# Of Courses', width: "25%" },
    { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: "15%" },
    { id: '', numeric: true, disablePadding: false, label: '', width: "20%" },
  ];
  

  return (

    <>
    <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
    
    <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />

      <div className="Table">
        <AlrtMessage
          message="Course added"
          background="#0B9444"
        />
        <div className="TableSection">
          <Table
            title="Tracks"
            addLabel="New Track"
            headCells={headCells}
            rows={items}
            AddButton={true}
            tableHeader={true}
            Checkbox={false}
            footer={true}
            onClick={handleDrawerOpen}
            handleOpenDialog={handleOpenDialog}
          />
        </div>

        <SideDrawer
          title={drawerAction == "Add"? "Add New Track" : "Edit Track"}
          ButtonLabel="Save"
          clickSaveButton={() => updateTrackData()}
          cancelButtonLabel="Cancel"
          sideOpen="right"
          open={open}
          FooterWithoutTab={true}
          handleDrawerClose={handleDrawerClose}
          body={
            <EditTrackData trackData={trackData} setTrackData={setTrackData} timezones={timezones} />
          }
        />

        <DraggableDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDeleteDialog}
          title="Delete this track?"
          body={<div>Are you sure you want to delete this? <br></br>Once you delete it, you can't get it back.</div>}
          ModalFooter={true}
          actionButton="Delete"
        />

      </div >
    </>
  );
}


export default TracksListing;

