import React, { useState, useEffect } from "react";
import axios from 'axios'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StepLabel from '@mui/material/StepLabel';

import BasicInfo from "./steps/Basicinfo";
import PaymentDetails from './steps/PaymentDetails'
import BillingDetails from './steps/BillingDetails';
import AccessDetails from './steps/AccessDetails';

import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';



// development related 
// import { sendDataToAPI, retriveDataFromAPI } from '../../../../hooks/HelperFunctions'



function getSteps() {
  return ['Basic Info', 'Payment Details', 'Billing Details', 'Access details',];
}


function getCourseRelationalData(orgId, courseId){
  // const result = retriveDataFromAPI('courses/getAddCourseRelatedData', `orgId=${orgId}&courseId=${courseId}`)
  // console.log(result);
  // return [];
  const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
  axios.get(`${baseUrl}/courses/getAddCourseRelatedData?orgId=${orgId}&courseId=${courseId}`).then((result) => {
    console.log(result.data.body);
    return result.data.body;
  }).catch((err) => {
      console.log(err);
  });
}



function OwnerCustomerAddNew(props) {



  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [courseOverview, setCourseOverview] = useState({});
  const [courseRelationalData, setCourseRelationalData] = useState([]);

  const steps = getSteps();

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${baseUrl}/courses/getAddCourseRelatedData?orgId=1&courseId=0`).then((result) => {
      // console.log(result.data.body);
      setCourseRelationalData(result.data.body);
    }).catch((err) => {
        console.log(err);
    });
    
  }, []);

  // console.log(courseRelationalData.courseLanguages);

  const getStepContent = (step, courseRelationalData) => {
    switch (step) {
      case 0:
        return <BasicInfo />;
      case 1:
        return <PaymentDetails/>;
      case 2:
        return <BillingDetails />;
      case 3:
        return <AccessDetails />;
      
      default:
        return 'Unknown step';
    }
  }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if(activeStep == 0){
      console.log(courseOverview);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };


   const breadcrumbsData = [
    {
      label: "Customers",
      link:"/customer-listing",
      icon:<PersonRoundedIcon fontSize="small" />,
    },

    {
      label: "Add new",
      link:"",
    },

  ];

  return (
    <div className="FormStepper">

      <Breadcrumbs 
        breadcrumbsData={breadcrumbsData}
      />

       <h1>Add New Customer</h1>
      <div className="StapperContaier">

        <div className="StapperBody Section">
         
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              // if (isStepOptional(index)) {
              //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
              // }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>


        <div className="StapperContent">
          {activeStep === steps.length ? (
            <div>
              <Typography >
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} >
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography >{getStepContent(activeStep, courseRelationalData)}</Typography>
              <div className="SpperButtonContainer">
              <div className="StepperButton">
                <Button disabled={activeStep === 0} onClick={handleBack} >
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}

                  >
                    Skip
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className="ButtonContinue"

                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
                </Button>
              </div>
              </div>
            </div>
          )}
        </div>
      </div>

    </div>
  );
}


export default OwnerCustomerAddNew;

