import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import './UplaodModal.css';



function UplaodModal(props) {


  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <div className="UplaodLibModal">
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        className="UplaodLibModalContainer"

      >
        <span><CloseRoundedIcon className="ModaClose" onClick={() => props.handleClose()} style={{ color: props.Headercolor }} /></span>
        <DialogContent>
          <div className="UplaodLibModalBody">

          <div className="ModalLeftCol ModaltabsContainer">
              <Tabs value={value} onChange={handleChange}>
                  <Tab label="Choose file" icon={<InsertDriveFileIcon />} />
                  <Tab label="YouTube video" icon={<YouTubeIcon className="TitleIcon iconYoutube" />} />
              </Tabs>
            </div>
           
          </div>
        </DialogContent>

      </Dialog>


    </div>
  );
}


export default UplaodModal;

