import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import {getUserData, getUserTimezone} from '../../../../hooks/HelperFunctions';

import VerticalTab from '../../../../components/VerticalTab/VerticalTab';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import SimpleBackdrop from '../../../../components/Form/Backdrop/SimpleBackdrop';
import CustomizedSnackbar from '../../../../hooks/Snackbar';

import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';
import SettingsIcon from '@mui/icons-material/Settings';

import Courses from './courses/Courses';
import Learners from './learners/Learners';
import Pricing from './pricing/Pricing';
import Certificates from './certificates/Certificates';
import Settings from './Settings/Settings';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

import InfoAlert from './InfoAlert';




function TracksDetails(props) {

  const navigate = useNavigate();

  let { trackId } = useParams();

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [trackTimezone, setTrackTimezone] = useState("");

  const [trackDetails, setTrackDetails] = useState([]);
  const [trackPricing, setTrackPricing] = useState([]);
  const [trackCourses, setTrackCourses] = useState([]);
  const [studentList, setStudentList] = useState([]);

  const [currentTab, setCurrentTab] = useState(0);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [defaultActiveTab, setDefaultActiveTab] = useState(0);
  //base url for api taken from .env file 
  const baseUrl = process.env.REACT_APP_PROJECT_ENV == "dev" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
  const timezone = getUserTimezone();

  const  capitalizeFirstChar = (string) => {
    if (string && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return '';
  }


const handleSettings = () => {
  console.log ("active settings tab")
  setCurrentTab(4);
}
  /**
   * close backdrop
   */
      const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
      }

   /**
   * When click on snackbar close btn
   */
   const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const showSnackbarMessage = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  }

  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  }


  useEffect(() => {
    getTrackDetailsInfo();
    getCoursesTableData();
    getStudentList();    
  }, []);

  useEffect(() => {
    setTrackTimezone(trackDetails.timezone);  
  }, [trackDetails]);


  // course listing data retriving
  const getCoursesTableData = () => {
    //call api to get media library data
    console.log("baseUrl", baseUrl);
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/orgPortal/getCourseListingData?trackId=${trackId}&orderBy=courseId`, {})
      .then(res => {
        console.log("res", res.data);
        let data = res.data.body;
        setTrackCourses(data);
        setOpenBackdrop(false);

      }
      )
      .catch(err => {
        console.log("err", err)
      }
      )
  }

  // fetching course material data
  const getStudentList = (queryString="") => {
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/orgPortal/getStudents?trackId=${trackId}&timezone=${timezone}&queryString=${queryString}`).then((result) => {
      console.log(result.data.body);
      setStudentList(result.data.body);
      setOpenBackdrop(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  // get trackds related data
  const getTrackDetailsInfo = () => {
    //call api to get media library data
    console.log("baseUrl", baseUrl);
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/orgPortal/getTrackDetails?trackId=${trackId}`, {})
      .then(res => {
        console.log("res", res.data);
        let trackData = res.data.body;
        let tracksPriceData = res.data.tracksPriceData;

        setTrackDetails(trackData);
        setTrackPricing(tracksPriceData);
      
        setOpenBackdrop(false);

      }
      )
      .catch(err => {
        console.log("err", err)
      }
      )
  }

  const updateTrackData = (trackData) => {

    console.log("trackData ", trackData);

    if(false){
      showSnackbarMessage("error", "One or more fields are required.");
    } else{
      // show loader
      setOpenBackdrop(true);
      axios.post(`${baseUrl}/orgPortal/updateTrack`, trackData)
      .then(response => showDataPostMessage(response));
    }
  
  };


  const deleteTrack = () => {
    setOpenBackdrop(true);
    axios.get(`${baseUrl}/orgPortal/deleteTrack?trackId=${trackId}`, {})
    .then(res => {
      console.log("res", res.data);
      let data = res.data.body;
      showSnackbarMessage("success", "Track deleted successfully");
      setTimeout(function() {
        navigate(`/tracks`, { replace: true });
      }, 3000);
    }
    )
    .catch(err => {
      console.log("err", err)
    }
    )
  }

  const showDataPostMessage = (response) => {

    console.log(response.data);

    // hide loader
    setOpenBackdrop(false);

    const error = typeof response.data.error == 'undefined' ? true : response.data.error;

    if (error) {
      showSnackbarMessage("error", response.data.message);
    } else{
      showSnackbarMessage("success", "Track status updated successfully");
      getTrackDetailsInfo();
    }
    
  }



    const tabData = [
    {
      icon: <MenuBookIcon />,
      label:<div className="emptyIcon">Courses {trackCourses.length == 0 && <ErrorRoundedIcon />}</div>,
    },
    {
      icon: <GroupsIcon />,
      label: "Learners",
    },
    {
      icon: <EmojiEventsRoundedIcon />,
      label:"Certificates",
    },
    {
      icon: <PriceChangeRoundedIcon />,
      label: <div className="emptyIcon">Pricing { !(trackPricing?.fullPrice?.enabled || trackPricing?.yearlyPrice?.enabled || trackPricing?.monthlyPrice?.enabled) && <ErrorRoundedIcon />}</div>,
    },
    {
      icon: <SettingsIcon />,
      label:<div className="emptyIcon">Settings {trackDetails.status !== "published" && <ErrorRoundedIcon />}</div>,
    },
  ]

  const tabBody = [
    {
      id: 0,
      panel: <Courses trackId={trackId} trackCourses={trackCourses} getCoursesTableData={getCoursesTableData} getStudentList={getStudentList} />
    },
    {
      id: 1,
      panel: <Learners studentList={studentList} trackId={trackId} getStudentList={getStudentList} />,
    },
    {
      id: 2,
      panel: <Certificates />,
    },
    {
      id: 3,
      panel: <Pricing trackId={trackId} trackDetails={trackDetails} trackPricing={trackPricing} getTrackDetailsInfo={getTrackDetailsInfo} />,
    },
    {
      id: 4,
      panel: <Settings trackId={trackId} trackDetails={trackDetails} trackCourses={trackCourses} trackPricing={trackPricing} getTrackDetailsInfo={getTrackDetailsInfo} updateTrackData={updateTrackData} deleteTrack={deleteTrack} timezone={trackTimezone} />,
    },

  ]



  const breadcrumbsData = [
    {
      label: "Tracks",
      link:"/tracks",
      icon:<InsightsRoundedIcon fontSize="small" />,
    },

    {
      label: "Track details",
    },

  ];


  return (
    <div>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
      <Breadcrumbs breadcrumbsData={breadcrumbsData}/>
      <VerticalTab
        title={trackDetails.name}
        name={capitalizeFirstChar(trackDetails?.trackType)}
        link="#"
        tabData={tabData}
        tabBody={tabBody}
        onTabSwitch={handleTabSwitch}
        activeTab={currentTab}

        alertBox={trackDetails.status !== "published" && <InfoAlert onClick={handleSettings} />}
      />
    </div>
  );
}


export default TracksDetails;

