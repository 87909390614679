import React from "react";
import PaymentHistory from './PaymentHistory';

function Billing(props) {

  const {studentBillingData} = props;

  return (
    <>
      <PaymentHistory studentBillingData={studentBillingData} />

    </>
  );
}






export default Billing;

