import React, { useEffect, useState } from "react";
import Table from '../../../../../components/Table/Table';
import Buttons from '../../../../../components/Form/Button/Button';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';

function createData(Invoice, Date, Amount, Status, Action,) {
  return { Invoice, Date, Amount, Status, Action, };
}

function Payments(props) {


  const onClick = () => {
    alert("onclick working fine!");
  }

  const headCells = [
    { id: 'Invoice', numeric: false, disablePadding: true, label: 'Invoice ID', width: "30%" },
    { id: 'Date', numeric: true, disablePadding: false, label: 'Date', width: "20%" },
    { id: 'Amount', numeric: true, disablePadding: false, label: 'Amount', width: "20%" },
    { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: "20%" },
    { id: 'Action', numeric: true, disablePadding: false, label: '', width: "20%" },
  ];

  const rows = [
    {
      cell: [
        { linkText: "Islamic Studies", linkTextStatus: true, link: "#" },
        { date: "Jan 14, 2022", },
        { textBold: "-$20", },
        { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Paid", },
        { ActionButton: true, ActionButtonLabel: "Action" },

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { linkText: "Islamic Studies", linkTextStatus: true, link: "#" },
        { date: "Jan 14, 2022", },
        { textBold: "-$20", },
        { Status: true, background: "#FFF5E6", color: "#FFAE35", label: "Pending", },
        { ActionButton: true, ActionButtonLabel: "Action" },

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { linkText: "Islamic Studies", linkTextStatus: true, link: "#" },
        { date: "Jan 14, 2022", },
        { textBold: "-$20", },
        { Status: true, background: "#FCE7E9", color: "#E63946", label: "Refund", },
        { ActionButton: true, ActionButtonLabel: "Action" },

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { linkText: "Islamic Studies", linkTextStatus: true, link: "#" },
        { date: "Jan 14, 2022", },
        { textBold: "-$20", },
        { Status: true, background: "#FCE7E9", color: "#E63946", label: "Refund", },
        { ActionButton: true, ActionButtonLabel: "Action" },

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { linkText: "Islamic Studies", linkTextStatus: true, link: "#" },
        { date: "Jan 14, 2022", },
        { textBold: "-$20", },
        { Status: true, background: "#FCE7E9", color: "#E63946", label: "Refund", },
        { ActionButton: true, ActionButtonLabel: "Action" },

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },


  ];


  return (
    <>
      <div className="TableSection">
        <Table
          title=""
          tableHeader={false}
          innerTitle="Payment History"
          AddButtonInner={true}
          addLabelInner="Add Payment"
          innerHeader={true}
          addLabel="New User"
          link="add-courses"
          headCells={headCells}
          rows={rows}
          AddButton={true}
          Checkbox={false}
          footer={true}
        />
      </div>

      <div className="Section">

        <div className="BalanceCard">
          <div className="BalanceCardHeader">
            <h1>Credit Balance</h1>
            <div>
              <Buttons
                icon={< EditIcon />}
                label="Adjust Balance"
                buttonColor="#E0EAFB"
                color="#004FE0"
                height="40px"
                fontSize="14px"
                link="/owner-customer-add"
              />
            </div>
          </div>

          <div className="BalanceCardBody" >
            <h2>$450</h2>
            <p>Balance is the total amount of pending invoices</p>
          </div>

        </div>
      </div>
    </>
  );
}






export default Payments;

