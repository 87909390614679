import React, { useState } from "react";
import axios from 'axios';
import {getUserData, getUserTimezone} from '../../../../../hooks/HelperFunctions';

import Table from '../../../../../components/Table/Table';
import SideDrawer from '../../../../../components/SideDrawer/SideDrawer';
import Profile from '../../../courses/details/students/Profile';
import SimpleBackdrop from '../../../../../components/Form/Backdrop/SimpleBackdrop';
import DraggableDialog from '../../../../../hooks/Dialog';
import CustomizedSnackbar from '../../../../../hooks/Snackbar';
import EmptyCard from '../../../../../components/EmptyCard/EmptyCard';
import Grid from '@material-ui/core/Grid';
import InfoAlert from '../InfoAlert';


function Learners(props) {

  const apiBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
  const orgId = localStorage.getItem('orgId');
  const timezone = getUserTimezone();

  const { studentList, trackId, getStudentList } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [trackData, setTrackData] = useState([]);

  const [studentStatusDataArray, setStudentStatusDataArray] = useState([]);

  const [queryString, setQueryString] = useState("");

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  const capitalizeFirstChar = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

  const handleOpenDialog = (status) => {
    console.log(status);
    const statusFull = capitalizeFirstChar(status);
    if (status == "blocked" || status == "dropped") {
      setDialogTitle(`Change status to "${statusFull}"?`);
      setDialogBody("Are you sure, you want to change status for this student? The student won't be able to access this course anymore.");
    } else {
      setDialogTitle(`Change status to "${statusFull}"?`);
      setDialogBody("Are you sure, you want to change status for this student? The student will be able to access this course again.");
    }

    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    getStudentData(studentStatusDataArray.userId);
  }

  /**
 * When click on snackbar close btn
 */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  }

  const getStudentData = (userId) => {
    setOpenBackdrop(true);
    axios.get(`${apiBaseUrl}/studentPortal/getStudentData?trackId=${trackId}&userId=${userId}&timezone=${timezone}`).then((result) => {
      console.log(result.data.body);
      setStudentData(result.data.body.studentDetails);
      setPaymentData(result.data.paymentData);
      setTrackData(result.data.trackData);
      setOpenBackdrop(false);
      handleDrawerOpen();
    }).catch((err) => {
      console.log(err);
    });
  }

  const changeStudentStatus = (userId, status) => {
    const dataArray = { trackId: trackId, userId: userId, status: status };
    // show confirm message
    handleOpenDialog(status);
    setStudentStatusDataArray(dataArray);
  }

  const showDataPostMessage = (response, dataArray) => {
    setOpenBackdrop(false);
    console.log(response, dataArray);
    if (response.error) {
      setOpenDialog(false);
      setAlertSeverity("error");
      setAlertMessage(response.message);
    } else {
      const statusFull = capitalizeFirstChar(dataArray.status);
      setAlertSeverity("success");
      setAlertMessage(`Status changed to "${statusFull}"`);
      setAlertOpen(true);
      getStudentList(queryString);
    }
  }

  const handleConfirmCloseDialog = () => {
    setOpenBackdrop(true);
    handleCloseDialog();
    axios.post(`${apiBaseUrl}/studentPortal/changeStudentStatus`, studentStatusDataArray)
      .then(response => showDataPostMessage(response, studentStatusDataArray));
  }

  const handleSearchText = (event) => {
    const fieldData = event.target.value;
    console.log(fieldData);
    setQueryString(fieldData);
    getStudentList(fieldData);
  };

  const handleSearch = () => {
    console.log(queryString);
    setQueryString('');
    getStudentList("");
  }



  const headCells = [

    { id: 'name', numeric: true, disablePadding: false, label: 'Name', width: "60%" },
    { id: 'enrolled', numeric: true, disablePadding: false, label: 'Enrolled', width: "40%" },
    // { id: 'stream', numeric: true, disablePadding: false, label: 'Stream', width: "20%" },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status', width: "25%" },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action', width: "20%", align: "right" },
  ];



  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <DraggableDialog openDialog={openDialog} title={dialogTitle} body={dialogBody} actionButton="Confirm" ModalFooter={true} handleCloseDialog={handleCloseDialog} handleConfirmCloseDialog={handleConfirmCloseDialog} />
      <CustomizedSnackbar open={alertOpen} severity={alertSeverity} message={alertMessage} handleClose={handleSnackbarClose} />

      <Grid container spacing={3}>

        {studentList.length == 0 ? 
        <Grid item xs={12} sm={12} md={12}>
          <div className="TableHeader">
            <h1 className="TableTitle">Learners</h1>
          </div>
          <EmptyCard
            title="No learners enrolled yet"
            description="Enrolled learners will be visible here"
          />
        </Grid>
        :
        <Grid item xs={12} sm={12} md={12}>
          <div className="TableSection">
            <Table
              title="Learners"
              tableHeader={true}
              innerHeader={true}
              headCells={headCells}
              rows={studentList}
              btnHandleClick={getStudentData}
              // rows={rows}
              AddButton={false}
              Checkbox={false}
              footer={true}
              showSearchBox={true}
              showSearchCount={true}
              searchBoxPlaceholder="Search Students"
              searchBoxText={queryString}
              handleSearchText={handleSearchText}
              handleSearch={handleSearch}
              FilterButton={false}
            />
          </div>
        </Grid>}


      </Grid>

      <SideDrawer
        title="Learner Details"
        buttonLabel="View More Details"
        buttonLink={`/student-details/${studentData.userId}`}
        sideOpen="right"
        open={open}
        handleDrawerClose={handleDrawerClose}
        FooterWithoutTab={true} 
        cancelButtonLabel="Close"
        body={
          <Profile 
            studentData={studentData} 
            trackData={trackData} 
            paymentData={paymentData} 
            changeStudentStatus={changeStudentStatus}
          />
        }
      />

    </>
  );
}


export default Learners;