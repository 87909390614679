import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from '../../../../../components/Form/TextField/TextField'
import AddTextField from '../../../../../components/Form/AddTextField/AddTextField'
import SwitchButton from '../../../../../components/Form/Switch/Switch'
import MultipleSeletChip from '../../../../../components/Form/MultipleSeletChip/MultipleSeletChip'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Button from '@mui/material/Button';
import Buttons from '../../../../../components/Form/Button/Button';

import { getDataMap } from '../../../../../hooks/HelperFunctions'

function Learners(props) {

  const { courseData, setCourseData, showSnackbarMessage } = props;
  const { gradeLevels, ageGroups } = props.courseRelationalData;

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangePannel = (panel, showAlert) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (showAlert) {
      showSnackbarMessage("success", "Changes has saved!");
    }
  };

  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    let fieldData = "";

    if (fieldName == "seatsStatus" || fieldName == "ageGroup" || fieldName == "gradeLevel") {
      fieldData = event.target.checked;
    } else {
      fieldData = event.target.value;
    }
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  return (
    <div className="formSection">
      <div className="FormBody">

        <div className="AccrodionThreeStep">
          <Accordion expanded={expanded === 'panel1'} onChange={handleChangePannel('panel1')}>
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >

              <div className="accTitleSection">
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Learning objectives
                  {typeof courseData.courseLearningObjs !== 'undefined' && <p className="ItemCount">{courseData.courseLearningObjs.length} {courseData.courseLearningObjs.length > 1 ? "items" : "item"}</p>}
                </Typography>

                <div>
                  <Button className="SessionButtonEdit" >Edit</Button>
                </div>
              </div>

            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                What will students learn in your course? You must enter at least 2 learning objectives or outcomes that learners can expect to achieve after completing your course.
              </Typography>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={12}>
                  <AddTextField
                    placeholder="Type here"
                    options={courseData.courseLearningObjs}
                    handleChange={(event, fieldData) => handleChangeWithData("courseLearningObjs", fieldData)}
                    maxText="80"
                    minItem={2}
                    maxItem={6}                    
                    primaryField="objId"
                    textField="title"
                    addButtonLabel="Add More Objective"
                  />
                </Grid>

              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel1')}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel1', true)}
                />
              </div>
            </AccordionDetails>
          </Accordion>



          <Accordion expanded={expanded === 'panel2'} onChange={handleChangePannel('panel2')}>
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Requirements
                  {typeof courseData.courseRequirements !== 'undefined' && <p className="ItemCount">{courseData.courseRequirements.length} {courseData.courseRequirements.length > 1 ? "items" : "item"}</p>}
                </Typography>

                <div>
                  <Button className="SessionButtonEdit" >Edit</Button>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                List the required skills, experience, tools or equipment learners should have prior to taking your course. If there are no requirements, use this space as an opportunity to lower the barrier for beginners.
              </Typography>


              <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={12}>
                  <AddTextField
                    placeholder="Type here"
                    options={courseData.courseRequirements}
                    handleChange={(event, fieldData) => handleChangeWithData("courseRequirements", fieldData)}
                    maxText="80"
                    minItem={1}
                    maxItem={6}
                    primaryField="reqId"
                    textField="title"
                    addButtonLabel="Add More Requirements"
                  />
                </Grid>

              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel2')}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel2', true)}
                />
              </div>
            </AccordionDetails>
          </Accordion>



          <Accordion expanded={expanded === 'panel3'} onChange={handleChangePannel('panel3')}>
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Number of Seats
                  <p className="ItemCount">{courseData.seatsStatus? courseData.seats : "Unlimited"}</p>
                </Typography>
                <div>
                  <Button className="SessionButtonEdit" >Edit</Button>
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={12}>
                  <SwitchButton
                    title="Limit the number of students that can enroll to this course"
                    subtitle="Enable this if there's seat limit"
                    checked={courseData.seatsStatus}
                    handleChange={handleChanges("seatsStatus")}
                  />
                </Grid>
                {courseData.seatsStatus &&
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="Max number of students"
                      number={true}
                      required={true}
                      // count="4"
                      value={courseData.seats}
                      handleChange={handleChanges("seats")}
                    />
                  </Grid>}

              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel3')}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel3', true)}
                />
              </div>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel4'} onChange={handleChangePannel('panel4')}>
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Age Group
                  <p className="ItemCount">{courseData.ageGroup? Object.keys(courseData.ageGroups).map(function (k) { return courseData.ageGroups[k]['label'] }).join(", ") : "All age groups"}</p>
                </Typography>
                <div>
                  <Button className="SessionButtonEdit" >Edit</Button>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={12}>
                  <SwitchButton
                    title="Age group applicable"
                    subtitle="Enable this if this course is for a specific age group"
                    checked={courseData.ageGroup}
                    handleChange={handleChanges("ageGroup")}
                  />
                </Grid>
                {courseData.ageGroup == true &&
                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSeletChip
                      label="Select age group"
                      required={true}
                      options={getDataMap(ageGroups, "ageId", "title")}
                      selectedOption={courseData.ageGroups}
                      handleChange={(event, fieldData) => handleChangeWithData("ageGroups", fieldData)}
                    />
                  </Grid>}
              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel4')}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel4', true)}
                />
              </div>

            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel5'} onChange={handleChangePannel('panel5')}>
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Grade Level
                  <p className="ItemCount">{courseData.gradeLevel? Object.keys(courseData.gradeLevels).map(function (k) { return courseData.gradeLevels[k]['label'] }).join(", ") : "All grade levels"}</p>
                </Typography>
                <div>
                  <Button className="SessionButtonEdit" >Edit</Button>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={12}>
                  <SwitchButton
                    title="Grade applicable"
                    subtitle="Enable this if grade is applicable"
                    BorderLine={false}
                    checked={courseData.gradeLevel}
                    handleChange={handleChanges("gradeLevel")}
                  />
                </Grid>

                {courseData.gradeLevel == true &&
                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSeletChip
                      label="Select grade"
                      required={true}
                      options={getDataMap(gradeLevels, "gradeId", "title")}
                      selectedOption={courseData.gradeLevels}
                      handleChange={(event, fieldData) => handleChangeWithData("gradeLevels", fieldData)}
                    />
                  </Grid>}
              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel5')}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={handleChangePannel('panel5', true)}
                />
              </div>
            </AccordionDetails>
          </Accordion>

        </div>

      </div>
    </div >
  );
}


export default Learners;

